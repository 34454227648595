import React from "react";
import classes from "./ContributorFooter.module.css";

const contributorGuidelineOne = (props) => {
  return (
    <div className={classes.ContributorFooter}>
      <p>
        Let's ﬆart with connecting your <span> VIMEO ACCOUNT </span>to Videobzz
        for ﬆreaming any content inﬆantly
      </p>
      <p>or</p>
      <p>
        {" "}
        Try to <span> UPLOAD </span> directly{" "}
      </p>{" "}
    </div>
  );
};

export default contributorGuidelineOne;
