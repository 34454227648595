import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';
import { Link } from 'react-router-dom';

import classes from './Blog.module.css';
import Demo from 'assets/Blog/demo.png';
import AdSponser from 'assets/Blog/adsponser.png';
import SearchHeader from 'components/Gallery/SearchHeader/SearchHeader';
import BlogBanner from './BlogBanner/BlogBanner';

const blog = (props) => {

  return (
    <div className={classes.Blog}>
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <SearchHeader isSearchHederTextHide={true} isRoyaltyFreeTextHide={true} />
      </Col>
      <BlogBanner />

      <div className={classes.Headings}>
        <h1>HeadlIne will be here</h1>
        <h4>Sub HeadlIne will be here and all </h4>
      </div>
      <div className={classes.BlogOuter}>

        <Row className="ml-0 mr-0">
          <Col sm="12" md={{ size: 9 }}>
            <Row className="ml-0 mr-0  mb-4">
              <Col sm="12" md={{ size: 6 }} className="text-center"><img src={Demo} alt="IMG" className="img-fluid" /></Col>
              <Col sm="12" md={{ size: 6 }}><p className={classes.Subheadline}>Sub HeadlIne will be here and all </p>
                <p className={classes.Description}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel dolor in hendrerit in vulputate velit esse molestie consequat, vel </p>
                <div className={classes.IconReadmore}>  <div className={classes.OuterIcon}>
                  <Link to={"/"} >
                    <div className={classes.SocialInsta}> </div>
                  </Link>
                  <Link to={"/"} >
                    <div className={classes.SocialLinked}> </div>
                  </Link>
                  <Link to={"/"} >
                    <div className={classes.SocialFace}> </div>
                  </Link>
                  <Link to={"/"} >
                    <div className={classes.SocialTwitt}> </div>
                  </Link>
                </div><Link to={"/blog/blog-slug"}><span className={classes.ReadMore}>Read More...</span></Link></div>
              </Col>

            </Row>
            <Row className="ml-0 mr-0">
              <Col sm="12" md={{ size: 6 }} className="text-center"><img src={Demo} alt="IMG" className="img-fluid" /></Col>
              <Col sm="12" md={{ size: 6 }}><p className={classes.Subheadline}>Sub HeadlIne will be here and all </p>
                <p className={classes.Description}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel dolor in hendrerit in vulputate velit esse molestie consequat, vel </p>
                <div className={classes.IconReadmore}>  <div className={classes.OuterIcon}>
                  <Link to={"/"} >
                    <div className={classes.SocialInsta}> </div>
                  </Link>
                  <Link to={"/"} >
                    <div className={classes.SocialLinked}> </div>
                  </Link>
                  <Link to={"/"} >
                    <div className={classes.SocialFace}> </div>
                  </Link>
                  <Link to={"/"} >
                    <div className={classes.SocialTwitt}> </div>
                  </Link>
                </div><Link to={"/blog/blog-slug"}><span className={classes.ReadMore}>Read More...</span></Link></div>
              </Col>

            </Row>
          </Col>
          <Col sm="12" md={{ size: 3 }}> <div className={classes.Adsponser}><img src={AdSponser} alt="IMG" className="img-fluid" /></div></Col>
        </Row>
      </div>
    </div>
  )
}


export default blog;
