import React, { Component } from 'react';

import BlogDetail from 'components/Blog/BlogDetail/BlogDetail';

class BlogDetailBuilder extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <BlogDetail />
    )
  }
}

export default BlogDetailBuilder;
