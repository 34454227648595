import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "hoc/Layout/Layout";
import GalleryBuilder from "containers/GalleryBuilder/GalleryBuilder";
import SeriesBuilder from "containers/SeriesBuilder/SeriesBuilder";
import PageBuilder from "containers/PageBuilder/PageBuilder";
import Upload from "components/Contributor/Upload/Upload";
import ChatBuilder from "containers/ChatBuilder/ChatBuilder";
import BlogBuilder from "containers/BlogBuilder/BlogBuilder";
import BlogDetailBuilder from "containers/BlogBuilder/BlogDetailBuilder";
import UserBuilder from "containers/UserBuilder/UserBuilder";
import MyProfile from "components/User/MyProfile/MyProfile";
import Collection from "components/User/Collection/Collection";
// import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import {
  ABOUT,
  FAQCLIENT,
  COLLABORATIONS,
  LICENSE,
  FAQ,
  TUTORILAS,
  IMPRINT_AND_TERMS,
  CONTRIBUTOR_GUIDELINE,
  USER_TYPE,
  //FAQ_CONTRIBUTOR,
} from "./AppConstant";
import LinkedInAuth from "../components/Auth/LinkedInAuth";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import AppContext from "../contexts/AppContext";
import { BottomRight } from "../components/UI/OverLays/BottomRight";
import FullScreen from "../components/UI/OverLays/FullScreen";
import { ThemeProvider } from "styled-components";
import Uploader from "../components/Uploader/Uploader";
import UploadUnit from "../components/Uploader/UploadUnit";

class App extends Component {
  constructor(props) {
    super(props);
    this.uploaderRef = React.createRef();
  }
  state = {
    bottomRightContent: null,
    working: false,
    vimeoIntegrated: false,
    vimeoVideos: {},
    myVideos: [],
    myUser: null,
    inProgressUploads: [],
    uploadStats: {},
    uploadWorkers: [],
    metaPickerState: {
      count:0,
    }
  };

  componentDidMount = () => {
    // this.setState({
    //   bottomRightContent: (
    //     <Uploader
    //       ref={this.uploaderRef}
    //       files={[
    //         { name: "file1", status: "Transcoding complete" },
    //         { name: "file2", status: "in_progress" },
    //       ]}
    //     />
    //   ),
    //   inProgressUploads: [
    //     { name: "file3", status: "Transcoding complete" },
    //     // { name: "file4", status: "in_progress" },
    //     // { name: "file5", status: "Transcoding complete" },
    //     // { name: "file6", status: "in_progress" },
    //   ],
    //   uploadStats: {
    //     file1: { status: "Transcoding complete" },
    //   },
    // });
    // return;
    // this.setState({
    //   metaPickerState: {
    //     count: 9,
    //     completed: [2, 4, 5, 6, 7, 8, 9],
    //     next: 10,
    //     Business: {
    //       selectedValue: "Bussiness"
    //     },
    //     Product: {
    //       selectedValue: "Product"
    //     },
    //     Category: {
    //       'checkedOptionValue': 'Private events',
    //       'checkedSubCategory': 'Weddings',
    //       'checkedItems' : [{
    //         id: 24,
    //         title: 'Birthdays'
    //       },
    //       {id: 25, title: "Themed Parties"}
    //     ]
    //     },
    //     Keywords: {
    //       keywords: "aaa, bbb"
    //     },
    //     CM: {
    //       checkedOptionValue: "Infographic\n",
    //       sexvalue: "yes",
    //       textaflag: true,
    //       descrition: "blah"
    //     },
    //     Language: {
    //       checkeditme1: "Afar "
    //     },
    //     Environment:{
    //       checkedOptionValue: "Glitter\n"
    //     },
    //     Symbols: {
    //       checkedOptionValue: "no_symbols"
    //     }
    //   }
    // });
  };

  setBottomRightContent = (bottomRightContent) => {
    this.setState({ bottomRightContent: bottomRightContent });
    // this.uploaderRef.current.
  };
  toggleBusy = (working) => {
    this.setState({ working: working });
  };
  vimeoIntegrationSet = (isSet) => {
    this.setState({ vimeoIntegrated: isSet });
  };
  setVimeoVideos = (videos) => {
    this.setState({ vimeoVideos: videos });
  };
  setMyVideos = (videos) => {
    this.setState({ myVideos: videos });
  };
  setInProgressUploads = (files) => {
    console.log(this.state.uploadWorkers, files);
    this.setState({
      inProgressUploads: files,
    });
  };
  setUploadStats = (stats) => {
    const newStats = { ...this.state.uploadStats, ...stats };
    this.setState({ uploadStats: newStats }, () => {
      console.log(`---updated---`);
      console.log(this.state.uploadStats);
    });
  };
  setMetaPickerState = (state) => {
    console.log(state);
    const newState = { ...this.state.metaPickerState, ...state };
    this.setState({
      metaPickerState: newState,
    },()=>{
      console.log(this.state.metaPickerState);
    })
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          vimeoIntegrated: this.state.vimeoIntegrated,
          vimeoVideos: this.state.vimeoVideos,
          myVideos: this.state.myVideos,
          inProgressUploads: this.state.inProgressUploads,
          uploadStats: this.state.uploadStats,
          metaPickerState: this.state.metaPickerState,
          setBottomRightContent: this.setBottomRightContent,
          toggleBusy: this.toggleBusy,
          setVimeoIntegrationStatus: this.vimeoIntegrationSet,
          setVimeoVideos: this.setVimeoVideos,
          setMyVideos: this.setMyVideos,
          setInProgressUploads: this.setInProgressUploads,
          setUploadStats: this.setUploadStats,
          setMetaPickerState: this.setMetaPickerState,
        }}
      >
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route
                path="/userType"
                component={() => <PageBuilder type={USER_TYPE} />}
              />
              <Route
                path="/FaqClient"
                component={() => <PageBuilder type={FAQCLIENT} />}
              />
              <Route
                path="/about"
                component={() => <PageBuilder type={ABOUT} />}
              />
              <Route exact path="/blog" component={BlogBuilder} />
              <Route path="/blog/:slug" component={BlogDetailBuilder} />
              <Route
                path="/license"
                component={() => <PageBuilder type={LICENSE} />}
              />
              <Route
                path="/collaborations"
                component={() => <PageBuilder type={COLLABORATIONS} />}
              />
              <Route path="/faq" component={() => <PageBuilder type={FAQ} />} />
              {/* <Route
                path="/contributor-faq"
                component={() => <PageBuilder type={FAQ_CONTRIBUTOR} />}
              /> */}
              <Route
                path="/tutorials"
                component={() => <PageBuilder type={TUTORILAS} />}
              />
              <Route
                path="/imprint-and-terms"
                component={() => <PageBuilder type={IMPRINT_AND_TERMS} />}
              />
              <Route
                path="/contributor-guideline"
                component={() => <PageBuilder type={CONTRIBUTOR_GUIDELINE} />}
              />
              <Route exact path="/linkedin" component={LinkedInPopUp} />
              <Route path="/upload" component={Upload} />
              {/* <Route path="/linkedInLogIn" component={LinkedInAuth} /> */}
              <Route path="/series/:id" component={SeriesBuilder} />
              <Route path="/chat/:id" component={ChatBuilder} />
              <Route path="/user" component={UserBuilder} />
              <Route path="/my-profile" component={MyProfile} />
              <Route path="/collections" component={Collection} />
              <Route exact path="/" component={GalleryBuilder} />
            </Switch>
            <BottomRight>{this.state.bottomRightContent}</BottomRight>
            {this.state.working && <FullScreen />}
          </Layout>
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}

export default App;
