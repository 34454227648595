import React from "react";
import { Row, Col, Container } from "reactstrap";
import classes from "./About.module.css";
import SearchHeader from "components/Gallery/SearchHeader/SearchHeader";
import BannerImg from "assets/whyus.png";
import IntroImg from "assets/intro.png";
import Side1Img from "assets/Side1Img.png";
import Sideimg2 from "assets/Site2Img.png";
import Side3Img from "assets/Site3Img.png";

const whyUs = (props) => {
  return (
    <Container>
      <div className={classes.WhyUs}>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <SearchHeader />
        </Col>
        <div className={classes.AboutText}>ABOUT</div>
        <div className={[classes.Text, "text-center"].join(" ")}>
          <p>
            VideoBzz is a free marketing video marketplace that makes sense!
          </p>
          <p>You see what you want and download/embed it for free!</p>
        </div>

        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <div className={[classes.TextGray, "text-center"].join(" ")}>
            <p>
              {" "}
              We want to help businesses around the world with free
              pro-pre-produced videos by our worldwide talented contributors’
              community.
            </p>
            <p>
              {" "}
              Our cloud-based library provides convenient and fun access to tens
              of thousands of marketing videos for most retail/products &
              services required offers, which can be available as a single video
              or as a video, which is a part of a series.{" "}
            </p>
          </div>
        </Col>
        <div className={classes.ImgBanner}>
          {" "}
          <img src={BannerImg} alt="IMG" />{" "}
        </div>
        <div className="text-center">
          <p className={classes.BelowBanner}>
            <span> Free </span> marketing videos - makes the whole difference!
          </p>
        </div>

        <div className={classes.WhyusDescr}>
          <Row className={classes.RowOne}>
            <Col sm="12" md={{ size: 8 }} className="d-flex">
              <div className={classes.ContentArea}>
                <h6>Our fantastic contributors’ community!</h6>
                <p>
                  Our fantastic contributors’ community is providing you with
                  amazing, invested pre-made generic video marketing content for
                  all kinds of generic offers. So you can choose the video tht
                  is right for your next promotion and share it anywhere you
                  wish from one minute to other!
                  <br /> However, donations always welcome.
                </p>
                <h6>Direct communications to all contributors</h6>
                <p>
                  You can always interact with the contributor who created the
                  video of your choice through a chat system, accessible through
                  all video players and invited the video to your requirments as
                  a premium option or to hire him for your next project
                  directly.
                </p>
              </div>
            </Col>
            <Col sm="12" md={{ size: 4 }} className="d-flex">
              <img src={Side1Img} alt="IMG" className="img-fluid" />{" "}
            </Col>
          </Row>
          <Row className={[classes.Flip, classes.RowOne].join(" ")}>
            <Col sm="12" md={{ size: 5 }} className="d-flex">
              <img src={Sideimg2} alt="IMG" className="img-fluid" />{" "}
            </Col>
            <Col sm="12" md={{ size: 7 }} className="d-flex">
              <div className={classes.ContentArea}style={{paddingLeft:130, paddingRight:0}} >
                <h6>Friendly License</h6>
                <p>
                  With our super friendly license, you can share the video on
                  all of your social platforms pages, your sites, blogs, emails,
                  CRM’s, landing pages, even on your digital signage, it fits
                  all screen sizes.
                </p>

                <h6>Easy</h6>
                <p>
                  All videos come with four standard resolutions to choose from.
                  You can download or generate embedding links.<br/> You can use it
                  as a whole or part of your project.<br/> It is all up to you!
                </p>
              </div>
            </Col>
          </Row>
          <Row className={classes.RowOne}>
            <Col sm="12" md={{ size: 7 }} className="d-flex">
              <div className={classes.ContentArea} style={{paddingRight: 50}}>
                <h6>This is just the beginning!</h6>
                <p>
                  <b>
                    Video<span className={classes.Orange}>Bzz</span>
                  </b>{" "}
                  will grow all the time continually and will offer more great
                  content for a broader range of services and products along the
                  way.
                  <br /> So stay tuned with us!
                </p>

                <p>
                  We are also trying to build our community for video content
                  for the private segment, and we hope we will catch up
                  reasonably fast!
                </p>
                <p>
                  Until then, enjoy today with what we have to offer to you and
                  start to downloading right away, you don’t even need to
                  register :-)
                </p>
              </div>
            </Col>
            <Col sm="12" md={{ size: 5 }}className="d-flex">
              <img src={Side3Img} alt="IMG" className="img-fluid" />{" "}
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default whyUs;
