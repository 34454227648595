
import './app.css';
import * as React from 'react';
import classes from "./MetaInfoHelper.module.css";
import $ from 'jquery';
import { css } from "emotion";
import history from "./history";
import httpService from "../../../services/httpService";
import AppContext from "../../../contexts/AppContext";
import FilterableList from './FilterableList';

const field1 = css`
display: contents;
align - items: center;
justify - content: space - around;
padding: 10px;
`;
export default class Choosecategory extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.onCheckedItemChanged = (sender) => {
      this.setState({ checkedItems: sender.checkedItems });
    };
    this.onCheckedItemChanged1 = () => {
    };
    this.state = {
      checkedItems: [],
      checkedOptionValue: "",
      checkedSubCategory: "",
      catedata: [],
      subdata: []

    };
    this.handleclick1 = this.handleclick1.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleRadioChange1 = this.handleRadioChange1.bind(this);
  }
  componentDidMount() {
    $("#timelineBadge1").css({ "--customback": "#27da11" })
    $("#timelineBadge2").css({ "--customback": "#27da11" })
    $("#nexticon2").addClass(classes.nexticon);
    $("#choosecategory").show();
    httpService.get("/gallery/categories").then(resp=>{
      this.setState({
        catedata: resp.data.map(category=>{
          return{
            country: category.title,
            subs: category.sub_category.map(sub=>{
              return {country: sub.title, title: sub.title, id: sub.id}
            })
          }
        })
      },()=>{
        if(this.context.metaPickerState.Category){
          const cv =  this.context.metaPickerState.Category.checkedOptionValue;
          console.log(`cv=${cv}`);
          this.setState({
            checkedOptionValue: cv,
            subdata: this.state.catedata[this.state.catedata.findIndex(i=>i.country===cv)].subs,
          });
          if (this.context.metaPickerState.Category.checkedItems){
            this.setState({
              checkedItems: this.context.metaPickerState.Category.checkedItems,
            },()=>{
              this.updatetimline();
              this.props.setNext(5);
            });
          }
        }
      });
    });
    
  }
  handleclick1() {
    if (this.state.checkedOptionValue != "" && this.state.checkedItems.length !== 0) {
      history.push("/user/Addsubcategory");

    }
  }
  componentDidUpdate() {
    this.updatetimline();
  }
  handleRadioChange(e) {
    this.setState({ 
      checkedOptionValue: this.state.catedata[e.target.value].country, 
      subdata: this.state.catedata[e.target.value].subs},()=>{
        this.props.setNext(4);
        this.context.setMetaPickerState({
          Category: {
            checkedOptionValue: this.state.checkedOptionValue
          }
        })
      }      
    );
  }
  handleRadioChange1(e) {
    this.setState({
      checkedSubCategory: this.state.subdata[e.target.value].title,
      checkedItems: [...this.state.checkedItems, this.state.subdata[e.target.value]]
    }, ()=>{
      this.props.setNext(5);
      this.context.setMetaPickerState({
        Category: {
          ...this.context.metaPickerState.Category,
          checkedSubCategory: this.state.checkedSubCategory,
          checkedItems: this.state.checkedItems
        }
      })
    })
  }
  updatetimline() {
    if (this.state.checkedOptionValue != "" && this.state.checkedItems.length !== 0) {
      $("#timelinePanel3").addClass(classes.newtimlepaintl1);
      $("#timelinePanel3").addClass(classes.newtimlepaintl2);
      $("#timelineBadge1").css({ "--customback": "#27da11" });
      $("#timelineBadge3").css({ "--customback": "#a39d9d" })
      $("#timelineTitle3").css({ "color": "red" });
      $("#nexticon2").css({ "border-top-color": "#0be830" });
    } else {
      $("#nexticon2").css({ "border-top-color": "#b9bcbf" });
    }

  }
  render() {
    return (
      <div className={field1} id="choosecategory">
       
        <div className="contain">
         <FilterableList 
          heading="Choose category" 
          items={this.state.catedata} 
          checkedValue={this.state.checkedOptionValue} 
          handleChange={this.handleRadioChange} 
          />

        <FilterableList 
          heading="Choose Sub category" 
          items={this.state.subdata} 
          checkedValue={this.state.checkedSubCategory} 
          handleChange={this.handleRadioChange1}
          multiChecked={(option)=>this.state.checkedItems.filter(o=>o.id===option.id).length===1} 
          />
        </div>
       
      </div>
    )
  }
}