import React, { Component } from 'react';
import { connect } from 'react-redux';

import Series from 'components/Gallery/Series/Series'
import SeriesVideoItems from 'components/VideoItems/SeriesVideoItems';
import FreeDownloadModal from 'components/UI/CustomModals/FreeDownloadModal';
import EmbeddingCodeModal from 'components/UI/CustomModals/EmbeddingCodeModal';
import ThankYouModal from 'components/UI/CustomModals/ThankYouModal';
import { Container } from 'reactstrap';
import classes from 'containers/App.module.css';
// import UploadMore from '../../components/VideoItems/UploadMore';
import Loader from 'components/UI/Loader/Loader';

import {
  loadSeries,
  loadSeriesVideos
} from 'store/actions';
// import { faFlushed } from '@fortawesome/free-solid-svg-icons';

const mapStateToProps = state => ({
  series: state.series,
  seriesList: state.seriesList,
  loadSeriesIDVideos: state.loadSeriesIDVideos,
  seriesVideos: state.seriesVideos
});
const mapDispatchToProps = dispatch => ({
  loadSeries: id => dispatch(loadSeries(id)),
  loadSeriesVideos: videos => dispatch(loadSeriesVideos(videos))
});

class SeriesBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      video: {},
      seriesIDVideos: '',
      isShowFreeDownloadModal: false,
      isShowFreeEmbeddingCodeModal: false,
      isShowThankYouModal: false
    }
  }

  componentDidMount() {
    if (this.state.id) {
      if (this.props.series === undefined) {
        let payload = {};
        payload.endpoint = "gallery/" + this.state.id;
        this.props.loadSeries(payload);
      }
    }
  }

  componentDidUpdate() {
    if (this.props.loadSeriesIDVideos && this.props.seriesVideos[this.props.loadSeriesIDVideos] === undefined) {
      // this.handler.loadSeriesVideos(this.props.loadSeriesIDVideos);
      let payload = {};
      payload.series_id = this.props.loadSeriesIDVideos;
      payload.endpoint = "gallery/" + this.state.id + "/series/" + this.props.loadSeriesIDVideos;
      this.props.loadSeriesVideos(payload);
    }
  }

  handler = {
    showFreeDownloadModal: (video) => {
      this.setState({ video: video, isShowFreeDownloadModal: true });
    },

    closeFreeDownloadModal: (is_show_ty_modal) => {
      this.setState({
        isShowFreeDownloadModal: false,
        isShowThankYouModal: is_show_ty_modal
      });
    },
    showFreeEmbeddingCodeModal: (video) => {
      this.setState({ video: video, isShowFreeEmbeddingCodeModal: true });
    },
    showThankYouModal: () => {
      this.setState({ isShowThankYouModal: true });
    },
    closeThankYouModal: () => {
      this.setState({ isShowThankYouModal: false });
    },
    closeFreeEmbeddingCodeModal: () => {
      this.setState({ isShowFreeEmbeddingCodeModal: false });
    },
    onMouseOverPlayVideo: (video_id) => {
      if (video_id) {
        var t = document.getElementById(video_id);
        t.play()
      }
    },
    onMouseOutPauseVideo: (video_id) => {
      if (video_id) {
        var t = document.getElementById(video_id);
        t.pause()
      }
      // console.log('out' + video_id);
    },
    loadSeries: (id) => {
      console.log(id);
      this.setState({ id: id })
      let payload = {};
      payload.endpoint = "gallery/" + id;
      this.props.loadSeries(payload);
      this.props.history.push(`/series/` + id);
      window.scrollTo(0, 0);
    },
    loadSeriesVideos: (series_id) => {
      this.setState({ seriesIDVideos: series_id })
      if (this.props.loadSeriesIDVideos !== series_id) {
        let payload = {};
        payload.series_id = series_id;
        payload.endpoint = "gallery/" + this.state.id + "/series/" + series_id;
        this.props.loadSeriesVideos(payload);
      }
    }
  }

  render() {
    let loading = false;
    if (this.props.loadSeriesIDVideos) {
      if (this.state.seriesIDVideos && this.state.seriesIDVideos !== this.props.loadSeriesIDVideos) {
        loading = true;
      } else if (this.props.seriesVideos[this.props.loadSeriesIDVideos] === undefined) {
        loading = true;
      }
    }
    return (
      <React.Fragment>
        {this.state.isShowThankYouModal ?
          <ThankYouModal
            isOpen={this.state.isShowThankYouModal}
            closed={this.handler.closeThankYouModal} />
          : ""}
        {this.state.isShowFreeDownloadModal ?
          <FreeDownloadModal
            video={this.state.video}
            isOpen={this.state.isShowFreeDownloadModal}
            closed={this.handler.closeFreeDownloadModal} />
          : ""}
        {this.state.isShowFreeEmbeddingCodeModal ?
          <EmbeddingCodeModal
            video={this.state.video}
            isOpen={this.state.isShowFreeEmbeddingCodeModal}
            closed={this.handler.closeFreeEmbeddingCodeModal} />
          : ""}
        {/* {this.props.series ? */}
        <Series
          id={this.state.id}
          series={this.props.series}
          loadSeriesIDVideos={this.props.loadSeriesIDVideos}
          showPopup={this.handler.showFreeDownloadModal}
          showEmbedingPopup={this.handler.showFreeEmbeddingCodeModal}
          loadSeriesVideos={this.handler.loadSeriesVideos}
        />
        {/* : "" } */}
        <Loader loading={loading} />
        {this.props.seriesVideos ?

          <Container className={classes.Mycontainer}><SeriesVideoItems
            loadSeriesIDVideos={this.props.loadSeriesIDVideos}
            videos={this.props.seriesVideos}
            seriesList={this.props.seriesList}
            showPopup={this.handler.showFreeDownloadModal}
            showEmbedingPopup={this.handler.showFreeEmbeddingCodeModal}
            loadSeries={this.handler.loadSeries}
            loadSeriesVideos={this.handler.loadSeriesVideos}
            onMouseOverPlayVideo={this.handler.onMouseOverPlayVideo}
            onMouseOutPauseVideo={this.handler.onMouseOutPauseVideo} />
          </Container>
          : ""
        }
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesBuilder);
