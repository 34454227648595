import React, { useState } from "react";
import {
  UncontrolledCollapse,
  Col,
  Collapse,
  Button,
  CardBody,
  Card,
} from "reactstrap";
/*import SearchHeader from "components/Gallery/SearchHeader/SearchHeader";*/
import SearchInput from "components/UI/SearchInput/SearchInput";
import classes from "./FaqClient.module.css";
import BannerImg from "assets/Faqcover.jpg";

const FaqClient = (props) => {
  /*  const [isOpen, setIsOpen] = useState(false);
  
  const toggle = () => setIsOpen(!isOpen);*/
  const [buttonSymbol, setButtonSymbol] = useState(true);
  const [buttonNumber, setButtonNumber] = useState(0);

  const controllButton = (btnNumber) => {
    // setButtonSymbol(!buttonSymbol);
    //console.log("fucntion called with parameter " + btnNumber);
    if (buttonNumber === btnNumber) {
      //console.log("In if condition");
      setButtonNumber(0);
    } else {
      //console.log("In Else");
      setButtonNumber(btnNumber);
    }
  };
  return (
    <div className={classes.FaqPage}>
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <SearchInput />
        <p className={classes.ParaRoyal}>
          All the content your business needs to start to promote!
        </p>
      </Col>
      <div className={classes.FaqBanner}>
        {" "}
        <img src={BannerImg} alt="IMG" />
        <div className={classes.Faq}>
          <p>FAQ</p>
        </div>
      </div>

      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-5 pb-3 mt-5">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>What is videoBzz?</p>
            <button id="toggler1" onClick={() => controllButton(1)}>
              {buttonNumber === 1 ? "-" : "+"}
            </button>
          </div>
          <div>
            {" "}
            <Collapse
              isOpen={buttonNumber === 1 ? true : false}
              // toggler="#toggler1"
            >
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                VideoBzz is a Marketplace that offers high-end free marketing
                video content for download and embedding from our contributors'
                community who specialize in video graphics, animation, and
                marketing content.
                <br />
                <br /> Our video library currently offers many promotional
                categories, such as promotion deals, sales, discounts, events,
                holidays, with many subcategories of envoirnments & text
                categories, so as a business, you can pinpoint any deal offer
                you need and get it ready to publish your next offer instantly.
                <br />
                <br /> The plan is to continually expand to more types of
                advertising video content for promoting services, products, and
                professionals and to provide also free content for private use,
                for example, events, such as birthday invitations, clips and
                presentations, and so much more.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Does it cost money?</p>
            <button id="toggler2" onClick={() => controllButton(2)}>
              {buttonNumber === 2 ? "-" : "+"}
            </button>
          </div>
          <div>
            {" "}
            <Collapse
              isOpen={buttonNumber === 2 ? true : false}
              // toggler="#toggler2"
            >
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                No. it's free! But you always welcome to donate to our
                contributors in the "Thank you" popup after completing the
                downloading of your chosen video.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>For whom is relevant?</p>
            <button id="toggler3" onClick={() => controllButton(3)}>
              {buttonNumber === 3 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 3 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                The library is perfect for most eCommerce businesses that want
                to promote their offers for services or products anywhere
                online.
                <br />
                <br /> The videos are easy to embed or upload for all social
                networks, landing pages, digital signage, CRMs, email marketing,
                pages, and background.
                <br />
                <br /> The platform is also great for small advertising agencies
                that need instant video marketing material as part of their
                service to their customers. You can use it as is or as part of
                your project.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Can we customize the videos?</p>
            <button id="toggler4" onClick={() => controllButton(4)}>
              {buttonNumber === 4 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 4 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                You are always welcome to contact the contributor for custom
                jobs via our chat system, which is accessible from all media
                players. However, for using the chat system, you need to
                register to the platform. Its free service!
                <br />
                <br /> You are also welcome to contact the contributor directly
                outside the VideoBzz platform via his social media profile.{" "}
                <br />
                <br />
                Officially, we will be able to provide a customization online
                order system within 3-4 months. So stay tuned!
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>In which legal terms can I use the videos?</p>
            <button id="toggler5" onClick={() => controllButton(5)}>
              {buttonNumber === 5 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 5 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                We have created a super friendly license that makes the whole
                experience very free and easy to use. Please check our license
                page here.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>How many videos did I allow to download?</p>
            <button id="toggler6" onClick={() => controllButton(6)}>
              {buttonNumber === 6 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 6 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                As part of our philosophy, we don't limit downloads!
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>What is the availability of new content?</p>
            <button id="toggler7" onClick={() => controllButton(7)}>
              {buttonNumber === 7 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 7 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Since VideoBzz is a contributors marketplace designed for
                endless space of content for an extensive range of categories,
                we believe that you can find new videos daily.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>How can I donate to a contributor?</p>
            <button id="toggler8" onClick={() => controllButton(8)}>
              {buttonNumber === 8 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 8 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Great, donations always a great way to motivate creators to
                continue to create great content!
                <br />
                <br /> What you need to do: After completing the download
                process, a "Thank you" popup window will appear, there you can
                find the Paypal button for complete payments.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Which video resolution & format can I download?</p>
            <button id="toggler9" onClick={() => controllButton(9)}>
              {buttonNumber === 9 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 9 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                All videos come in MP4 format!
                <br />
                <br /> We try to keep a minimum standard resolution as follows:
                <br />
                Horizontal: 1920 * 1080
                <br /> Vertical: 1080 * 1920
                <br />
                <br /> Based on those resolutions, you automatically get the
                option to choose four recommendations below that. For example,
                for the 1920 * 1080 HD video resolution version, you will have
                access to the following resolution breakdown:
                <br />
                <br />
                <b> Horizontal</b>
                <br /> High Def 1080p (mp4, 1920x1080)
                <br /> High Def (mp4, 1280x720)
                <br /> Standard Def (mp4, 960x540)
                <br /> Standard Def (mp4, 640x360)
                <br />
                <br /> <b>Vertical</b>
                <br /> High Def 1080p (mp4, 1080x1920)
                <br /> Standard Def (mp4, 540x960)
                <br /> High Def (mp4, 720x1280)
                <br /> Standard Def (mp4, 360x640)
                <br />
                <br /> In case the contributor created the video. For example,
                for mobile phone resolution only (360 * 640 pixels), the
                platform will not produce smaller resolution versions because of
                the original video file is too low-resolution.
                <br />
                <br /> All videos come in MP4 format!
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default FaqClient;
