export const FAQCLIENT = "FAQCLIENT";
export const CONTACT_US = "CONTACT_US";
export const ABOUT = "ABOUT";
export const COLLABORATIONS = "COLLABORATIONS";
export const BLOG = "BLOG";
export const LICENSE = "LICENSE";
export const FAQ = "FAQ";
export const TUTORILAS = "TUTORILAS";
export const IMPRINT_AND_TERMS = "IMPRINT_AND_TERMS";
export const CONTRIBUTOR_GUIDELINE = "CONTRIBUTOR_GUIDELINE";
export const USER_TYPE = "USER_TYPE";
//export const FAQ_CONTRIBUTOR = 'FAQ_CONTRIBUTOR';
