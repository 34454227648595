import React from 'react';
import classes from "./MetaInfoHelper.module.css";
import { css } from "emotion";
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import AppContext from "../../../contexts/AppContext";
const field1 = css`
    display: contents;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    padding:10px;
`;
const labelStyle = css`
  display: flex;
  align-items: center;
`;
const fontstyle = css`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #b5b3b7;
      &:checked {
    background-color: #ffffff;
    border: 2px solid #0ce40c;
    font-color:blue;
    &:after {
      display: block;
    }
  }
`;

class Business extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: ''
    }
    this.handleChange1 = this.handleChange1.bind(this);
    this.map = {
      "Private": 1,
      "Bussiness": 2,
    };
  }
 
  componentDidMount() {
    $("#nexticon").addClass(classes.nexticon);
    $("#business").show();
    console.log(this.context);
    if(this.context.metaPickerState.Business){
      this.setState({
        selectedValue: this.context.metaPickerState.Business.selectedValue
      });
      this.props.setNext(this.map[this.context.metaPickerState.Business.selectedValue]);
    }
  }
 
  handleChange1 = (event) => {
    this.setState({
      selectedValue: event.target.value
    }, ()=>{
      this.props.setNext(this.map[this.state.selectedValue]);
      this.context.setMetaPickerState({
        Business: {
          selectedValue: this.state.selectedValue
        }
      });
    });
  };
  componentDidUpdate() {
    this.updatetimline();
  }
  
  updatetimline() {
    if (this.state.selectedValue != '') {
      $("#timelinePanel1").addClass(classes.newtimlepaintl1);
      $("#timelinePanel1").addClass(classes.newtimlepaintl2);
      $("#timelineBadge1").css({ "--customback": "#a39d9d" });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={field1} id="business">
          <div>
            <label className={labelStyle}>
              <input
                type="radio"
                checked={this.state.selectedValue === 'Bussiness'}
                onChange={this.handleChange1}
                value="Bussiness"
                className={classes.input}
                name="radio-button-demo"
                // inputProps={{ 'aria-label': 'Bussiness' }}
              />
              <span className={fontstyle}>Bussiness</span>
            </label>
          </div>
          <div>
            <label className={labelStyle}>
              <input
                type="radio"
                checked={this.state.selectedValue === 'Private'}
                onChange={this.handleChange1}
                value="Private"
                className={classes.input}
                name="radio-button-demo"
                // inputProps={{ 'aria-label': 'Private' }}
              />
              <span className={fontstyle}>Private</span>
            </label>
          </div>
          
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Business);