import React from 'react';
import siteLogo from 'assets/VideoBzz-Logo.png'
import classes from './Logo.module.css';

const logo = (props) => {
  return (
    <div className={classes.Logo} style={{ height: props.height, margin: props.margin }}>
      <img src={siteLogo} alt="LOGO" />
    </div>
  )
}

export default logo;
