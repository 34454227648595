import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col
} from 'reactstrap';

import classes from './FooterItems.module.css';
import FooterItem from './FooterItem'

import Enjoy from 'assets/Enjoy.png'



const footerItems = (props) => {
  return (
    <div className={classes.FooterItems}>
      <Row className={classes.Norow}>
        <Col sm="6" md={{ size: 3 }} >
          <div className={classes.OuterSocial}>
            <Link to={"/"} >
              <div className={classes.SocialInsta}> </div>
            </Link>
            <Link to={"/"} >
              <div className={classes.SocialLinked}> </div>
            </Link>
            <Link to={"/"} >
              <div className={classes.SocialFace}> </div>
            </Link>
            <Link to={"/"} >
              <div className={classes.SocialTwitt}> </div>
            </Link>
          </div>
        </Col>
        <Col sm="6" md={{ size: 2 }} >
          <ul >
            <FooterItem link='/' >Gallery</FooterItem>
            <FooterItem link='/about'>About</FooterItem>
            <FooterItem link='/why-us'>Why us</FooterItem>
            <FooterItem link='/collections'>Collections</FooterItem>
          </ul>
        </Col>
        <Col sm="6" md={{ size: 2 }} >
          <ul >
            <FooterItem link='/license' >License</FooterItem>
            <FooterItem link='/imprint-and-terms'>Terms of Use</FooterItem>
            <FooterItem link='/'>Privacy Policy</FooterItem>
            <FooterItem link='/'>API / Developers</FooterItem>
          </ul>
        </Col>
        <Col sm="6" md={{ size: 2 }} >
          <ul >
            <FooterItem link='/' >Support center</FooterItem>
            <FooterItem link='/tutorials'>Tutorials</FooterItem>
            <FooterItem link='/faq'>FAQ</FooterItem>
            <FooterItem link='/imprint-and-terms'>Imprint</FooterItem>
          </ul>
        </Col>
        <Col sm="6" md={{ size: 2 }} >
          <ul >
            <FooterItem link='/' >Contributors</FooterItem>
            <FooterItem link='/contributor-faq' >Contributor FQA</FooterItem>
            <FooterItem link='/contributor-guideline'>Contributor guidelines</FooterItem>
            <FooterItem link='/'>Contributor Tutorials</FooterItem>

          </ul>
        </Col>
        <Col sm="6" md={{ size: 1 }} >
          <img className={classes.Enjoy} src={Enjoy} alt="LOGO" />
        </Col>
      </Row>
      <div className={classes.BtmFtr} >

        <p>
          VideoBuzz is a free Video marketplace that makes sense!
       </p>

        <p>Talented contributor's community that works hard to create you the right promotional content! </p>

        <p>Free download/Embedding in a press of a button!   </p>


      </div>
    </div>
  )
}
export default footerItems
