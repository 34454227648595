export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules, form = {}) => {
    let validity = {};
    validity.isValid = true;
    validity.message = '';
    if (!rules) {
        return validity;
    }

    if (rules.required) {
        validity.isValid = value.trim() !== '' && validity.isValid;
        if (rules.message) {
            validity.message = rules.message;
        } else {
            validity.message = 'This field is required';
        }
    }

    if (validity.isValid && rules.equalTo && form[rules.equalTo] && form[rules.equalTo].touched) {
        validity.isValid = form[rules.equalTo].value === value;
        if (rules.message) {
            validity.message = rules.message;
        } else {
            validity.message = 'The passwords do not match.';
        }
    }

    if (validity.isValid && rules.minLength) {
        validity.isValid = value.length >= rules.minLength && validity.isValid;
        if (rules.message) {
            validity.message = rules.message;
        } else {
            validity.message = 'Please enter at least ' + rules.minLength + ' characters.';
        }
    }

    if (validity.isValid && rules.maxLength) {
        validity.isValid = value.length <= rules.maxLength && validity.isValid;
        if (rules.message) {
            validity.message = rules.message;
        } else {
            validity.message = 'Please enter no more than ' + rules.maxLength + ' characters';
        }
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        validity.isValid = pattern.test(value) && validity.isValid;
        if (rules.message) {
            validity.message = rules.message;
        } else {
            validity.message = 'Please enter a valid email address';
        }
    }

    if (validity.isValid && rules.isNumeric) {
        const pattern = /^\d+$/;
        validity.isValid = pattern.test(value) && validity.isValid;
        if (rules.message) {
            validity.message = rules.message;
        } else {
            validity.message = 'Please enter a valid number';
        }
    }

    return validity;
}
