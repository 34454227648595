import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./Layout.module.css";
import Toolbar from "components/Navigation/Toolbar";
import FooterToolbar from "components/UI/Footer/FooterToolbar";

import { updateProfileDetails } from "store/actions";

const mapStateToProps = (state) => ({
  profileDetails: state.profileDetails,
});
const mapDispatchToProps = (dispatch) => ({
  updateProfileDetails: (id) => dispatch(updateProfileDetails(id)),
});

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Toolbar />
        <main className={classes.Content}>{this.props.children}</main>
        <FooterToolbar />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
