import React, { Component } from "react";
import { Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";

import classes from "./AuthModal.module.css";
import errorClasses from "components/UI/Error/Error.module.css";
import Loader from "components/UI/Loader/Loader";
import Input from "components/UI/Input/Input";
import { updateObject, checkValidity } from "utils/utility";
import httpService from "services/httpService";

class SignUpModal extends Component {
  // constructor(props) {
  //     super(props);
  // }

  state = {
    signUpForm: {
      first_name: {
        label: "First name",
        elementType: "input",
        elementConfig: {
          type: "text",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        invalidMessage: "",
        touched: false,
      },
      last_name: {
        label: "Last name",
        elementType: "input",
        elementConfig: {
          type: "text",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        invalidMessage: "",
        touched: false,
      },
      email: {
        label: "email",
        elementType: "input",
        elementConfig: {
          type: "text",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        valid: false,
        invalidMessage: "",
        touched: false,
      },
      password: {
        label: "Password (6 or more characters)",
        elementType: "input",
        elementConfig: {
          type: "password",
        },
        value: "",
        validation: {
          required: true,
          // maxLength: 6,
          minLength: 6,
        },
        valid: false,
        invalidMessage: "",
        touched: false,
        emptyInput: "confirm_password",
      },
      confirm_password: {
        label: "Confirm Password",
        elementType: "input",
        elementConfig: {
          type: "password",
        },
        value: "",
        validation: {
          required: true,
          equalTo: "password",
        },
        valid: false,
        invalidMessage: "",
        touched: false,
      },
      is_terms: {
        elementType: "checkbox",
        wrapperClass: [classes.Agree],
        elementConfig: {
          type: "checkbox",
          options: [
            {
              value: "1",
              displayValue:
                "You agree to the VedioBzz User Agreement, Privacy, and Cookie Policy",
            },
          ],
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        invalidMessage: "",
        touched: false,
      },
    },
    formIsValid: false,
    hasAPIStatus: false,
    hasAPIMsg: "",
    loading: false,
    showUserType: false,
  };

  getUserType = () => {
    this.setState({ showUserType: true });
  };

  handler = {
    toggle: () => !this.props.isOpen,

    toggleLoginModal: () => {
      this.props.closed();
      this.props.toggleLoginModal();
    },

    onSubmit: (event) => {
      event.preventDefault();
      const formData = {};
      for (let formElementIdentifier in this.state.signUpForm) {
        formData[formElementIdentifier] =
          this.state.signUpForm[formElementIdentifier].value;
      }
      let that = this;
      that.getUserType();
      //   that.setState({ loading: true });
      // debugger

      //   return httpService
      //     .post("user/register/", formData)
      //     .then((res) => {
      //       if (res.data.success) {
      //         this.setState({
      //           hasAPIStatus: true,
      //           hasAPIMsg: res.data.msg,
      //           loading: false,
      //         });
      //         setTimeout(function () {
      //           // that.props.closed();
      //           // that.props.history.push(`/`);
      //           that.handler.toggleLoginModal();
      //         }, 2000);
      //       } else {
      //         this.setState({
      //           hasAPIStatus: true,
      //           hasAPIMsg: res.data.error.email[0],
      //           loading: false,
      //         });
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       this.setState({
      //         hasAPIStatus: true,
      //         hasAPIMsg: "Something went wrong. Please try again later",
      //         loading: false,
      //       });
      //     });
    },
    inputChanged: (event, inputIdentifier) => {
      const input = this.state.signUpForm[inputIdentifier];
      let checkValid = {};
      if (input.elementType === "checkbox") {
        let isChecked = event.target.checked;
        checkValid = checkValidity(
          isChecked ? event.target.value : "",
          input.validation,
          this.state.signUpForm
        );
      } else {
        checkValid = checkValidity(
          event.target.value,
          input.validation,
          this.state.signUpForm
        );
      }

      const updatedFormElement = updateObject(input, {
        value: event.target.value,
        valid: checkValid.isValid,
        invalidMessage: checkValid.message,
        touched: true,
      });
      let updated = { [inputIdentifier]: updatedFormElement };

      if (input.emptyInput) {
        const emptyInput = this.state.signUpForm[input.emptyInput];
        if (emptyInput.touched) {
          const updatedElement = updateObject(emptyInput, {
            value: "",
            valid: false,
            invalidMessage: "",
            touched: false,
          });
          updated[input.emptyInput] = updatedElement;
        }
      }
      const updatedSignUpForm = updateObject(this.state.signUpForm, updated);
      // const updatedSignUpForm = updateObject(this.state.signUpForm, {
      //     [inputIdentifier]: updatedFormElement
      // });

      let formIsValid = true;
      for (let inputIdentifier in updatedSignUpForm) {
        formIsValid = updatedSignUpForm[inputIdentifier].valid && formIsValid;
      }
      this.setState({
        signUpForm: updatedSignUpForm,
        formIsValid: formIsValid,
      });
    },
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.signUpForm) {
      formElementsArray.push({
        id: key,
        config: this.state.signUpForm[key],
      });
    }
    let form = (
      <form onSubmit={this.handler.onSubmit}>
        {formElementsArray.map((formElement) => (
          <Input
            key={formElement.id}
            label={formElement.config.label}
            wrapperClass={formElement.config.wrapperClass}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            invalidMessage={formElement.config.invalidMessage}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) =>
              this.handler.inputChanged(event, formElement.id)
            }
          />
        ))}

        {this.state.hasAPIStatus ? (
          <p className={[errorClasses.InvalidText, "mt-2", "mb-2"].join(" ")}>
            {this.state.hasAPIMsg}
          </p>
        ) : (
          ""
        )}
        <Loader loading={this.state.loading} />
        <Button className={classes.Jion} disabled={!this.state.formIsValid}>
          Join
        </Button>
        <p className={classes.OR}>OR</p>
        <Button className={classes.logassocial}>Continue with Facebook</Button>
        <Button className={classes.logassocial}>Continue with Linkedin</Button>
        <p className={classes.Signin}>
          Already on VideoBzz?{" "}
          <span onClick={this.handler.toggleLoginModal}> Signin </span>
        </p>
      </form>
    );
    return (
      <Modal
        size="md"
        isOpen={this.props.isOpen}
        backdrop={true}
        fade={false}
        toggle={this.handler.toggle}
        contentClassName={classes.Test}
      >
        <ModalHeader
          className={classes.MHeader}
          toggle={this.props.closed}
        ></ModalHeader>
        <ModalBody className={classes.ModalBody}>
          <Col sm="12">
            <Loader loading={this.props.loading} />
            {form}
          </Col>
        </ModalBody>
      </Modal>
    );
  }
}

export default SignUpModal;
