import React, { useState } from "react";
import {
  UncontrolledCollapse,
  Col,
  Collapse,
  Button,
  CardBody,
  Card,
} from "reactstrap";
/*import SearchHeader from "components/Gallery/SearchHeader/SearchHeader";*/
import SearchInput from "components/UI/SearchInput/SearchInput";
import classes from "./FaqPage.module.css";
import BannerImg from "assets/Faqcover.jpg";

const FaqPage = (props) => {
  /*  const [isOpen, setIsOpen] = useState(false);
  
  const toggle = () => setIsOpen(!isOpen);*/
  const [buttonSymbol, setButtonSymbol] = useState(true);
  const [buttonNumber, setButtonNumber] = useState(0);

  const controllButton = (btnNumber) => {
    // setButtonSymbol(!buttonSymbol);
    //console.log("fucntion called with parameter " + btnNumber);
    if (buttonNumber === btnNumber) {
      // console.log("In if condition");
      setButtonNumber(0);
    } else {
      // console.log("In Else");
      setButtonNumber(btnNumber);
    }
  };
  return (
    <div className={classes.FaqPage}>
      <Col className={classes.PaddingTop} sm="12" md={{ size: 8, offset: 2 }}>
        <SearchInput />
        <p className={classes.ParaRoyal}>
          All the content your business needs to start to promote!
        </p>
      </Col>
      <div className={classes.FaqBanner}>
        {" "}
        <img src={BannerImg} alt="IMG" />
        <div className={classes.Faq}>
          <p>FAQ</p>
        </div>
      </div>

      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-5 pb-3 mt-5">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>How do I upload my videos?</p>
            <button id="toggler1" onClick={() => controllButton(1)}>
              {buttonNumber === 1 ? "-" : "+"}
            </button>
          </div>
          <div>
            {" "}
            <Collapse
              isOpen={buttonNumber === 1 ? true : false}
              // toggler="#toggler1"
            >
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                We provide 2 uploads options:
                <br />
                <br /> 1. Via your Vimeo account integration to VideoBzz <br />
                2. Via our upload share space, we are giving for our
                contributors.
                <br />
                <br /> (20GB weekly share space).
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              Why do I need my Vimeo account to integrate into your platform for
              uploading?
            </p>
            <button id="toggler2" onClick={() => controllButton(2)}>
              {buttonNumber === 2 ? "-" : "+"}
            </button>
          </div>
          <div>
            {" "}
            <Collapse
              isOpen={buttonNumber === 2 ? true : false}
              // toggler="#toggler2"
            >
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Mainly because it's convenient for you, once you integrate your
                Vimeo account, you don't need to re-upload all of your content
                all over again, and it's a great time saver.
                <br />
                <br /> Apart from the time saving, it's also giving you full
                control over your content via your Vimeo account, and the most
                important you don't have limits with content!! You can grow your
                content on VideoBzz as your content on Vimeo! So no storage
                limits.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>How do I control the videos which I upload from Vimeo?</p>
            <button id="toggler3" onClick={() => controllButton(3)}>
              {buttonNumber === 3 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 3 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                After connecting Your Vimeo account to VideoBuzz, you first need
                to choose videos that will appear from your Vimeo video library
                and sort them with "VideoBzz" categories options before they can
                publish on VideoBuzz. It's a process that you control 100%.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Which upload method is better?</p>
            <button id="toggler4" onClick={() => controllButton(4)}>
              {buttonNumber === 4 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 4 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Without a doubt, connecting your Vimeo account is far more
                comfortable, practical, and providing you fast content
                integration with unlimited space from Vimeo.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Why Contributing when I can sell and make money?</p>
            <button id="toggler5" onClick={() => controllButton(5)}>
              {buttonNumber === 5 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 5 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                The content industry has changed, and today is much more
                challenging to make money from generic materials. However, in
                the industry, there is a massive demand for new talented
                creators ranging from small jobs to large projects. In this
                platform, we empower the creators through their generic content.
                <br />
                <br />
                Your creativity skills in video, animation, your marketing ideas
                and your written content in the script are revealed via your
                free generic content which you share on this platform, and this
                is the jump that you need to make to be an outstanding creator
                and start getting the right attention from the right clients.
                <br />
                <br />
                Your great free content opens up to you great opportunities.
                It's precisely like fishing with bait or without bait, that
                making all the difference in your fishing experiences.
                <br />
                <br /> Focus on great and high-end free content, choose popular
                topics to create your content, and win your users' hearts via
                downloads and with great user reviews.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>How can I get a donation from my free content?</p>
            <button id="toggler6" onClick={() => controllButton(6)}>
              {buttonNumber === 6 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 6 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Every download process leads to a donation opportunity!
                <br />
                <br /> In each download process, the user exposes the "Donate"
                payment option several times, so if they download your content,
                they cannot miss it. The donation can be paid only via the
                Paypal option at this point.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Does VideoBzz get anything from your donations?</p>
            <button id="toggler7" onClick={() => controllButton(7)}>
              {buttonNumber === 7 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 7 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                No way!
                <br /> We don't have any share from donations!
                <br /> We also make sure that the money will get directly to
                your Paypal account and not via our platform.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              Can I communicate with potential clients or regular viewers that
              like my content?
            </p>
            <button id="toggler8" onClick={() => controllButton(8)}>
              {buttonNumber === 8 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 8 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Of course!
                <br /> We make everything possible to provide you with business
                opportunities, and one of the most effective ways is to enable
                the viewer to chat with you. They can initiate chat conversation
                directly from the video player when they are watching your
                video, and by clicking on the chat icon, they can start the
                conversation with you.
                <br />
                <br /> The chat conversation will open up a new chat page where
                you and the viewer can communicate based on the video that they
                watched in the hope of creating job opportunities and business
                mingling.
                <br />
                <br /> The minimum requirement to start chatting with
                contributors on the platform is to create free registration on
                the platform, mainly for security reasons.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Can we communicate via other platforms outside VideoBzz?</p>
            <button id="toggler9" onClick={() => controllButton(9)}>
              {buttonNumber === 9 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 9 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Of course!
                <br /> We believe in freedom of choice!
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              Does it cost visitors to initiate a chat with me on the platform?
            </p>
            <button id="toggler10" onClick={() => controllButton(10)}>
              {buttonNumber === 10 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 10 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Not at all!
                <br /> The chat system will be accessible for all videos you
                will upload, and from your profile ID as long you set the chat
                option in your admin for "Active Chat."
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Can I control the chat options?</p>
            <button id="toggler11" onClick={() => controllButton(11)}>
              {buttonNumber === 11 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 11 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Of course!
                <br /> Each video you setting in the system has a default
                "Active chat" option.
                <br />
                <br /> In case you which not communicating with visitors, you
                disable the default chat option from that video setting page,
                and the chat option will be disabled.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Does the platform keep my chat records?</p>
            <button id="toggler12" onClick={() => controllButton(12)}>
              {buttonNumber === 12 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 12 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                We are keeping a track record of 4 weeks to the most.
                <br />
                <br /> If the chat history is important to you, you will have
                the option to download the script and send it to your email.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              So, when is it expected to begin the online customization service?
            </p>
            <button id="toggler13" onClick={() => controllButton(13)}>
              {buttonNumber === 13 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 13 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                We will announce it both via emails and on the site! However, we
                are aiming to be ready on the 1. Jan 2021.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Do I have storage limitations on VideoBuzz?</p>
            <button id="toggler14" onClick={() => controllButton(14)}>
              {buttonNumber === 14 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 14 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                As long you connecting your Vimeo account, you are covered with
                the same storage capacity Vimeo provides, which we believe that
                solve this issue for good!
                <br />
                <br /> However, via our weekly storage of 20GB limitation as a
                shared space, you can upload relatively a lot, and if you don't
                have much content, it can be a great solution to start with
                uploading content to the platform. However, we always recommend
                connecting your Vimeo account for many convenient reasons also
                for the future.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>What is a Profile ID, and what is it for?</p>
            <button id="toggler15" onClick={() => controllButton(15)}>
              {buttonNumber === 15 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 15 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Your profile ID is a short CV version. Therefore, it is
                recommended to take the time and fill in the details in the most
                accurate and relevant way.
                <br />
                <br /> Visitors can review your profile ID in several ways:
                <br /> 1. Directly via the video players that play your content
                on galleries.
                <br /> 2. Via your profile.
                <br />
                <br /> The Profile ID leverages your chances of getting more
                jobs and growing your prospects.
                <br />
                <br /> More and more media and filters will be available to both
                parties to maintain vital relationships with creators and
                clients who are prioritized for relevant skills.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              Can I share my profile ID on other social networks and via email?
            </p>
            <button id="toggler16" onClick={() => controllButton(16)}>
              {buttonNumber === 16 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 16 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Of course!
                <br /> We recommend doing this with your business contacts as
                this is the way to distribute your free content showcase, which
                will naturally be exposed to your profile ID.
                <br />
                <br /> Once you provide free content regardless of whether it is
                relevant to them, it opens a barrier. It creates curiosity for
                opening that link and watching the substance that leads to
                identifying your profile ID.
                <br />
                <br /> This is the beginning base that we believe makes the
                exposure process more refreshing, diverse, and useful.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Can I reach customers? </p>
            <button id="toggler17" onClick={() => controllButton(17)}>
              {buttonNumber === 17 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 17 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                You will be able to reach customers via published project bids
                from registered users who will post on the site. (We will notify
                when this feature will be available with all other online
                customization options).
                <br />
                <br /> You will also be able to check register users that viewed
                your profile so your communication approach can be more precise
                and relevant.
                <br />
                <br /> However, for the users to be available for replying to
                you in the chatbox, they must be registered.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              What can users do with my free video content after they download
              it from the platform??
            </p>
            <button id="toggler18" onClick={() => controllButton(18)}>
              {buttonNumber === 18 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 18 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Our friendly license provides them to do almost anything they
                want except what they cannot do.
                <br />
                <br /> For detailed answers, please check the "License" page.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              How can I control all my activities and videos on the platform?
            </p>
            <button id="toggler19" onClick={() => controllButton(19)}>
              {buttonNumber === 19 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 19 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                As a registered contributor who already published material on
                the site, you have the "Contributor admin" area where you can
                control everything.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>How can I promote my services on the platform?</p>
            <button id="toggler20" onClick={() => controllButton(20)}>
              {buttonNumber === 20 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 20 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                We understand that you might find it interesting and useful to
                promote your services as a content firm with paid advertising in
                specific pages and categories, and for that pls contact us via
                the "Collaboration" page.
                <br />
                <br /> We will make sure to reply you back as soon as possible
                with the most suitable options.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Can I stop my contributor account anytime I want?</p>
            <button id="toggler21" onClick={() => controllButton(21)}>
              {buttonNumber === 21 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 21 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Of course!
                <br /> We would be sad to see you leave, but you can always
                close your free account at any point you want in VideoBzz.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>Can I freeze specific videos I already published?</p>
            <button id="toggler22" onClick={() => controllButton(22)}>
              {buttonNumber === 22 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 22 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Yes!
                <br /> This function will be available from your Contributor
                admin/My videos Panel.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>What happens after I'm closing down my account?</p>
            <button id="toggler23" onClick={() => controllButton(23)}>
              {buttonNumber === 23 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 23 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                All the videos will be removed automatically from the platform
                as well as your profile. Your Vimeo account will be disconnected
                as well.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              What will be with videos that already been download after closing
              the account?
            </p>
            <button id="toggler24" onClick={() => controllButton(24)}>
              {buttonNumber === 24 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 24 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Nothing can be done once the video or videos has been download
                from your VideoBzz free contributor account under the license
                agreement.
                <br />
                <br /> The videos will be use based on the license terms of the
                platform when first been downloaded.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              Contributor license authorization for his / her free videos on
              VideoBzz?
            </p>
            <button id="toggler25" onClick={() => controllButton(25)}>
              {buttonNumber === 25 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 25 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                Once the contributor has created an account and uploads his /
                her free videos to VideoBzz, the platform provides automatic
                download/embedding access to those videos to anyone browsing the
                platform directly or via third-party integration.
                <br />
                <br /> This means the contributor automatically approves the
                platform license for free download/embedding for free use only
                under the license terms of VideoBzz.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-1 pb-3">
        <div className={classes.FaqCollapse}>
          <div className={classes.PlusBtnDiv}>
            {" "}
            <p>
              Does VideoBzz provide the tools for the online order process with
              users on the platform?
            </p>
            <button id="toggler26" onClick={() => controllButton(26)}>
              {buttonNumber === 26 ? "-" : "+"}{" "}
            </button>
          </div>
          <div>
            {" "}
            <Collapse isOpen={buttonNumber === 26 ? true : false}>
              <p className={classes.Ans}>Answer</p>
              <p className={classes.AnswerP}>
                We are working on this as you read these words.
                <br /> We will offer two types of online services.
                <br /> Our primary solution will provide an easy customization
                process that saves up to 90% of the buyer's communication time.
                <br />
                <br /> In big, we will provide two sets of options to create
                online orders with buyers:
                <br />
                <br /> The primary one is using a pre-ordered online order that
                works with a simple timeline.
                <br /> The video maker can set the video they want to customize
                with friendly video mapping tools in seconds in the video, so
                any placeholders you can customize can be clear to the buyer
                according to parameters like text, fonts, logo, logo location,
                color, photos, photos, BG, Sound, Resolution, etc.
                <br />
                <br /> The buyer will need to select a pre-made video design
                from the gallery and transfer it to the customization timeline.
                Then he adds all the custom topics he wants and sums up the
                order.
                <br />
                <br /> You will eventually receive a full detailed order summary
                that you will know what to put into each parameter (With a
                pre-made video scene takes about 10 minutes to complete). In
                this way, you can make easy money with content you already have
                in your PC and increase your revenue stream through ready
                content.
                <br />
                <br /> The second solution will be more straightforward and
                based on the traditional freelance marketplace principle, which
                is designed for projects that need to be done from scratch
                rather than based on ready content.
              </p>
            </Collapse>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default FaqPage;
