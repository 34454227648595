import './app.css';
import * as React from 'react';
import { getData } from './data';
import classes from "./MetaInfoHelper.module.css";
import $ from 'jquery';
import { MDBCol, MDBIcon } from "mdbreact";
import { css } from "emotion";
import history from "./history"
import httpService from "../../../services/httpService";
import AppContext from "../../../contexts/AppContext";

//
const field1 = css`
display: contents;
align - items: center;
justify - content: space - around;
padding: 10px;
`;
var text_value = "";
var subtitle_value = "";
var overlanguage_value = "";
export default class Choosevideolanguage extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            catedata: getData(),
            nolanguage: false,
            language_text: false,
            language_subtitle: false,
            language_overlanguage: false,
            
           
            checkedOptionValue: "",
            
            
            checkedOptionValue1: "",
            checkeditme: "",
            checkeditme1: "",
            checkeditme2: "",
            filterText : ""
        };
        this.handleclick1 = this.handleclick1.bind(this);
        
        this.handleRadioChange1 = this.handleRadioChange1.bind(this);
        this.onChangepercent = this.onChangepercent.bind(this);
        this.onChangelanguagetext = this.onChangelanguagetext.bind(this);
        this.onChangelanguagetitle = this.onChangelanguagetitle.bind(this);
        this.onChangelanguage_overlanguage = this.onChangelanguage_overlanguage.bind(this);
       
        
    }


    componentDidMount() {
        $("#timelineBadge5").css({ "--customback": "#27da11" })
        $("#timelineBadge1").css({ "--customback": "#27da11" })
        $("#nexticon5").addClass(classes.nexticon);
        $("#choosevideolanguage").show();
        httpService.get("/gallery/languages").then(resp=>{
            this.setState({
              catedata: resp.data.map(category=>{
                return{
                  country: category.title,
                  subs: category.sub_category
                }
              })
            },()=>{
                if (this.context.metaPickerState.Language) {
                  this.setState({
                      catedata: this.state.catedata,
                      ...this.context.metaPickerState.Language
                  });
                }
            })
        })
    }
    
    onChangepercent(e) {
        this.setState(initialState => ({
            nolanguage: !initialState.nolanguage,
        }),()=>{
            this.context.setMetaPickerState({
                Language:{
                    ...this.state
                }
            });
        });
        
    }
    onChangelanguagetext(e) {

        this.setState(initialState => ({
            language_text: !initialState.language_text,
            language_subtitle: false,
            language_overlanguage: false,
            checkeditme: "",
            checkeditme2: "",
            checkeditme1: "",
            checkedOptionValue1: "",
        }),()=>{
            this.context.setMetaPickerState({
                Language:{
                    ...this.state
                }
            });
        });
        
    }
    onChangelanguagetitle(e) {
        this.setState(initialState => ({
            language_text: false,
            language_subtitle: !initialState.language_subtitle,
            language_overlanguage: false,
            checkeditme: "",
            checkeditme2: "",
            checkeditme1: "",
            checkedOptionValue1: "",
        }),()=>{
            this.context.setMetaPickerState({
                Language:{
                    ...this.state
                }
            });
        });
        
    }
    onChangelanguage_overlanguage(e) {
        this.setState(initialState => ({
            language_text: false,
            language_subtitle: false,
            language_overlanguage: !initialState.language_overlanguage,
            checkeditme: "",
            checkeditme2: "",
            checkeditme1: "",
            checkedOptionValue1: "",
        }),()=>{
            this.context.setMetaPickerState({
                Language:{
                    ...this.state
                }
            });
        });
        
    }
    componentDidUpdate() {
        this.updatetimline();
    }

    
    handleRadioChange1 = e => {
        this.setState({ checkedOptionValue1: e.target.value }, ()=>{
            this.props.setNext(8);
        });
        if (this.state.language_text == true)  {
            this.setState({
                checkeditme: e.target.value
            }, ()=>{
                this.context.setMetaPickerState({
                    Language:{
                        ...this.state
                    }
                });
        
            });
        }


        if (this.state.language_subtitle == true) {
            this.setState({
                checkeditme1: e.target.value
            }, ()=>{
                this.context.setMetaPickerState({
                    Language:{
                        ...this.state
                    }
                });
        
            });
        }

        if (this.state.language_overlanguage == true) {
            this.setState({
                checkeditme2: e.target.value
            }, ()=>{
                this.context.setMetaPickerState({
                    Language:{
                        ...this.state
                    }
                });
        
            });
        }

       
    };
    
    handleclick1() {
        if ((this.state.checkedOptionValue1 != "" && (this.state.language_subtitle === true || this.state.language_overlanguage === true || this.state.language_text === true)) || this.state.nolanguage === true) {
            history.push("/user/Choosevideoenvironment");
        }
    }
    updatetimline() {
        if ((this.state.checkedOptionValue1 != "" && (this.state.language_subtitle === true || this.state.language_overlanguage === true || this.state.language_text === true)) || this.state.nolanguage === true) {
            $("#timelinePanel6").addClass(classes.timelinePane2css);
            $("#timelinePanel6").addClass(classes.timelinePane2cssafter);
            $("#timelineBadge6").css({ "--customback": "#a39d9d" })
            $("#timelineBadge1").css({ "--customback": "#27da11" })
            $("#timelineTitle6").css({ "color": "#ca0dd7" });
            $("#nexticon5").css({ "border-top-color": "#0be830" });
        } else {
            $("#nexticon5").css({ "border-top-color": "#b9bcbf" });
        }
        if (this.state.checkedOptionValue === "no_language") {
            $("#nexticon5").css({ "border-top-color": "#0be830" });
            this.state.checkedOptionValue1 = "";
        }
    }
    render() {
        return (
            <div className={field1} id="choosevideolanguage">
               
                <div className="contain">
                    <div className="container-fluid">
                        <div className="filedviode">
                            <div className="headerspell">
                                <span>Choose Video language</span>
                            </div>
                            <div className="serchvideolanguage">
                                <MDBCol md="7">
                                    <div className="input-group md-form form-sm form-1 pl-1">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text purple lighten-3" id="basic-text1">
                                                <MDBIcon className="text-white" icon="search" />
                                            </span>
                                        </div>
                                        <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e)=>{
                                            this.setState({
                                                filterText: e.target.value
                                            })
                                        }}/>
                                    </div>
                                </MDBCol>
                            </div>
                            <div className="categoryfield">
                                <div className="col-xs-5" id="radioviewlanguage">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox"
                                                value="no_langauge"
                                                checked={this.state.nolanguage}
                                                onChange={this.onChangepercent}
                                                className="form-check-input"
                                            />
                                        No language in the video
                                        </label>
                                    </div>
                                    {this.state.nolanguage === false && <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox"
                                                value="language_text"
                                                checked={this.state.language_text}
                                                onChange={this.onChangelanguagetext}
                                                className="form-check-input"
                                            />
                                        Language text in the video{this.state.language_text === true && <span className="herbroew">{(this.state.checkeditme)}</span>}
                                        </label>
                                    </div>}
                                    {this.state.nolanguage === false &&  <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox"
                                                value="language_subtitle"
                                                checked={this.state.language_subtitle}
                                                onChange={this.onChangelanguagetitle}
                                                className="form-check-input"
                                            />
                                        Language subtitles in the video{this.state.language_subtitle && <span className="Esperantoww">{(this.state.checkeditme1)}</span>}
                                        </label>
                                    </div>}
                                    {this.state.nolanguage === false  && <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox"
                                                value="language_overlanguage"
                                                checked={this.state.language_overlanguage}
                                                onChange={this.onChangelanguage_overlanguage}
                                                className="form-check-input"
                                            />
                                        Language voice over in the video{this.state.language_overlanguage && <span className="language_voice">{(this.state.checkeditme2)}</span>}
                                        </label>
                                    </div>}
                                    <hr />
                                    {
                                        this.state.catedata.filter(option=>option.country.toLowerCase().indexOf(this.state.filterText.toLowerCase())!==-1).map((option, i)=>{
                                        return (this.state.language_text === true || this.state.language_subtitle === true || this.state.language_overlanguage === true) && this.state.nolanguage === false && 
                                        <div className="radioitem">
                                            <input
                                            type="radio"
                                            value={option.country}
                                            name="platform"
                                            className="radiobutton"
                                            checked={this.state.checkedOptionValue1 === option.country}
                                            onChange={this.handleRadioChange1}
                                        />
                                            <label className="radiolabel">{option.country}</label>
                                        </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div >
        )
    }
}