import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Badge } from 'reactstrap';

import classes from './Notification.module.css';

import profileImgURL from 'assets/user_profile.png';
import NotificationImg from 'assets/notification.png';


const Notification = (props) => {
  const [notifiDropdownOpen, setNotifiDropdownOpen] = useState(false);
  const toggleNotifi = () => setNotifiDropdownOpen(prevState => !prevState);

  let notifications = [
    { img: profileImgURL, name: "User name", message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { img: profileImgURL, name: "User name", message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { img: NotificationImg, name: "User name", message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { img: NotificationImg, name: "User name", message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { img: NotificationImg, name: "User name", message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }
  ];

  let notificationItems = [];
  notificationItems = notifications.map((item, i) => {
    return <DropdownItem key={"notifi_" + i}>
      <div className={classes.liveIcon}><i className="fas fa-circle"></i></div>
      <div> {item.img ? <img src={item.img} alt="ALT" /> : ''}</div>
      <div> <p>{item.name}</p>
        <p className={classes.MsgNotification}>{item.message}</p>
      </div>
      <div> <i className="far fa-comment-alt"></i></div>
    </DropdownItem>

  });

  return (
    <Dropdown isOpen={notifiDropdownOpen} toggle={toggleNotifi} className={classes.Notification}>
      <DropdownToggle
        tag="div"
        data-toggle="dropdown"
        aria-expanded={notifiDropdownOpen}
        className={props.customClass}
      >
        <img src={NotificationImg} alt="Social" />
      </DropdownToggle>
      <DropdownMenu className={classes.DropDiv}>
        <div className={classes.NotificatioHead}>
          <span>Message and Notification center</span>
          <span onClick={toggleNotifi} className={classes.CloseNtifcation}>
            <i className="fa fa-close"></i>
          </span>
        </div>
        <div className={classes.SubHead}>
          <p><i className="far fa-bell"></i>
            <span className={classes.Topic}> Notification </span>
            <Badge className={classes.Badges}> 1 </Badge>
          </p>
          <p>
            <span className={classes.Topic}>Chat Message </span>
            <Badge className={classes.Badges}> 1 </Badge> <i className="far fa-comment-alt"></i>
          </p>
        </div>
        <div className={classes.ItemNotification}> {notificationItems} </div>
        <div className={classes.FtrNotification}>
          <span className={classes.Setting}> <i className="fas fa-cog"></i>  Notification Setting </span>
          <span className={classes.ChatNote}>open full message & Notification List</span>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}
export default Notification
