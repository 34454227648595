import React from "react";
import { Col, Row } from "reactstrap";
import classes from "./Terms.module.css";
import { IMPRINT_AND_TERMS } from "../../../containers/AppConstant";

const terms = (props) => {
  return (
    <div className={classes.ContributorGuideline}>
      <Col sm="12" md={{ size: 12 }}>
        <div>
          <div className={classes.ContributorTxt}>
            Introduction & Scope. Welcome, please read the following Terms and
            Conditions of Service (hereinafter, the “Terms”), applicable for the
            website www.videobzz.com (the “Site”) carefully. This is a legal
            agreement which sets out the terms and conditions on which Green
            Speed North LTD (hereinafter referred to as “VideoBzz”) will provide
            services to you or to the legal entity you represent ‘you’, ‘your’,
            and/or ‘yourself’. The terms ‘us’, ‘its’, ‘ours’ and/or ‘we’, as
            used herein, shall refer to us, VideoBzz and/or our affiliates,
            assignees, successors and/or brands. These Terms shall supplement
            our Privacy Policy (the “Policy”), incorporated herein by reference.
            Our goal is to help business and marketers, and everyone looking for
            marketing video to find videos that you can use for free subject to
            and in compliance with these Terms. User Agreement & Acceptance. By
            accessing our Site you represent and warrant that you: (a) agree to
            all of the terms and conditions stated herein; and (b) have the
            right, power, and authority to bind your represented entity or the
            agency to these terms and conditions. These Terms constitute a
            legally binding user agreement, along with any and all applicable
            laws and regulations. Henceforth, you hereby acknowledge and
            covenant to abide by and comply with any applicable federal, state
            and local rules and regulations applicable to your use of our
            services. If you do not agree with any of these Terms, you are
            thereby prohibited from using or accessing this Site. Your access to
            and use of the Service is conditioned on your acceptance of and
            compliance with these Terms. These Terms apply to all video makers,
            animators, marketers, visitors, users, and others who access and/or
            use the Service. The Site’s accessibility to the user is solely
            provided for the user’s individual benefit, thus if you are using
            our Site in representation of a corporate third party, you hereby
            represent and warrant that you have the necessary power and
            authority in order to execute this agreement. In order to use the
            functionalities and services provided, you will need to register for
            an account with us, thereby disclosing certain proprietary
            information, along with personally identifiable information. Account
            Registration and Security. In order to use our Services, and in
            particular for uploading videos, you will need to create an account
            with us, including all mandatory fields on our registration forms,
            as requested by us from time to time. You must provide accurate and
            complete information. You agree to keep secret the password chosen
            upon creating your account and not to communicate it to anybody. If
            you lose or disclose it, you must promptly inform us. If your data
            changes after registration, you are obliged to correct the
            information in your account immediately. You are solely responsible
            for the activity that occurs on your account and for keeping your
            password secure and confidential, and must notify us immediately of
            any breach of security or unauthorized use of your account. You will
            be liable for any and all misuse of your account, including the
            fraudulent use by a third party, or even the disclosure of your
            password. We reserve the right to request identification and
            compliance documents from our users, at any time and at our sole
            discretion. VideoBzz does not knowingly provide its Services to
            persons under the age of eighteen (18). Accordingly, we reserve the
            right to request any and all applicable proof of identification and
            consent proof from our users, at any moment, without prior notice,
            and at our sole and final discretion. VideoBzz does not knowingly
            collect any kind of information from any person under the age of
            thirteen (13), and will delete any related information thereto.
            VideoBzz reserves the right to verify the permission of your parents
            or guardian. Therefore, you must provide an email address of your
            parents or guardian when you register so that we can obtain a
            declaration of consent from your parents or guardian. Services
            Offered. VideoBzz provides, operates and manages a marketing video
            marketplace that allows its users to download, syndicate and embed
            multimedia content pieces in various types of third party platforms
            such as Vimeo, for their commercial purposes. Our pro-pre-produced
            videos are sourced by our worldwide talented contributors’
            community. Our cloud-based library provides convenient access to
            tens of thousands of marketing videos for several categories of
            retail/products & services, which can be available as a single video
            or as a video series. We may offer additional functionalities
            through the Site, as indicated from time to time (collectively
            referred to as, the “Services”). Content Provision Terms. We offer
            the Vimeo Inc. account integration in order to import video content
            from a contributor user´s Vimeo account. Such user will be
            classified as our contributing user, and in such manner he/she will
            be able to upload and syndicate multimedia content pieces into our
            Site, all under the terms of this agreement. Other users will be
            classified as consumer users, able to view/download/embed/syndicate
            multimedia content pieces from our Site, in order to use them for
            their legitimate commercial purposes. The video content from the
            contributor´s Vimeo account can be integrated into our Service only
            through the integration and authorization by each contributor user.
            Video(s) from contributor´s Vimeo account can be published on the
            Site only via the "Upload & publish" timeline functionality for each
            of the selected content pieces prior to registration and publishing
            approval. Therefore, we do not accept any scenario of random uploads
            of videos that are not purported to have been pre-selected and
            approved by the contributor. Once released, VideoBzz will be able to
            distribute contributor´s published chosen content on VideoBzz via
            the Vimeo API. Besides Vimeo integration capabilities, VideoBzz
            provides a weekly upload of 20GB share space for contributors that
            don't have a Vimeo account yet or don't have much content to upload
            yet. All uploaded video content can be sorted directly via the
            contributor "Upload" timeline functionality in their user account.
            User Licenses. User Generated Content License. You hereby grant
            VideoBzz a limited, non-exclusive, sub-licensable, assignable,
            royalty-free, perpetual, irrevocable, for all the countries and
            territories through the world, right and license to use, host,
            store, reproduce, modify, create derivative works (such as those
            resulting from translations, adaptations or other changes),
            communicate, publish, publicly perform, publicly display and
            distribute such any content you may upload, disseminate, deliver,
            create or transfer any post, original audio file, message, chat,
            files uploaded, data inputted, e-mails sent, or otherwise any
            content delivered to VideoBzz via the Site or the Services. You
            represent and warrant to VideoBzz that you have all rights,
            authorizations or otherwise hold sufficient title for any and all
            content submitted to VideoBzz as set forth herein. Open Source
            License. In addition to original user generated content, third party
            content which is uploaded/embedded/syndicated into our Service is
            licensed using the 'Creative Commons Zero License' or analogous open
            source licenses. Under such type of licenses, the prime authors have
            dedicated the work to the public domain by waiving all of his or her
            rights to their released content worldwide under copyright law,
            including all related and neighboring rights. Subject to the CC0
            License terms, the released content may be used for all personal and
            commercial purposes without attributing the author/ content owner of
            the content. In such manner, as our contributing user, you will be
            responsible to mark the applicable content with the reference "CC0
            License" or analogous next to the respective content piece made
            available for download and/or embedding in our Service. For more
            information, please review the terms and conditions of the Creative
            Commons Zero license available to the public at
            http://www.creativecommons.org/licenses/by/3.0/ Please be reminded
            that the patent or trademark rights of any individual person, such
            as publicity or privacy rights, are not directly affected by the
            Creative Commons Zero License or other commonly used open source
            licenses, henceforth, depending on the intended use of the Creative
            Commons Zero License content (e.g. for commercial purposes), each
            user will still need to be responsible --and liable to VideoBzz-- of
            securing the authorization and right´s release for the use of their
            personal likeness, voice, face, depiction of identifiable people,
            logos, trademark and/or other copyrightable work depicted in the
            released content. VideoBzz hereby reserves the right to request any
            kind of evidence of such authorization from our users from time to
            time, at the final satisfaction of VideoBzz. As our contributor,
            every time you upload a video piece containing an audio soundtrack
            piece, you will be responsible to ascertain which common license
            will apply to your soundtrack (e.g. CC BY 3.0 / or CC00 types), and
            you will need to mark the license and syndication terms with the
            instruction of the specific artist. VideoBzz License. In order to
            access our Services, VideoBzz grants you a limited and non-exclusive
            license to utilize and access the Services, including access to
            third party syndicated content released for embedding, download and
            commercial exploitation. You are prohibited from duplicating,
            re-engineering, reverse engineering, modifying or otherwise using
            the Services, in whole or in part. For all content made available on
            the Service that is not CC BY 3.0 / or CC00 type, VideoBzz grants
            you an irrevocable, perpetual, non-exclusive, royalty-free license
            to download, copy, modify, distribute, perform, and otherwise use
            the content, including for commercial purposes. The content made
            available on the Service under this license is marked with the
            appertaining reference license text next to the respective content
            piece. For the avoidance of doubt, the VideoBzz license does not
            include: (a) the right to compile any content piece to replicate a
            similar or competing Service; (b) the right to commercially exploit
            copies of the content without first updating, modifying, or
            otherwise incorporating new creative elements into the content (i.e.
            selling unaltered copies of photos, videos or other content),
            including selling the content as prints or printed on physical
            goods. VideoBzz does not grant any express or implied right to you
            under any patents, trademarks, copyrights or trade secret
            information; and you shall have no right, either directly or
            indirectly, to own, use, loan, sell, rent, lease, license,
            sublicense, assign, copy, translate, modify, adapt, improve or
            create any new or derivative works from, or display, distribute,
            perform or in any way exploit any downloaded Services and computer
            applications, in whole or in part. Proprietary Rights. The
            trademarks, copyright, service marks, trade names and other
            intellectual property rights and proprietary notices displayed on
            the Site and the Services are the property of or otherwise are
            licensed to VideoBzz and its licensors and affiliates, whether
            acknowledged (or not), and which are protected under intellectual
            property laws, including copyright laws and treaties and other
            jurisdictions throughout the world. Respective title holders may or
            may not be affiliated with us or our affiliates, partners and
            advertisers. No section hereof shall be construed as intent to grant
            to you any right transfer or interest in the Site or our Services,
            in whole or in part. You acknowledge and agree that any and all
            infringing use or exploitation of copyrighted content in the Site
            and/or the Services may cause us, our affiliates, licensors or
            content providers irreparable injury, which may not be remedied
            solely at law, and therefore our affiliates, licensors or content
            providers may seek remedy for breach of these Terms, either in
            equity or through injunctive or other equitable relief. Specific
            Disclaimer. The music, sound and multimedia content functionalities
            are provided by third party platforms that connect to us via their
            own terms of use and license agreements, which include music
            syndication, streaming and embedding. In order to use the full scope
            of our Services, you will need to open an account with such third
            party platforms, and their service´s provision will be governed by
            their own terms and conditions, not ours. We do allow for the
            syndication and linking to third party platforms that store,
            provide, make available, stream and distribute copyrighted content
            in the form of widgets, web tabs and other type of windows, such as
            Vimeo, SoundCloud, MixCloud, YouTube, and others. While video
            creators and users that upload content to our Site represent and
            warrant to us that they have all the rights therein and that the
            content does not infringe any third party rights, VideoBzz cannot
            reasonably monitor all content uploaded to the Service. We,
            therefore, do not make any representations or guarantees for the
            rights granted hereunder to you. If a song, audiovisual content or
            musical piece is blocked, cancelled, changed or modified, in whole
            or in part, you will not be entitled to any remedy from us. This is
            under the understanding that we do not provide the actual products
            and services offered by such third parties. While we make a
            commercially reasonable effort to ensure the information provided is
            accurate and up-to-date, you as our user are ultimately responsible
            for checking your content pieces and making sure that the suggested
            artists, genres, music, albums and other information is accurate.
            Copyright Takedown Notice. VideoBzz takes copyright infringement
            matters seriously, and is ready to remove any allegedly or factually
            infringing content displayed on the Site upon due notice and request
            by the title holder. The following procedure will apply for any
            content displayed through the Site that allegedly infringes the
            intellectual property rights of you or of any third party. You must
            notify us of your claim with subject: "Copyright Takedown Request".
            Once received, VideoBzz will study and consider your claim and, if
            it believes or has reason to believe any content on the Site
            infringes on another’s copyright, Vehicle Reports may delete it,
            disable or otherwise stop displaying it. Your notification claim
            must be in writing and must at least contain the following
            information: (i) your signature and identification, or the ones the
            person authorized to act on behalf of you or the title holder; (ii)
            a clear and concise description of the content of which its
            copyright has allegedly been infringed; (iii) contact information
            (e.g. address and email); and (iv) a statement, under penalty of
            perjury, indicating that you have a good faith belief that the
            information provided in your claim is true and accurate. Given our
            business model, our affiliates, advertisers, partners and third
            party syndicated content providers may be adversely affected due to
            a Copyright Takedown Notice. Accordingly, upon receipt of any such
            notice, we will try to contact the content owner or webmaster of the
            affected site to uphold their right to a Counter Claim Notice. To
            file a Counter Claim Notice, please contact our team indicated
            above, in writing, and with a Counter Claim Notice containing the
            following information, at a minimum: (i) express identification of
            the content that we may have or have removed from our Site and
            section or domain it was displayed; (ii) contact information, such
            as your address and your email address; (iii) a signature and
            identification of the title holder and/or the person authorized to
            act; and (iv) a statement indicating that you swear, under penalty
            of perjury, that you have a good faith belief that the information
            provided in the Counter Claim Notice is true and accurate.
            Third-party Websites and Content. From time to time, our Site and
            Services may contain hyperlinks to other websites. These links are
            for your personal convenience and to provide you with further
            information which may be of interest to you. The provision of such
            links does not imply any endorsement of such third-party websites
            (or their products and services). Please review the applicable terms
            and policies of such websites, including their privacy and data
            collection practices. We may place ads and promotions from
            third-party sources in the Site. Accordingly, your participation or
            undertakings in promotions of third-parties other than VideoBzz, and
            any terms, conditions, warranties or representations associated with
            such undertakings, are solely between you and such third-party.
            VideoBzz is not responsible or liable for any loss or damage of any
            sort incurred as the result of any such dealings or as the result of
            the presence of third-party advertisers on the Site. Contests,
            Sweepstakes, and Promotions. From time to time, Occasionally,
            VideoBzz or our partners and/or advertising partners may undertake
            promotional activities on the Site, including but not limited to,
            contests, sweepstakes or other type of promotions. Each of these
            undertakings is subject to additional special conditions which will
            be published on our Site or otherwise notified to users. When
            participating in such activities, each user must separately agree to
            the applicable terms and conditions of participation thererinto.
            After user approval is granted, such conditions will become an
            integral part of these Terms. Upon the case of a conflict between
            such conditions and the Terms, the conditions of such undertaking
            shall prevail. All prizes in connection with a Promotion are
            non-transferable and cannot be changed for cash or other products or
            services. VideoBzz reserves the right to modify or replace the
            Promotion prizes. User Support. You can always contact our team with
            questions about our Services, and we will strive to answer them and
            work together in order to resolve any such doubts. If you have any
            questions or complaints regarding the Site or our Services, please
            contact us by email as indicated on our contact web page. We will
            undertake commercially reasonable efforts in order to answer as
            quickly as possible. You must provide us with full details of your
            service query so that we can clearly asses your concerns. Changes to
            the Services. VideoBzz reserves the right to modify, amend, suspend,
            terminate, upgrade, update or otherwise modify these Terms, the Site
            and the Services, at any time and without notice. Any changes will
            be displayed on the Site, and we may also notify you by email.
            Please refer to the last effective date where changes were last
            undertaken by us. Your use of our Services after the effective date
            of any update– either by an account registration or simple use –
            thereby indicates your acceptance thereof. Service Level Agreement.
            For technical inquiries, please contact our tech support team.
            Notwithstanding the foregoing, some of the Services and
            functionalities of the Site are offered by third party platforms
            such as Amazon Web Services. Accordingly, any and all
            functionalities of the Site are subject to their terms and
            conditions and Service Level Agreements, available online for your
            reference. User Privacy. By disclosing any data and personally
            identifying information to us, you agree to our Policy, including
            the collection, process, storage and disclosure of such personally
            identifiable information, including to our affiliates, partners and
            clients. We will ask for your express consent, including for
            inclusion into our newsletters, updates, and follow ups. For more
            information, please read our Policy. Confidentiality. Neither party
            shall disclose any Confidential Information to any third party
            except to its employees, attorneys, tax or accounting professionals
            who have a legitimate need to know and who have agreed to be bound
            by the provisions of a confidentiality agreement at least as
            stringent as the ones herein. Each party shall further protect the
            Confidential Information to the same extent as it protects its own
            information of a similar type. For the purposes herein stated,
            “Confidential Information” shall be defined as the digital, verbal
            and/or written disclosures, documents or communications, stored in
            either written, graphic, digital, optical electromagnetic form or in
            software as a service systems, commonly known as “the cloud”,
            e-mails, optical disks, memory cards or removable drives or any
            other means that can retain information. Confidential Information
            shall not include, information that: (i) is in the public domain at
            the time of disclosure; (ii) becomes publicly available through no
            fault of the recipient party and without breach of these Terms,
            (iii) is already in the lawful possession of a party without
            restriction prior to disclosure; (iv) becomes rightfully known to a
            party without restriction from a source other than the disclosing
            party; or that (v) is required to be disclosed by virtue of an order
            of a competent court or a legal requirement; shall not be deemed
            Confidential Information. User Representations and Warranties. You
            hereby represent, warrant and covenant that: (i) your use of our
            Site and Services, and all your uploaded and used data shall be at
            all times compliant with these Terms and all local, state, federal
            and international laws and regulations applicable to you and your
            organization; (ii) you have obtained all necessary rights, releases
            and permissions to provide any data to VideoBzz and its affiliates,
            licensors and agents; and to grant the rights granted to VideoBzz in
            these Terms, including without limitation any intellectual property
            rights or rights of publicity, privacy and any use, collection and
            disclosure authorized. Term, Termination. The term hereof shall
            begin on the date that comes first among: (i) first access to the
            Site; (ii) your first access or execution of our Services; or (iii)
            VideoBzz begins providing its Services to you. The term hereof will
            automatically end on the earlier date of either your: (i) account
            deactivation, suspension, freezing or deletion; (ii) access
            termination or revocation for our Services; (iii) VideoBzz'
            termination of these Terms, at its sole and final discretion; (iv)
            the termination date indicated by VideoBzz to you from time to time;
            or (v) VideoBzz’ decision to make the Site or the Services no longer
            available for use, at its sole and final discretion. Termination for
            cause by VideoBzz. These Terms, along with any and all licenses
            granted hereof may or will automatically terminate if you breach any
            of the terms and conditions contained herein. Upon termination for
            breach, your rights to use our Site, our Services and any
            information provided or generated thereby shall cease and you shall
            not be entitled to any compensation, credit, remedy or refund of any
            nature. In this case, please contact our support department in order
            to initiate the process of receiving a backup copy of your user
            data, if any, subject to the terms and conditions of our providers,
            such as Amazon Web Services. User Code of Conduct. As our user, you
            agree not to undertake, motivate, or facilitate the use or access of
            the Site or the Services in order to: ● Infringe these Terms, or
            allow, encourage or facilitate others to do so. ● Plagiarize and/or
            infringe on the intellectual property rights or privacy rights of
            any third party, including any breach of confidence, copyright,
            trademark, patent, trade secret, moral right, privacy right, right
            of publicity, or any other intellectual property or proprietary
            right. ● Collect, receive, transfer or disseminate any personally
            identifiable information of any person without due consent from the
            title holder. ● Republish, sell, rent or sub-license content or
            materials from the Site without our authorization. ● Reproduce,
            duplicate or copy material from the Site without our authorization.
            ● Distribute, post or otherwise make available any content that: (i)
            infringes on or endangers the intellectual property rights of any
            person (e.g. trademark, moral rights, trade secret, copyright,
            ancillary rights or other); (ii) enables any act that could promote
            or cause discrimination, racism, harm, libel, hatred or violence
            against any individual or group; (iii) endangers children and
            underage persons; (iv) is or allows illegal or fraudulent activities
            to take place; (v) is or may constitute a criminal or capital
            offense or otherwise infringes on any applicable law; and/or (vi) is
            or can be considered to be obscene, sexually explicit material,
            pornographic, threatening/defamatory, plagiarized, firearms,
            tobacco, alcohol, marijuana, gambling, binary options, Forex,
            pornographic or analogous material. ● Collect, receive, transfer or
            disseminate any personally identifiable information of any person
            without due consent from the title holder. ● Use any automated or
            manual process to obtain, copy, process, access and/or use our Site
            or Services or any part therefrom to capture unauthorized data or
            content, for any purpose. ● Enable, undertake, program or execute
            any type of system, computer program or technique in order to
            data-mine, retrieve, scrape, index or otherwise extract unauthorized
            information from VideoBzz or any portion or data feeds therefrom.
            For purposes of clarification, such actions will include the use of
            persons, site search/retrieval applications, software ‘robots’ and
            ‘spiders’ and any analogous data gathering and extraction tools,
            regardless of the type and amount of information intended for
            extraction. ● Use the our Services to disseminate any type of
            computer viruses, worms, defects, trojan horses or other items of a
            destructive nature. ● Undertake any action that will or may cause an
            unreasonable load on VideoBzz’ and/or its partners’ and affiliates’
            technology or infrastructure, or otherwise make excessive traffic
            demands from VideoBzz. ● Intercept or monitor activity via our Site
            or Services, without our express authorization. ● Otherwise reverse
            engineer, decompile or extract the proprietary code of the Site and
            our Services. Account Suspension & Termination. VideoBzz encourages
            you to report violations of our Terms. Users undertaking conducts
            that may constitute a factual –or even alleged– breach of these
            Terms, including but not limiting, using automated mechanisms to
            make fraudulent communications, may become subject to immediate
            account suspension/termination, at our sole and final discretion,
            without notice and without responsibility. We reserve the right, at
            our sole and final discretion, to deactivate, freeze, suspend or
            terminate any account upon any factual or alleged breach of these
            Terms. You must notify us immediately of any change in your
            eligibility to use our platform, or if you suspect a breach of
            security or unauthorized use of your account. You acknowledge and
            agree that we may report any activity that we believe may violate
            any law to law enforcement, regulators or other relevant third
            parties, and that any violation of the aforementioned provisions may
            result in the immediate termination of your access to the Site or
            our Services. Disclaimer of Warranty. To the fullest extent
            permissible under applicable law, the Site and the Services are
            provided to you “as is”, with “all faults” and “as available”,
            without warranty of any kind. VideoBzz, and its affiliates, clients,
            agents, officers, licensors and/or distributors, do not make, and
            hereby disclaim, any and all express, implied or statutory
            warranties, either by statute, common law, custom, usage of trade,
            course of dealing or otherwise, however arising, including implied
            warranties of description, quality, fitness for a particular
            purpose, non-infringement, non-interference with use and/or
            enjoyment. In no event shall VideoBzz, its affiliates, clients,
            agents, officers, licensors, distributors and/or any authorized
            third party, be held liable for any special, indirect, incidental or
            consequential damages, including losses, costs or expenses of any
            kind resulting from possession, access, use, inaccessibility or
            malfunction of the Site or the Services, including but not limited
            to, loss of revenue, profits, business, loss of use or lack of
            availability of computer resources; whatsoever arising out of or
            related thereto, whether arising in tort (including negligence),
            contract, strict liability or other legal or equitable theory and
            whether or not VideoBzz, its affiliates, clients, licensors and/or
            distributors have been advised of the possibility of such damages.
            Limitation of Liability. If a user suffers loss or damage as a
            result of VideoBzz’ negligence or failure to comply with these
            Terms, any claim by such user against us will be limited in respect
            of any one incident, or series of connected incidents, to the fees
            paid by such user in the previous 3 months or the amount of direct
            loss or damage suffered by Customer, whichever is less. These Terms
            provide you with specific legal rights, and you may have other
            rights that may vary from jurisdiction to jurisdiction. Legislation
            of some states/countries does not allow certain limitations of
            liability, and henceforth this limitation of liability shall apply
            to the fullest extent permitted by law in the applicable
            jurisdiction. Indemnification. You shall indemnify, hold harmless,
            and defend VideoBzz, its affiliates, clients, agents, officers,
            licensors, distributors and/or any authorized representatives, and
            the officers, directors and employees of each (jointly, the
            “VideoBzz’ Indemnitees”) from and against any and all third party
            liabilities, claims, causes of action, suits, losses, damages,
            fines, judgments, settlements and expenses (including any and all
            reasonable outside attorneys’ fees and court costs) which may be
            suffered, made or incurred by any of such VideoBzz’ Indemnitees
            arising out of or relating to: (i) any breach of any warranties,
            representations and/or covenants made by you hereunder (to the
            extent not arising substantially from any breach hereof by
            VideoBzz); and/or (ii) any third party claim arising out of or in
            relation to VideoBzz or use thereof in combination with your
            business platform, including without limitation, any claim that
            VideoBzz violates, infringes, or misappropriates any proprietary or
            intellectual property right of any third party, including without
            limitation, any privacy right of any person, or violates any
            applicable law. General Terms. Assignment. These Terms will inure to
            the benefit of any successors of the parties. Force Majeure.
            VideoBzz shall not be liable for any failure of performance on its
            obligations as set forth herein, where such failure arises from any
            cause beyond our reasonable control, including but not limiting to,
            electronic, power, mechanic or Internet failure. No Embargo. You
            hereby represent and warrant that: (i) you are not located in a
            country that is subject to an international government’s embargo, or
            that has been designated by any nation’s government as a “terrorist
            supporting” country; and (ii) you are not listed on any government’s
            list of prohibited or restricted parties or activities. Entire
            Agreement. These Terms set forth the entire agreement between the
            parties hereof and may not be altered or amended except in writing
            signed by both parties. Governing Language. From time to time, our
            Terms may be translated into other languages for your convenience.
            The English version of each of these documents shall be the version
            that prevails and governs your use of our Services. Upon the case of
            any conflict between the English version and any translated version,
            the English version will prevail. Newsletters. The Site may allow
            you to subscribe to our newsletter service, which may be provided by
            us or through an authorized third party. Through our newsletters,
            you may receive information according to your subscriber
            preferences. As our subscriber, you will receive a conspicuous
            communication indicating any subscription and you will be able to
            select the amount and type of emails received by you. If you wish to
            unsubscribe, you will find ‘unsubscribe’ and similar links on our
            communications. No Waiver. Failure by VideoBzz to enforce any rights
            hereunder shall not be construed as a waiver of any rights with
            respect to the subject matter hereof. No Relationship. You and
            VideoBzz are independent contractors, and no agency, partnership,
            joint venture, employee-employer, or franchiser-franchisee
            relationship is intended or created by these Terms. Notices. All
            notices and other communications given or made pursuant to these
            Terms must be in writing and will be deemed to have been given upon
            the earlier of actual receipt or: (a) personal delivery to the party
            to be notified; (b) when sent, if sent by facsimile or electronic
            mail during normal business hours of the recipient, and if not sent
            during normal business hours, then on the recipient’s next business
            day; (c) five days after having been sent by registered or certified
            mail, return receipt requested, postage prepaid; or (d) one business
            day after deposit with a nationally recognized overnight courier,
            freight prepaid, specifying next business day delivery, with written
            verification of receipt. Each party agrees to receive electronic
            documents and to accept electronic signatures, which shall thereto
            be considered valid substitutes for hardcopy documents and hand
            inked signatures. Severability. If any provision of these Terms is
            held unenforceable, then such provision will be modified to reflect
            the parties' intention. All remaining provisions of these Terms will
            remain in full force and effect. Taxes. You are responsible for
            complying with all tax obligations associated with your account. It
            is important that you keep complete and accurate contact details in
            order for us to remit invoices, especially payment processing
            account details. Applicable Law. You agree to submit to the
            applicable laws in Israel, which will govern these Terms and any
            claim, without regard to conflict of law provisions. Forum. For the
            application of this arbitration agreement, and of other matters
            appertaining these Terms, the parties hereto agree to submit to the
            personal jurisdiction of the courts located in Israel. If you bring
            a dispute in a manner other than in accordance with this section,
            you agree that we may move to have it dismissed, and that you will
            be responsible for our reasonable attorney’s fees, court costs, and
            disbursements in doing so. Contact. If you have any questions or
            comments about us, our Site, our Policy and/or these Terms, please
            contact us at: VideoBzz LTD hello@greenspeednorth.com Address: Amat
            Hamaim 9A 3033309 Atlit - Israel CRN: 51-481-2796 Date of last
            effective update is [---------], 2020.
          </div>
        </div>
      </Col>
    </div>
  );
};
export default terms;
