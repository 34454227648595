import React from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import classes from "./CustomModals.module.css";
import UserProfileImg from "components/UI/UserProfileImg/UserProfileImg";
import Paypal from "assets/paypal.png";

const ThankYouModal = (props) => {
  let modal = props.isOpen;
  const toggle = () => (modal = !modal);

  return (
    <Modal
      size={props.modalSize ? props.modalSize : "lg"}
      isOpen={modal}
      backdrop={false}
      fade={false}
      toggle={toggle}
      className={classes.EmbeddingCodeModal}
      contentClassName={classes.Test}
    >
      <ModalHeader className={classes.MHeader} toggle={props.closed}>
        <UserProfileImg />
        <p className={classes.ProfilePopname}> Lior Bazak </p>
        {/* <Button className={classes.BtnFreeDown}
                        onClick={() => this.handler.downloadVideo()}
                    >Free Download</Button> */}
        <Row className={classes.ModalNav}>
          <p>Follow</p>
          <p>Donate</p>
          <p>
            {" "}
            <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon> Likes
          </p>
        </Row>
      </ModalHeader>
      <ModalBody className={[classes.ModalBody, classes.ModalMg].join(" ")}>
        <Col sm="12" md={{ size: 7, offset: 3 }} classs="text-center p-0">
          <p className={classes.Hii}>Say hi to Lior Bazak</p>
          <div className={classes.SocialIcon}>
            <Link to={"/"}>
              <div className={classes.SocialInsta}> </div>
            </Link>
            <Link to={"/"}>
              <div className={classes.SocialLinked}> </div>
            </Link>
            <Link to={"/"}>
              <div className={classes.SocialFace}> </div>
            </Link>
            <Link to={"/"}>
              <div className={classes.SocialTwitt}> </div>
            </Link>
          </div>
        </Col>
        <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-5 mb-5">
          <p className={classes.Donate}>
            Donate <img src={Paypal} alt="IMG" className="img-fluid" />{" "}
          </p>
          <span className={classes.SmallLine}>
            It's a great way to say to Lior thanks and keep the good work!
          </span>
        </Col>
        <Col sm="12" md={{ size: 12 }}>
          <p className={classes.SponsorAd}>Sponsor ad space</p>
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default ThankYouModal;
