import React, { useEffect, useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";

import { Row, Col } from "reactstrap";

import classes from "./User.module.css";
import SearchHeader from "components/Gallery/SearchHeader/SearchHeader";

import appContext from "../../contexts/AppContext";

const User = (props) => {
  const app = useContext(appContext);

  const myVideos = (
    <Link
      to={"/user/my-videos"}
      className={
        props.location.pathname === "/user/my-videos" ? classes.ActiveTab : ""
      }
    >
      My videos
    </Link>
  );

  return (
    <div className={classes.User}>
      <Row className={classes.Norow}>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <SearchHeader
            isSearchHederTextHide={true}
            isRoyaltyFreeTextHide={true}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 12 }} lg={{ size: 12 }} xl={{ size: 12 }}>
          <div className={classes.AdminItem}>
            <Link
              to={"/user"}
              className={
                props.location.pathname === "/user" ? classes.ActiveTab : ""
              }
            >
              Set Your Vimeo account
            </Link>
            {app.vimeoIntegrated && myVideos}
            <Link
              to={"/user/personal-details"}
              className={
                props.location.pathname === "/user/personal-details"
                  ? classes.ActiveTab
                  : ""
              }
            >
              Info & Personal details
            </Link>
            <Link
              to={"/user/payment-details"}
              className={
                props.location.pathname === "/user/payment-details"
                  ? classes.ActiveTab
                  : ""
              }
            >
              Payment details
            </Link>
            {!app.vimeoIntegrated && myVideos}
            <Link
              to={"/user/earnings"}
              className={
                props.location.pathname === "/user/earnings"
                  ? classes.ActiveTab
                  : ""
              }
            >
              Earnings
            </Link>
            <Link
              to={"/user/general-status"}
              className={
                props.location.pathname === "/user/general-status"
                  ? classes.ActiveTab
                  : ""
              }
            >
              General status
            </Link>
            <Link
              to={"/user/preferences"}
              className={
                props.location.pathname === "/user/preferences"
                  ? classes.ActiveTab
                  : ""
              }
            >
              Preferences
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(User);
