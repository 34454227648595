import React from "react";
import classes from "./UserType.module.css";

import BuyerImg from "assets/Buyer2.gif";
import SellerImg from "assets/Seller2.gif";
import HeaderImg from "assets/Header.gif";

import { Button, Row } from "reactstrap";
import httpService from "services/httpService";

const completeRegistration = (type) => {
  httpService
    .get(`/user/complete-registration?type=${type}`)
    .then((resp) => {
      window.location.href = "/";
    })
    .catch((err) => {
      console.error(err);
    });
};

const UserType = () => {
  return (
    <div className={classes.Container}>
      <img className={classes.Main} src={HeaderImg} alt="IMG" />
      <div style={{ display: "flex" }}>
        <div className={classes.Buyer}>
          <div style={{ textAlign: "center" }}>
            {/* <Row>
              <p>If you want to download great videos</p>
            </Row> */}
            <Row style={{ marginTop: "-12px" }}>
              <img src={BuyerImg} alt="Buyer" />
            </Row>
            <div style={{ textAlign: "center", width: "100%" }}>
              <Button
                onClick={() => completeRegistration("buyer")}
                outline
                color="success"
              >
                Choose
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.Seller}>
          <div style={{ textAlign: "center" }}>
            {/* <Row>
              <p>If you want to share your videos with the world</p>
            </Row> */}
            <Row>
              <img src={SellerImg} alt="Seller" />
            </Row>
            <div style={{ textAlign: "center", width: "100%" }}>
              <Button
                onClick={() => completeRegistration("seller")}
                outline
                color="primary"
              >
                Choose
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserType;
