import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  InputGroup,
  InputGroupButtonDropdown,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupText,
} from "reactstrap";

import classes from "./SearchInput.module.css";
import { loadCategories, searchGalleries } from "store/actions";

const mapStateToProps = (state) => ({
  categories: state.categories,
  searchQuery: state.searchQuery,
});
const mapDispatchToProps = (dispatch) => ({
  loadCategories: (categories) => dispatch(loadCategories(categories)),
  searchGalleries: (inp) => dispatch(searchGalleries(inp)),
});

class SearchHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      searchValue:
        this.props.location.pathname === "/" ? this.props.searchQuery : "",
    };

    this.handler.onChange = this.handler.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.categories === undefined) {
      this.props.loadCategories();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.dropdownOpen !== this.state.dropdownOpen ||
      nextProps.categories !== this.props.categories ||
      nextState.searchValue !== this.state.searchValue
    );
  }

  handler = {
    setDropdownOpen: (dropdownOpen) => {
      this.setState({ dropdownOpen: dropdownOpen });
    },
    onChange: (e) => {
      this.setState({ searchValue: e.target.value });
    },
    searchGallery: () => {
      if (this.state.searchValue !== this.props.searchQuery) {
        let payload = {};
        payload.searchQuery = this.state.searchValue;
        payload.endpoint = "gallery/?query=" + this.state.searchValue;
        this.props.searchGalleries(payload);
        if (this.props.location.pathname !== "/") {
          this.props.history.push(`/`);
        }
      }
    },
  };

  render() {
    let categories = [];
    if (this.props.categories) {
      categories = this.props.categories.map((cat) => {
        return (
          <div key={"cat_" + cat.id}>
            <DropdownItem header>{cat.title}</DropdownItem>
            <div className={classes.Category}>
              {cat.sub_category.map((subcat) => {
                return (
                  <span key={"subcat_" + subcat.id}>
                    <p>{subcat.title}</p>
                  </span>
                );
              })}
            </div>
          </div>
        );
      });
    }

    return (
      <InputGroup className={[classes.Search, classes.SearchInput].join(" ")}>
        <Input
          value={this.state.searchValue}
          onChange={this.handler.onChange}
          placeholder="Search"
          className={classes.Brdrnone}
        />
        <span
          onClick={(event) => this.handler.searchGallery()}
          className={classes.Btnigrp}
        >
          <i style={{ fontSize: 14, padding: "4px 1px 2px" }}></i>
        </span>
        <InputGroupButtonDropdown
          addonType="append"
          isOpen={this.state.dropdownOpen}
          toggle={() => this.handler.setDropdownOpen(!this.state.dropdownOpen)}
        >
          <DropdownToggle className={classes.Btnintgrp}>
            Categories
          </DropdownToggle>
          <DropdownMenu className={classes.SearchDrop}>
            <div className={classes.Overflow}>
              <Input placeholder="Search" className={classes.InnerSrch} />
              <p className={classes.Cateheading}>
                Mix & match multiple categories search
              </p>
              <span className={classes.Checked}>
                {" "}
                <p> With % offer </p>{" "}
              </span>
              {categories}
            </div>
          </DropdownMenu>
        </InputGroupButtonDropdown>
      </InputGroup>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchHeader));
