
import './app.css';
import * as React from 'react';
import { getData } from './data';
import classes from "./MetaInfoHelper.module.css";
import $ from 'jquery';
import { css } from "emotion";
import history from "./history";
import httpService from "../../../services/httpService";
import AppContext from "../../../contexts/AppContext";

//
const field1 = css`
display: contents;
align - items: center;
justify - content: space - around;
padding: 10px;
`;
export default class Choosevideo extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.state = {
            catedata: getData(),
            sexvalue: "",
            checkedOptionValue: "",
            textaflag: false,
            descrition: ""
        };
        this.onChangeValue = this.onChangeValue.bind(this);
        this.hadlehange = this.hadlehange.bind(this);
        this.handleclick1 = this.handleclick1.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    updateStep(){
        if ((this.state.checkedOptionValue != "" && this.state.sexvalue === "yes" && this.state.descrition != "") || (this.state.checkedOptionValue != "" && this.state.sexvalue === "no")) {
            this.props.setNext(7);
        }else{
            this.props.setNext(6);
        }

    }
    onChangeValue(event) {
        this.setState({ 
            sexvalue: event.target.value,
            textaflag: event.target.value == "yes"
        }, ()=>{
            this.updateStep();
            this.context.setMetaPickerState({
                CM: {
                    ...this.context.metaPickerState.CM,
                    sexvalue: this.state.sexvalue,
                    textaflag: this.state.textaflag     
                }
            });
        })
        console.log(this.state.sexvalue);
    }
    hadlehange(e) {
        this.setState({ descrition: e.target.value }, ()=>{
            this.updateStep();
            this.context.setMetaPickerState({
                CM: {
                    ...this.context.metaPickerState.CM,
                    descrition: this.state.descrition
                }
            });
        });
        console.log(this.state.descrition)
    }
    handleRadioChange(e) {
        this.setState({ checkedOptionValue: e.target.value }, ()=>{
          this.updateStep();
          this.context.setMetaPickerState({
              CM:{
                  checkedOptionValue: this.state.checkedOptionValue
              }
            });
        });
    }
    handleclick1() {
        if ((this.state.checkedOptionValue != "" && this.state.sexvalue === "yes" && this.state.descrition != "") || (this.state.checkedOptionValue != "" && this.state.sexvalue === "no")) {
            history.push("/user/Choosevideolanguage");
        }
    }
    componentWillMount() {
        $("#timelineBadge1").addClass(classes.eventtrriger);

        $("#timelineBadge4").addClass(classes.eventtrriger);
        $("#nexticon4").addClass(classes.nexticon);

    }
    componentDidMount() {
        $("#timelineBadge1").css({ "--customback": "#27da11" })
        $("#choosevideo").show();
        $("#timelineBadge4").css({ "--customback": "#27da11" })
        $("#nexticon4").addClass(classes.nexticon);
        httpService.get("/gallery/creative-methods").then(resp=>{
            this.setState({
              catedata: resp.data.map(category=>{
                return{
                  country: category.title,
                  subs: category.sub_category
                }
              })
            }, ()=>{
                if (this.context.metaPickerState.CM){
                    this.setState({
                      checkedOptionValue: this.context.metaPickerState.CM.checkedOptionValue,
                      sexvalue: this.context.metaPickerState.CM.sexvalue,
                      descrition: this.context.metaPickerState.CM.descrition?this.context.metaPickerState.CM.descrition:"",
                      textaflag: this.context.metaPickerState.CM.textaflag
                    }, ()=>{
                        this.updateStep();
                    });
                }
            })
          })
    }
    
    componentDidUpdate() {
        this.updatetimline();
    }

    updatetimline() {
        if ((this.state.checkedOptionValue != "" && this.state.sexvalue === "yes" && this.state.descrition != "") || (this.state.checkedOptionValue != "" && this.state.sexvalue === "no")) {
            $("#timelinePanel5").addClass(classes.newtimlepaintl1);
            $("#timelinePanel5").addClass(classes.newtimlepaintl2);
            $("#timelineBadge1").css({ "--customback": "#27da11" })
            $("#timelineBadge5").css({ "--customback": "#a39d9d" })
            $("#timelineTitle5").css({ "color": "blue" });
            $("#nexticon4").css({ "border-top-color": "#0be830" });
        } else {
            $("#nexticon4").css({ "border-top-color": "#b9bcbf" });
        }
    }
    render() {
        return (
            <div className={field1} id="choosevideo">
               
                <div className="contain-video">
                    <div className="container-fluid">
                        <div className="filedviode">
                            <div className="headerspellvideo">
                                <span>Choose Video Creative methods</span>
                            </div>
                            <div className="col-xs-5" id="textareinptfield">
                                {this.state.catedata.map((option, i) => (
                                    <div key={i} className="radioitem">
                                        <input
                                            type="radio"
                                            name="site_name"
                                            value={option.country}
                                            className="radiobutton"
                                            checked={this.state.checkedOptionValue === option.country}
                                            onChange={this.handleRadioChange}
                                        />
                                        <label className="radiolabel">{option.country}</label>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    <div className="checkboxvideo">
                        <span className="videosound">The video has soundtrack?</span>
                        <div onChange={this.onChangeValue} className="radiogroup">
                            <div className="yesfield" >Yes <input type="radio" value="yes" name="gender" checked={this.state.sexvalue=="yes"}/></div>
                            <div className="nofield" > No <input type="radio" value="no" name="gender" checked={this.state.sexvalue=="no"}/></div>
                        </div>
                        {this.state.textaflag && <div>
                            <div className="textareat"><textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="5"
                                value={this.state.descrition}
                                placeholder="Paste license  "
                                onChange={this.hadlehange}
                            /></div>
                            <div className="fomrefield"><span className="formore">For more Royalty-free and common license terms of use,please press here</span></div>
                        </div>}
                    </div >
                </div>

               
            </div >
        )
    }
}