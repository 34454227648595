import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import {
  Table,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

import classes from "./MyVideos.module.css";
import httpService from "services/httpService";
import { useTable, useColumnOrder } from "react-table";
import { createDndContext, DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { DRow } from "./DRow";
import StyledEditText from "./StyledEditText";

const MySeries = ({ album_id, series_id, handler }) => {
  const [videos, setVideos] = useState([]);
  const [series, setSeries] = useState([]);
  const [album, setAlbum] = useState([]);
  const [ui, setUi] = useState({});
  const [test, setTest] = useState(false);

  useEffect(() => {
    getVideosForSeries();
  }, []);

  const columns = useMemo(
    () => [
      // { Header: "Id", accessor: "id" },
      { Header: "Video", accessor: "video" },
      { Header: "Vid/Ser name", accessor: "name" },
      { Header: "Single / Series", accessor: "single" },
      { Header: "Upload Date", accessor: "date" },
      { Header: "Online Custom", accessor: "custom" },
      { Header: "Share link", accessor: "slink" },
      { Header: "Download link", accessor: "dlink" },
      { Header: "Embed link", accessor: "elink" },
      { Header: "Views", accessor: "views" },
      { Header: "Video source", accessor: "vsource" },
      { Header: "Status", accessor: "status" },
      // { Header: "Drag", accessor: "grip" },
    ],
    []
  );

  const getVideosForSeries = () => {
    httpService
      .get(`/gallery/${album_id}/series/${series_id}`)
      .then((res) => {
        console.log(res);
        setVideos(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    httpService
      .get(`/gallery/mine/`)
      .then((res) => {
        const series = [];
        res.data.results.forEach((v) => [
          v.thumbnail_video.series.forEach((s) => series.push(s)),
        ]);
        setSeries(series);
        console.log(series);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moveVideo = (video, series) => {
    console.log(video, series);
    httpService
      .post(`gallery/series/${series.id}/`, {
        moveVideo: video.id,
      })
      .then((res) => {
        console.log(res);
        getVideosForSeries();
        // setVideos(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const data = React.useMemo(() => makeData(20), []);
  const data = useMemo(() => {
    const vlist = videos.results || videos.videos;
    console.log(`Series=${series}`);
    return vlist
      ? vlist.map((videoi) => {
          const video = videoi.thumbnail_video
            ? videoi.thumbnail_video
            : videoi;
          return {
            id: video.id,
            video: (
              <img
                src={video.image}
                className="img-fluid"
                width="50"
                height="50"
                alt="IMG"
              />
            ),
            name: video.thumbnail_video
              ? video.thumbnail_video.title
              : video.title,
            single: videoi.thumbnail_video ? (
              videoi.videos_count == 1 ? (
                <Dropdown
                  isOpen={ui[video.id]}
                  toggle={() => {
                    console.log(`test=${ui[video.id]}`);
                    ui[video.id] = !ui[video.id];
                    setUi({ ...ui });
                    // setTest(test ? false : true);
                  }}
                >
                  <DropdownToggle caret>Move to series</DropdownToggle>
                  <DropdownMenu>
                    {series.map((s) => {
                      return (
                        <DropdownItem onClick={() => moveVideo(video, s)}>
                          {s.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              ) : (
                // <Button onClick={() => getVideosForSeries(videoi)}>
                //   {"Series: " + videoi.thumbnail_video.series[0].id}
                // </Button>
                <Button
                  onClick={() =>
                    handler("ALBUMDETAILS", videoi.thumbnail_video.id)
                  }
                >
                  {"Details"}
                </Button>
              )
            ) : (
              <Dropdown
                isOpen={ui[video.id]}
                toggle={() => {
                  console.log(`test=${ui[video.id]}`);
                  ui[video.id] = !ui[video.id];
                  setUi({ ...ui });
                  // setTest(test ? false : true);
                }}
              >
                <DropdownToggle caret>Move to series</DropdownToggle>
                <DropdownMenu>
                  {series.map((s) => {
                    return (
                      <DropdownItem onClick={() => moveVideo(video, s)}>
                        {s.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            ),
            date: "now",
            custom: "cs",
            elink: (
              <Button
                onClick={() =>
                  navigator.clipboard.writeText(
                    video.resolutions[0].embeded_url
                  )
                }
              >
                Copy
              </Button>
            ),
            dlink: <a href={video.resolutions[0].file_link}>download</a>,
            slink: <a href={video.video_url}>share</a>,
            views: "vc",
            vsource: video.series[0].name,
            status: video.is_active ? (
              <div
                style={{
                  backgroundColor: "green",
                  height: "100%",
                  width: "100%",
                  display: "absolute",
                }}
              >
                <span style={{ color: "white" }}>Active</span>
              </div>
            ) : (
              <div style={{ backgroundColor: "red" }}>
                <span>Pending</span>
              </div>
            ),
          };
        })
      : [];
  }, [videos.videos, series, ui]);

  const DTable = ({ columns, data, saveOrder }) => {
    // console.log(columns, data);
    const manager = useRef(createDndContext(HTML5Backend));
    const [records, setRecords] = useState(data);
    const getRowId = useCallback((row) => {
      return row.id;
    }, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      visibleColumns,
      prepareRow,
      setColumnOrder,
    } = useTable({ data: records, columns, getRowId }, useColumnOrder);

    const moveRow = (dragIndex, hoverIndex) => {
      const dragRecord = records[dragIndex];
      console.log(dragIndex, hoverIndex);

      setRecords(
        update(records, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      );
    };
    // console.log(`rows=${JSON.stringify(rows)}`);
    console.log(rows);
    saveOrder(rows.map((r) => r.id));

    return (
      <DndProvider manager={manager.current.dragDropManager}>
        <Table striped className={classes.MyvideoTB} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th>Drag</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, index) =>
                prepareRow(row) || (
                  <DRow
                    index={index}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                  />
                )
            )}
          </tbody>
        </Table>
      </DndProvider>
    );
  };

  const saveOrder = (ordered) => {
    if (videos.videos) {
      const original = videos.videos.map((v) => v.id);
      if (JSON.stringify(ordered) != JSON.stringify(original)) {
        httpService
          .post(`gallery/series/${videos.videos[0].series[0].id}/`, {
            ordered: ordered,
          })
          .then((res) => {
            console.log(res);
            // setVideos(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleSave = (val) => {
    console.log(`Value=${val}`);
    httpService
      .post(`gallery/series/${series_id}/`, {
        rename: val,
      })
      .then((res) => {
        console.log(res);
        // setVideos(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.MyVideos}>
      <div className="mb-4">
        {videos.results && (
          <p className={classes.Head}>
            <span className="mr-3">
              Total Videos{" "}
              {videos.results &&
                videos.results.filter((v) => v.videos_count == 1).length}{" "}
            </span>{" "}
            /{" "}
            <span className="ml-3">
              {" "}
              Total Seires{" "}
              {videos.results &&
                videos.results.filter((v) => v.videos_count != 1).length}
            </span>
          </p>
        )}

        <div>
          <span className="mr-3">
            {/* <span>Series</span> */}
            <StyledEditText
              editOnViewClick={true}
              submitOnEnter={true}
              showButtonsOnHover={true}
              cancelOnEscape={true}
              // className={classes.HeadSeries}
              type="text"
              value={videos.name}
              onSave={handleSave}
            />
          </span>
        </div>
        <DTable columns={columns} data={data} saveOrder={saveOrder} />
      </div>
    </div>
  );
};

export default MySeries;
