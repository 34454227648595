import React from 'react';
import classes from "./MetaInfoHelper.module.css";
import { css } from "emotion";
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import history from "./history";
import AppContext from "../../../contexts/AppContext";

const field1 = css`
    display: contents;
    align-items: center;
    justify-content: space-around;
    padding:10px;
`;
const labelStyle = css`
  display: flex;
  align-items: center;
`;
const fontstyle = css`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #b5b3b7;
      &:checked {
    background-color: #ffffff;
    border: 2px solid #0ce40c;
    font-color:blue;
    &:after {
      display: block;
    }
  }
`;

class Product extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      selectedValue: '',
      prevflag: false,
      nextflag: false
      // setSelectedValue: []
    }
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleclick1 = this.handleclick1.bind(this);
  }
  componentDidMount()
  {
    $("#product").show();
    $("#timelineBadge1").css({ "--customback": "#27da11" });
    $("#timelineBadge1").css({ "--customback1": "#27da11" });
    $("#nexticon1").addClass(classes.nexticon);
    if(this.context.metaPickerState.Product){
      this.setState({
        selectedValue: this.context.metaPickerState.Product.selectedValue,
      },()=>{
        this.updatetimline();
        this.props.setNext(4);
      });
    }
  }
  handleChange1 = (event) => {
    this.setState({
      selectedValue: event.target.value
    }, ()=>{
      this.context.setMetaPickerState({
        Product: {
          selectedValue: this.state.selectedValue
        }
      });
      this.props.setNext(4);
    });
  };
  handleclick1() {
    if (this.state.selectedValue != '') {
      history.push("/user/Choosecategory");
    }
  }
  componentDidUpdate() {
    this.updatetimline();
  }
  updatetimline() {
    if (this.state.selectedValue != '') {
      $("#timelinePanel2").addClass(classes.timelinePane2css);
      $("#timelinePanel2").addClass(classes.timelinePane2cssafter);
      $("#timelineBadge1").css({ "--customback": "#27da11" })
      $("#timelineBadge2").css({ "--customback": "#a39d9d" })
      $("#timelineTitle2").css({ "color": "#ca0dd7" });
      $("#nexticon1").css({ "border-top-color": "#0be830" });
    } else {
      $("#nexticon1").css({ "border-top-color": "#b9bcbf" });
    }

  }

  render() {
    return (
      <div className={field1} id="product">
       
        <div>
          <label className={labelStyle}>
            <input
              type="radio"
              checked={this.state.selectedValue === 'Product'}
              onChange={this.handleChange1}
              value="Product"
              className={classes.input}
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Product' }}
            />
            <span className={fontstyle}>Product</span>
          </label>
        </div>
        <div>
          <label className={labelStyle}>
            <input
              type="radio"
              checked={this.state.selectedValue === 'Service'}
              onChange={this.handleChange1}
              value="Service"
              className={classes.input}
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Service' }}
            />
            <span className={fontstyle}>Service</span>
          </label>
        </div>
    
      </div>
    );
  }
}
export default withRouter(Product);