import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import classes from "./CustomModals.module.css";

const YesNoModal = (props) => {
  let children = props.children;
  const [show, setShow] = useState(false);
  const toggle = () => {
    console.log("closing");
    props.onCancel();
    setShow(!show);
  };

  const confirm = () => {
    props.onConfirm();
    setShow(!show);
  };

  useEffect(() => {
    setShow(props.isOpen);
  }, [children]);

  return (
    <Modal
      size={props.modalSize ? props.modalSize : "lg"}
      isOpen={show}
      backdrop={true}
      fade={true}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader>
        <h3>Confirm Album Delete</h3>
      </ModalHeader>
      <ModalBody className={[classes.ModalBody, classes.ModalMg].join(" ")}>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={confirm}>
          Confirm
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default YesNoModal;
