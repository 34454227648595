import styled from "styled-components";

const StyledPaginateContainer = styled.div`
  .pagination {
    color: #ff5800;
    // padding: 15px;
    padding-left: 5px;
  }
  .break-me {
    cursor: default;
    // padding: 15px;
  }
  .active {
    border-color: transparent;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ff5800;
    color: white;
  }
  .page {
    padding-left: 5px;
    padding-right: 5px;
    border: #dbd8d8 solid 1px;
    border-radius: 25px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .next-class {
    padding-left: 5px;
  }
  .previous-class{
    padding-right: 5px;
  }
`;

export default StyledPaginateContainer;
