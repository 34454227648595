import React from 'react';
import { Row, Col } from 'reactstrap';

import classes from './Collection.module.css';

const Collection = (props) => {

  return (
    <div className={classes.Collection}>
      <Row className={classes.Norow}>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <h1>Collections</h1>
        </Col>
      </Row>
    </div>
  )
}


export default Collection;
