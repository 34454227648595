import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import classes from "./CustomModals.module.css";
import UserProfileImg from "components/UI/UserProfileImg/UserProfileImg";

const EmbeddingCodeModal = (props) => {
  const [copied, setCopied] = useState(false);
  const [embeddedCopied, setEmbeddedCopied] = useState(false);

  const modal = props.isOpen;
  const toggle = () => !modal;

  const refreshEmbeddedState = () => {
    setEmbeddedCopied(true);
    setTimeout(function () {
      setEmbeddedCopied(false);
    }, 7000);
  };

  if (!props.video.resolutions) {
    return null;
  }

  let setFirstResolutions =
    props.video.resolutions && props.video.resolutions[0]
      ? props.video.resolutions[0]
      : {};
  let videoStyle = {},
    ration = "0",
    div_col_size = 10,
    div_col_offset = 1;
  if (setFirstResolutions) {
    let width = setFirstResolutions.width;
    let height = setFirstResolutions.height;

    if (height > width) {
      ration = (width / height) * 100;
      div_col_size = 6;
      div_col_offset = 3;
    } else {
    }
    ration = (height / width) * 100;
    videoStyle.paddingTop = ration + "%";
  }

  return (
    <Modal
      size={props.modalSize ? props.modalSize : "lg"}
      isOpen={modal}
      backdrop={false}
      fade={false}
      toggle={toggle}
      className={classes.EmbeddingCodeModal}
      contentClassName={classes.Test}
    >
      <ModalHeader className={classes.MHeader} toggle={props.closed}>
        <UserProfileImg />
        <p className={classes.ProfilePopname}> Lior Bazak </p>
        {/* <Button className={classes.BtnFreeDown}>Free Embedding Code</Button> */}
        <Row className={classes.ModalNav}>
          <p>Follow</p>
          <p>Donate</p>
          <p>
            {" "}
            <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon> Likes
          </p>
        </Row>
      </ModalHeader>
      <ModalBody className={classes.ModalBody}>
        <Col
          sm="12"
          md={{ size: div_col_size, offset: div_col_offset }}
          classs="text-center p-0"
        >
          <div className="embed-responsive" style={videoStyle}>
            <div className={classes.PlayersIcon}>
              <Link to={"/"}>
                <div className={classes.User}> </div>
              </Link>
              <Link to={"/"}>
                <div className={classes.Chat}> </div>
              </Link>
              <Link to={"/"}>
                <div className={classes.Share}> </div>
              </Link>
              <Link to={"/"}>
                <div className={classes.Heart}> </div>
              </Link>
              <Link to={"/"}>
                <div className={classes.Music}> </div>
              </Link>
            </div>
            <video
              className="embed-responsive-item"
              controls
              autoPlay
              id={"fep_" + props.video.resolutions[0].id}
              src={props.video.resolutions[0].file_link}
              poster={props.video.image}
            />
          </div>
        </Col>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <div>
            {" "}
            <CopyToClipboard
              text={props.video.resolutions[0].embeded_url}
              onCopy={() => setCopied(true)}
            >
              <Button className={classes.BtnFreeDown}>
                {copied ? "Link Copied " : "Free Embedded Code"}
              </Button>
            </CopyToClipboard>
            {/*embeddedCopied && (
              <Tooltip
                placement={"top"}
                isOpen={embeddedCopied}
                autohide={true}
                target={"ScheduleUpdateTooltip"}
              >
                Embedded Code Copied!
              </Tooltip>
              <CopyToClipboard
              /*text={props.video.resolutions[0].embeded_url}
              onCopy={refreshEmbeddedState}
              id="ScheduleUpdateTooltip"
            >
              <Button className={classes.BtnFreeDown}>
                {"FREE EMBBBEDED CODE"}
              </Button>*
            </CopyToClipboard>
            
            )*/}
          </div>
          <div className={classes.Embedd}>
            {props.video.resolutions[0].embeded_url}
          </div>
        </Col>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <CopyToClipboard
            text={props.video.resolutions[0].embeded_url}
            onCopy={() => setCopied(true)}
          >
            <Button className={classes.BttnLink}>
              {copied ? "Link Copied" : "Copy Link"}
            </Button>
          </CopyToClipboard>
        </Col>

        <Col sm="12" md={{ size: 12 }}>
          <p className={classes.SponsorAd}>Sponsor ad space</p>
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default EmbeddingCodeModal;
