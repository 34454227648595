import React from "react";
import { Col, Row } from "reactstrap";
import classes from "./Terms.module.css";
import { IMPRINT_AND_TERMS } from "../../../containers/AppConstant";

const privacypolicy = (props) => {
  return (
    <div className={classes.ContributorGuideline}>
      <Col sm="12" md={{ size: 12 }}>
        <div>
          <div className={classes.ContributorTxt}>
            Privacy Policy Introduction. Welcome, this is the Privacy Police and
            Data Notice applicable for our website www.videobzz.com,
            encompassing its sub-domains and its mobile optimized versions. We
            also recommend you to read our Terms, along with any and all of our
            rules, guidelines and ancillary policies (if any), all included
            herein by reference. This Policy applies to our Site, its web pages
            and any website that references this Policy, along with any of Green
            Speed North’s operated websites and platforms, as well as any data
            we may collect across partnered and unaffiliated websites. This
            Policy also applies to the personally identifiable information
            collected through the mobile version of our Site, independently of
            the mobile operative system, mobile device or browser you use to
            access it. Definitions. The following defined terms will be used in
            this Policy, whether they are used in the singular or plural form,
            or in the masculine or feminine form. Privacy Policy shall be
            referred to as the “Policy” Green Speed North LTD shall be referred
            to as “Green Speed North” www.videobzz.com shall be referred to as
            the “Site” A visitor or end user of our Site is referred to as
            “you”, “your(s)” or “yourself” Personally Identifiable Information
            shall be referred to as “PI” Terms and Conditions of Service shall
            be referred to as the “Terms” The terms “collect”, “process”,
            “treat”, “use”, “share”, “disclose”, “divulge” and analogous words
            shall be refer to your PI and other data collected from our visitors
            and end users. In addition, the terms “our”, “us” or “we” shall
            refer to Green Speed North and its affiliates, agents, shareholders,
            licensors, representatives, officers and related parties. User
            Agreement & Registration. As a user of our Site, in accordance with
            Article 6 (1) (a) GDPR, you will be asked to accept to the terms of
            this Policy, by clicking on the “I Agree” checkbox on the
            registration form or box or other similar means. Through that
            action, you thereby expressly acknowledge and agree to the terms of
            this Policy, which is and constitutes a legal, binding agreement
            between you and Green Speed North. Overall, this Policy is freely
            available for your review prior to registration, and if you do not
            agree to its terms, your remedy shall consist of not registering an
            account, and exiting the web tab though which you visited our Site.
            The Green Speed North team does every possible effort to keep your
            trust; and thus we adhere to these general privacy principles, in
            order to defend your privacy: • Green Speed North does not sell your
            PI. • Green Speed North does not divulge your contact information to
            third parties or other users without your consent. • Any PI that you
            give to Green Speed North will be protected by industry standard
            technology and codes of ethics. For purposes of this Policy, the
            Site shall be the initial point of contact between you and Green
            Speed North, and will serve as the point of collection of any
            personal data you may provide us. As our user, you will normally be
            able to manage and select the quantity and type of PI you may reveal
            to us when using our Site, usually in your account settings. We
            constantly try to develop our user experience, and continuously work
            in order to: • notify you concerning the ways in which your personal
            information may be utilized and shared (overseas included); •
            preserve the security and protection of your personal details;
            and/or • enforce the accessibility of your personal information in
            order for you to exercise your right of correction of said
            information. Accordingly, by registering with us or otherwise using
            our products and services, you consent to the collection, transfer,
            processing, storage, and disclosure of your PI as described in this
            Policy. Services Offered. Green Speed North provides, operates and
            manages a marketing video marketplace that allows its users to
            download and embed our multimedia content pieces in various types of
            third party platforms, for their commercial purposes. Our
            pro-pre-produced videos are sourced by our worldwide talented
            contributors’ community. Our cloud-based library provides convenient
            access to tens of thousands of marketing videos for several
            categories of retail/products & services, which can be available as
            a single video or as a video series. We may offer additional
            functionalities through the Site, as indicated from time to time
            (collectively referred to as, the “Services”) Collection of Personal
            Information. Personal Information. Includes information that can be
            used to personally identify an individual person, such as: (e.g.
            phone number, name (first and last), email address, phone number).
            Accordingly, you hereby represent and warrant to Green Speed North
            that you have the necessary rights and authorizations required for
            the disclosure of any and all PI. If you open an account with us
            representing a legal entity, you also represent and warrant that you
            have the necessary power and authorization. Non-Personal
            Information. Includes information that cannot be used to personally
            identify an individual person, such as anonymous usage data, general
            demographic information that we may collect, such as number of
            clicks, platform types, preferences you submit and preferences that
            are generated based on the data you submit. Information Collected
            via Third Party Tools. Such as aggregate user statistics, analytics,
            demographic information, and web site usage information. As our
            user, you will generally be able to control and select the amount
            and type of PI you may disclose to us. Information Collected via
            Social Media Integration. We may allow our users to use single sign
            on authentication services such as those provided by Facebook,
            Google and others (if available). Henceforth, the Site might provide
            an option of using your social media credentials and, thus, by such
            action, we will collect your PI as given by such third party
            platforms, but never more than the PI these platforms disclose.
            Collected PI from such third parties will include, but will not be
            limited to, friend lists, email, image gallery, likes and other
            information. If you close your social media account, or if you
            deauthorize our access, your access to our Site may be suspended.
            For more information, please read the terms and policies of such
            third party platforms. Technical Information Collected via the Site.
            As you navigate through a website, certain information can be
            passively collected (that is, gathered without you actively
            providing the information) using various technologies and means,
            such as Internet Protocol addresses, cookies, Internet tags, and
            navigational data collection. • Internet Protocol (IP) addresses. An
            IP Address is a number assigned to your computer by your Internet
            service provider so you can access the Internet and is generally
            considered to be non-personally identifiable information, because in
            most cases an IP address is dynamic (changing each time you connect
            to the Internet), rather than static (unique to a particular user's
            computer). We use your IP address to diagnose problems with our
            server, report aggregate information, determine the fastest route
            for your computer to use in connecting to our Website, and
            administer and improve services to our consumers. • Cookies. A
            "cookie" is a bit of information that a website sends to your web
            browser that helps the site remember information about you and your
            preferences. "Session cookies" are temporary bits of information
            that are erased once you exit your web browser window or otherwise
            turn your computer off. Session cookies are used to improve
            navigation on websites and to collect aggregate statistical
            information. This Site uses session cookies. • Persistent Cookies.
            Are more permanent bits of information that are placed on the hard
            drive of your computer and stay there unless you delete the cookie.
            Persistent cookies store information on your computer for a number
            of purposes, such as retrieving certain information you have
            previously provided (e.g., username), helping to determine what
            areas of the website visitors find most valuable, and customizing
            the website based on your preferences. This Site uses persistent
            cookies. • Internet tags. (also known as single-pixel GIFs, clear
            GIFs, invisible GIFs, and 1-by-1 GIFs) are smaller than cookies and
            tell the Site´s server information such as the IP address and
            browser type related to the visitor's computer. This Site uses
            Internet tags. • Navigational data. ("log files," "server logs," and
            "clickstream" data) and "Internet Tags" are used for system
            management, to improve the content of the site, market research
            purposes, and to communicate information to visitors. Purpose of PI
            Collection. We will store your PI for the purposes of managing your
            collaboration with other users and clients, improve your user
            experience, send newsletters and contact you about inquiries for our
            services. Overall, we use the collected PI to provide and improve
            our services. Accordingly, we will generally collect, use and
            disclose your PI to: • Provide, operate, maintain, improve, and
            promote our platform and our products and services. • Verify your
            e-mail address and other account notices. • Implement anti-fraud
            measures. • Develop, research, process, safeguard and improve our
            services. • Conduct research, analysis, and surveys. • Offer
            promotions, newsletters, send service related announcements and
            contact you about inquiries for our products and services. •
            Investigate and prevent fraudulent transactions, unauthorized access
            to our services, and other suspicious activities. • Monitor and
            analyze trends, usage, and activities in connection with our
            products and services and for marketing or advertising purposes.
            Green Speed North will keep any collected PI only as long as it is
            necessary, with regard to the purpose of its processing. This means
            that PI collected and processed for marketing and commercial
            purposes will be stored for as long as you have an active account,
            and/or for at least six (6) months after your account has been
            deleted or deactivated. Upon the case of PI which is stored by our
            sub-processors, the data will be stored on their secure servers as
            indicated on their respective privacy policies from time to time. If
            you do not wish to disclose any or part of your PI to us, you may
            still be able to use some of the functionalities of our Site, though
            we will not be able to guarantee that you will be able to enjoy them
            at their fullest if you elect not to disclose it to us. Disclosure
            of Personal Information. Disclosure to Affiliates and Partners. In
            accordance with Article 6 (1) (b) GDPR, as it is necessary for the
            purposes of pursuing our legitimate interests or the legitimate
            interests of third parties, we may disclose your PI to our
            affiliates and business partners worldwide that agree to treat it in
            accordance with this Policy. In addition, we may disclose your PI
            information to contractors we use to support our business (e.g.
            cloud services, software as a service, infrastructure as a service,
            technical support), as long as there is no reason to assume it may
            be any overriding interest in the PI data not being disclosed which
            is worthy of protection. Disclosure to Business Successors. In
            accordance with Article 6 (1) (f) GDPR, if our business is sold or
            merges in whole or in part with another business that would become
            responsible for providing the Site to you, we retain the right to
            transfer your PI to the new business. The new business would retain
            the right to use your PI according to the terms of this Policy as
            well as to any changes to this privacy notice as instituted by the
            new business. We will also retain the right to transfer your PI if
            our company files for bankruptcy and some or all of our assets are
            sold to another individual or business. Disclosure to Third Party PI
            Processors. In accordance with Article 6 (1) (b) GDPR, in order to
            provide our services, we use third-party platforms and tools, such
            as those provided by PayPal, Stripe, Google and Amazon, among other
            providers. Please be aware that such platforms are governed by their
            own terms, policies and personal data collection practices and
            policies. Accordingly, by acknowledging your consent to this Policy,
            you are also acknowledging the applicable terms and conditions of
            such third party platforms, which provide functionalities that
            include aggregated statistics. Upon the case of any discrepancy or
            conflict among this Policy and the terms of them, the terms of the
            latter entities will govern. We will keep a list of any
            sub-processors that will be involved in the processing of your PI
            due to the provision of our service and will inform you of any
            intended material changes concerning the addition or replacement of
            sub-processors before such changes are effective, thereby giving you
            the opportunity to object to such changes. Lawful Disclosure. In
            accordance with Article 6 (1) (c) GDPR, if we, or our affiliates,
            clients, contractors, licensors, officers, agents and/or
            representatives, reserve the right to allow access to any of your PI
            when we think it is reasonably important or when you violate the
            terms mentioned in this policy. Hereafter, we will have the right to
            disclose any or all gathered PI and/or data, when the following
            circumstances arise: (i) if necessary under an applicable law; (ii)
            in reply to a legal demand or subpoena from an agency of the law;
            (iii) to protect ourselves and our affiliates from any legal third
            party claims and procedures that may be brought to us (inclusive of
            takedown notices); and/or (iv) to prevent or cause cessation of any
            undertakings that may be construed by us as having the ability to be
            or cause a predicament or hazard to us. Commercial Message
            Communications Authorization. Occasionally, we will ask you for
            express permission and approval to receive electronic messages in
            electronic format which are then sent to an electronic address and
            that contains a message asking recipients to participate in
            commercial activities such as newsletters, purchase of products,
            services and invitations to participate in surveys. We will send you
            service-related announcements on occasions when it is necessary to
            do so. For instance, if our service is temporarily suspended for
            maintenance, or a new enhancement is released, which will affect the
            way you use our services, we might send you an email. Generally, you
            may not opt-out of these communications, which are neither
            commercial nor promotional in nature. In accordance with Article 21
            GDPR you have the right to object to the processing of your personal
            data if grounds for this objection is to direct marketing.
            Henceforth, if you no longer wish to receive email update and other
            commercial communications and messages, you may opt-out of receiving
            them by following the instructions included in each update or
            communication. Cookie Policy. Periodically, we may place what are
            known as ‘cookies’ inside your device for the purpose of tracking
            and collecting data in regard to your utilization of our Site and
            our Services. These files are small texts that our site transfers to
            your device, and which allow us to recognize you and obtain certain
            data. In this manner, the information generated by the cookies about
            your use of this Site will include, but will not be limited to:
            type/version of browser, operating system used, referrer URL (last
            website visited), host name of the accessing computer (IP address)
            and/or time of server request. By agreeing to accept our use of
            cookies, you are giving us, and the third parties we partner with,
            permission to place, store, and access some or all the cookies
            described below on your computer: • Necessary Cookies. These cookies
            are necessary for proper functioning of the Site, such as displaying
            content, logging in, validating your session, responding to your
            request for services, and other functions. Most web browsers can be
            set to disable the use of cookies. However, if you disable these
            cookies, you may not be able to access features on our website
            correctly or at all. • Functional Cookies. These cookies enable the
            Site to remember a user’s choices – such as their language, user
            name, and other personal choices – while using the Site. They can
            also be used to deliver services, such as letting a user make a blog
            post, listen to audio, or watch videos on the Site. • Session
            Cookies. These cookies allow websites to link the actions of a user
            during a browser session. They may be used for a variety of
            purposes, such as remembering what a user has put in their shopping
            cart as they browse a website. Session cookies also permit users to
            be recognized as they navigate a website so that any item or page
            changes they make are remembered from page to page. Session cookies
            expire after a browser session; thus, they are not stored long term.
            • Persistent Cookies. These cookies are stored on a user’s device in
            between browser sessions, which allows the user’s preferences or
            actions across a website (or, in some cases, across different sites)
            to be remembered. Persistent cookies may be used for a variety of
            purposes, including remembering users’ choices and preferences when
            using a website or to target advertising to them. We may also
            collect and use the data contained in log files, which may include
            your IP address, your ISP, the browser you used to visit our
            platform, the time you visited our platform and which sections you
            visited. When you access our platform via a mobile device, we may
            use your mobile device ID (i.e. the unique identifier assigned to a
            mobile device by the manufacturer) and/or Advertising ID (for Apple
            iOS 6 and later) instead of cookies in order to recognize you and
            track displayed web pages, along with their performance. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. If you wish so, you can easily disable cookies
            on your web browsing software by following the step-by-step guides
            located at www.allaboutcookies.org and at www.youronlinechoices.com.
            Third Party Integrations. We use the content delivery network of
            Vimeo Inc., which allows multimedia syndication of third party
            created and sourced video pieces. This tool allows certain
            information to be tracked on websites that use Vimeo. For more
            information, please refer to the privacy policy, personal data
            procedures and terms of use of Vimeo, which will govern any
            personally identifiable information collection, process and
            treatment. Advertising, Opt-out Choice. From time to time, we may
            use Google Analytics, Ad Words, Double Click and/or Ad Sense
            tracking codes, and other third-party software tools (such as
            remarketing codes) in order to collect information and marketing
            analytics about the manner in which you browse our platform and
            services. The served ads will be targeted based on your previous
            browsing history, and may include retargeting codes. For example,
            third-party vendors may show you Green Speed North’s ads on certain
            websites across the Internet, even after you leave our platform. The
            collected information is anonymized, meaning it cannot be tracked
            back to individuals. Using such tools, we learn how to optimize, and
            serve ads based on a user’s past visits, providing you with a better
            user experience. Our third-party vendors may use cookies and beacons
            to track your Internet browsing in order to serve you ads based on
            what they or us believe to be of interest according to your browsing
            activities and consumer preferences. Henceforth, by using our
            services, along with our platform and websites, you hereby consent
            to such disclosure, treatment and storage of PI. In order to enforce
            and uphold your right to privacy, you will always have the option to
            elect not to receive this type of advertising from us or third
            parties. We do not currently offer “do-not-track” or similar
            mechanisms. Unlike cookies, device IDs cannot be deleted, but you
            can select to reset your Advertising IDs in the “Settings” section
            of your mobile device, among other. You can learn more about how to
            opt-out by browsing Google’s opting-out and privacy pages located at
            www.google.com, or the Network Advertising Initiative website
            located at www.networkadvertising.org. Third Party Tools and PI
            Processors. In order to provide our services, we use third-party
            platforms and tools, such as those provided by PayPal, Stripe,
            Google and Amazon, among other providers. Please be aware that such
            platforms are governed by their own terms, policies and personal
            data collection practices and policies. Accordingly, by
            acknowledging your consent to this Policy, you are also
            acknowledging the applicable terms and conditions of such third
            party platforms, which provide functionalities that include
            aggregated statistics. Upon the case of any discrepancy or conflict
            among this Policy and the terms of them, the terms of the latter
            entities will govern. Our Site may present our commercial partner’s
            hyperlinks as well as those of other third parties. Once you have
            used these links to leave our Site, you should note that we do not
            have any control over that other website. Therefore, we cannot be
            responsible for the protection and privacy of any information that
            you provide whilst visiting such sites and such sites are not
            governed by this privacy statement. You should exercise caution and
            look at the privacy statement applicable to the website in question.
            We will keep a list of any sub-processors that will be involved in
            the processing of your PI due to the provision of our service and
            will inform you of any intended material changes concerning the
            addition or replacement of sub-processors before such changes are
            effective, thereby giving you the opportunity to object to such
            changes. Security of Collected Information. The security of your PI
            is important to us, but please remember that no method of
            transmission over the Internet, or method of electronic storage is
            100% secure. While we strive to use commercially acceptable means to
            protect your information, we cannot guarantee its absolute security.
            You can be assured that personal information collected through the
            Site is secure and is maintained in a manner consistent with current
            industry standards. The importance of security for all personal
            information associated with our subscribers is of utmost concern to
            us. Your personal information is protected in several ways. We
            protect inputted information by undertaking the reasonable technical
            and administrative security measures (e.g. firewalls, data
            encryption, physical & administrative access controls to the data
            and servers) that limit the risk of loss, abuse, unauthorized
            access, disclosure, and alteration. Your personal information
            resides on a secure server that only selected employees and
            contractors have access to via password. In order to most
            efficiently serve you, credit card transactions and order
            fulfillment are handled by established third-party banking,
            processing agents and distribution institutions. They receive the
            information needed to verify and authorize your credit card or other
            payment information and to process and ship your order. To protect
            your personal information, we take reasonable precautions and follow
            industry best practices to make sure it is not inappropriately lost,
            misused, accessed, disclosed, altered or destroyed. If you provide
            us with your credit card information, the information is encrypted
            using secure socket layer technology (SSL) and stored with
            encryption systems. Although no method of transmission over the
            Internet or electronic storage is 100% secure, we follow all
            requirements and implement additional generally accepted industry
            standards. Service eligibility. You must at least be eighteen (18)
            years of age, or older—to purchase our products and/or to use our
            services. Green Speed North does not knowingly collect any kind of
            information from persons under the age of thirteen (13). If we learn
            or have reason to suspect that any user or that any client, project
            or customer data appertains persons under the age of thirteen (13),
            we will freeze and/or delete any PI under that user’s account,
            without prior notice and without responsibility. GDPR Notice and
            your Rights as Data Subject. For the purposes of the GDPR, in the
            European Union, Green Speed North is a “data controller” of the PI
            you provide to us for the primary purposes of providing you with our
            services. For our European Union customers and users, by clicking
            the "I Accept" button or otherwise accepting the terms and
            conditions of our services through a clickable action or similar
            action, you hereby acknowledge, agree and unequivocally consent to
            the collection, process, management, treatment, transfer and
            authorized of your PI by Green Speed North, its affiliates and
            authorized third parties. The section below covers the certain
            situations that you, as data subject, and we as a data controller,
            are most likely to see, but you should also carefully review the
            full list of data subject rights here:
            https://gdpr-info.eu/chapter-3/. You retain the right to access,
            amend, correct or delete your PI where it is inaccurate at any time.
            To do so, please contact hello@greenspeednorth.com. • Right to be
            Forgotten: You can request us to be “forgotten”; that is, to have
            your entire PI removed from our service. If we are asked to do this,
            in accordance with Article 17 GDPR we will remove any PI you we
            collected from you as requester. We will also need to contact any
            third parties that process your PI on our behalf, such as our cloud
            service providers. To ensure that any personal data in Green Speed
            North’s possession can be removed in a timely manner, you can relay
            any request to be “forgotten” to us by submitting a request- • Right
            to Data Portability: Under the GDPR, in accordance with Article 20
            GDPR our users located in the EU may request Green Speed North to
            send them any PI in our possession. In this case, we will provide
            you with any PI that you have in a commonly used, machine-readable
            format. • Right to Data Access: As a data subject, in accordance
            with Article 15 GDPR you can ask Green Speed North to confirm how
            and where your PI is being stored and processed. You also have the
            right to know how such that data is shared with third parties by us.
            • Right to Data Rectification: As data subject, in accordance with
            Article 16 GDPR you have the right to obtain from Green Speed North,
            without undue delay, the rectification of inaccurate PI concerning
            you. • Right to be Informed: You have the right to be informed about
            the PI we collect from you, and how we process it. • Right to
            Withdraw Consent. In accordance with Article 7 (3) GDPR, you have
            the right to withdraw your consent given to us at any time. • Right
            to Object: In accordance with Article 18 GDPR you have the right to
            object to us processing your PI for the following reasons: •
            Processing was not based on legitimate interests or the performance
            of a task in the public interest/exercise of official authority
            (including profiling); • Direct marketing (including profiling); •
            Processing for purposes of scientific/historical research and
            statistics; and • Rights in relation to automated decision-making
            and profiling. • Automated Individual Decision-Making and Profiling:
            You have the right not to be subject to a decision based solely on
            automated processing, including profiling, which produces legal
            effects concerning you or similarly significantly affects you. •
            Right to Complain: You have the right to file a complaint with
            supervisory authorities if your information has not been processed
            in compliance with the GDPR. In accordance with Article 77 GDPR, if
            the supervisory authorities fail to address your complaint properly,
            you may have the right to a judicial remedy. Your privacy request
            must include, at the least, the following information: (i) your
            complete name, address and/or e-mail address in order for us to
            notify you the response to your request; (ii) attached documents
            establishing your identity; and (iii) a clear and concise
            description of the PI with regard to which you seek to enforce any
            of your privacy rights. If you request rectification, please
            indicate amendments to be made and attach documentation to back up
            your request. Upon receipt of your privacy request, and after due
            review, we may then edit, deactivate and/or delete your PI from our
            services within thirty (30) days. In case of secure databases under
            our control where deletion is impossible, we will make such
            information permanently inaccessible. Notice to California
            Residents. Pursuant to the California Consumer Privacy Act of 2018
            (the “CCPA”), Green Speed North and its affiliates hereto provide
            the following notice regarding the categories of PI that we have
            collected or disclosed within the preceding twelve (12) months about
            California residents who are not employees, independent contractors,
            owners, directors, officers, or job applicants of Green Speed North,
            or emergency contacts or benefits beneficiaries of the foregoing.
            Governing Language. From time to time, this Policy may be translated
            into other languages for your convenience. The English language
            version of each of these documents shall be the version that
            prevails and governs your use of Site and our products and services.
            Upon the case of any conflict between the English language version
            and any translated version, the English language version will
            prevail. Amendments to this Policy. Periodically, and at the final
            discretion of ourselves, we may update, change, suspend and/modify
            or our Site, our services, this Policy and/or our Terms, in whole or
            in part. We hereby reserve such right in order to operate our
            business and protect ourselves. Your use after any changes indicate
            your acceptance thereof and we will post a notice regarding such
            changes on our Site, and may also write an email to you or otherwise
            notify you. Contact. If you have any questions or comments about us,
            our Site, our Terms and/or this Policy, please contact us at: Green
            Speed North LTD hello@greenspeednorth.com Address: Amat Hamaim 9A
            3033309 Atlit - Israel CRN: 51-481-279 Date of last effective update
            is [---------], 2020.
          </div>
        </div>
      </Col>
    </div>
  );
};
export default privacypolicy;
