import React from 'react';
import { Table, Input, Badge  } from 'reactstrap';

import classes from './Earnings.module.css';

const earnings = (props) => {

    return (
        <div className={classes.Earnings}>
            <div className={classes.Earningbox}>
            
                <div>
                  <p>Net Income</p>
                  <p>$138.40</p>
                </div>
                <div>
                <p>Withdrawn</p>
                  <p>$0</p>
                </div>
                <div>
                <p>Used for Purchases</p>
                  <p>$138.40</p>
                </div>
                <div>
                <p>Pending Clearance</p>
                  <p>$138.40</p>
                </div>
                <div>
                <p>Availabe for Withdrawal</p>
                  <p>$138.40</p>
                </div>
            </div>

            <div className={classes.ShowFliter}>
              <div className={classes.ShowTxt}>Show</div>
              <div> <Input type="Text" name="text" id="" placeholder="Everything" /></div>
              <div> <Input type="Text" name="text" id="" placeholder="2020" /></div>
              <div> <Input type="Text" name="text" id="" placeholder="All Months" /></div>
            </div>

            <div className={classes.OuterTable}> 
                 <Table>
      <thead>
        <tr>
        <th>DATE </th>
          <th>FOR</th>
          <th className="text-right">AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
         <td>Mark</td>
          <td>  <Badge color="info">Clearing</Badge> Mark</td>
          <td className="text-right">$130.40</td>
         
        </tr>
        <tr>
         <td>Mark</td>
          <td>Jacob</td>
          <td className="text-right">$130.40</td>
         
        </tr>
       
      </tbody>
    </Table></div>
        </div>
    )
}


export default earnings;
