import {
    SET_APP_CONFIG,
    LOAD_CATEGORIES,
    LOAD_GALLERIES,
    LOAD_SERIES,
    LOAD_SERIES_VIDEOS,
    LOAD_SEARCH_GALLERIES,
    SET_SEARCH_QUERY,
    UPDATE_PROFILE_DETAIL
} from 'store/actions/actionTypes';

import produce from 'immer';

const initialState = {
    seriesVideos: {}
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_CONFIG:
            return state
        case UPDATE_PROFILE_DETAIL:
            return produce(state, draft => {
                draft.profileDetails = action.payload.profileDetails;
            })
        case LOAD_CATEGORIES:
            return produce(state, draft => {
                draft.categories = action.payload.categories;
            })
        case LOAD_GALLERIES:
            return produce(state, draft => {
                if (draft.galleries) {
                    draft.galleries.next = action.payload.galleries.next;
                    draft.galleries.previous = action.payload.galleries.previous;
                    draft.galleries.results = draft.galleries.results.concat(action.payload.galleries.results);
                } else {
                    draft.galleries = action.payload.galleries;
                }
            })
        case LOAD_SEARCH_GALLERIES:
            return produce(state, draft => {
                draft.galleries = action.payload.galleries;
            })
        case SET_SEARCH_QUERY:
            return produce(state, draft => {
                delete draft.galleries;
                draft.searchQuery = action.payload.searchQuery;
            })
        case LOAD_SERIES:
            return produce(state, draft => {
                draft.series = action.payload.series;
                draft.seriesList = action.payload.seriesList;
                draft.loadSeriesIDVideos = draft.loadSeriesIDVideos ? draft.loadSeriesIDVideos : action.payload.loadSeriesIDVideos;
            })
        case LOAD_SERIES_VIDEOS:
            return produce(state, draft => {
                draft.loadSeriesIDVideos = action.payload.seriesVideos.id;
                draft.seriesVideos[action.payload.seriesVideos.id] = action.payload.seriesVideos;
            })
        default:
            return state

    }
}

export default rootReducer
