import classes from "./BottomRight.module.css";

import React from "react";

export const BottomRight = (props) => {
  return (
    props.children && (
      <div className={classes.bottomRight}>{props.children}</div>
    )
  );
};
