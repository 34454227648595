

import './app.css';
import * as React from 'react';
import classes from "./MetaInfoHelper.module.css";
import $ from 'jquery';
import history from "./history";
import AppContext from "../../../contexts/AppContext";
//
export default class Addsubcategory extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      keywords: ''
    };
    this.hadlehange = this.hadlehange.bind(this);
  }
  componentDidMount() {
    $("#timelineBadge1").css({ "--customback": "#27da11" })
    $("#timelineBadge3").css({ "--customback": "#27da11" })
    $("#nexticon3").addClass(classes.nexticon);
    $("#addsubcategory").show();
    if (this.context.metaPickerState.Keywords){
      this.setState({
        keywords: this.context.metaPickerState.Keywords.keywords
      }, ()=>{
        console.log(`kw=${this.state.keywords}`);
        this.props.setNext(6);
      });
      
    }
  }
  componentDidUpdate() {
    this.updatetimline();
  }
 
  updatetimline() {

    $("#exampleFormControlTextarea1").attr("disabled");

    if (this.state.keywords != '' && this.state.keywords.length <= 6) {
      $("#timelinePanel4").addClass(classes.timelinePane2css);
      $("#timelinePanel4").addClass(classes.timelinePane2cssafter);
      $("#timelineBadge1").css({ "--customback": "#27da11" });
      $("#timelineBadge4").css({ "--customback": "#a39d9d" })
      $("#timelineTitle4").css({ "color": "#f99207" });
      $("#exampleFormControlTextarea1").css({ color: "#27da11" });
      $("#nexticon3").css({ "border-top-color": "#0be830" });

    } else {
      $("#nexticon3").css({ "border-top-color": "#b9bcbf" });

    }
  }
  hadlehange(e) {
    this.setState({ keywords: e.target.value }, ()=>{
      this.props.setNext(6);
      this.context.setMetaPickerState({
        Keywords: {
          keywords: this.state.keywords
        }
      })
    })
  }
  render() {
    return (
      <div className="totalfiled" id="addsubcategory">
       
        <div >
          <div className="keyword"><span className="addaspan">Add Keywords</span></div>
          <div><span className="radiolabel">Enter up to 6 keyword that describe best the video Sub Category & style</span></div>
          <div className="form-group">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              value={this.state.keywords}
              onChange={this.hadlehange}
            />
          </div>
        </div>

      
      </div>
    )
  }
}