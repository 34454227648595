import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import classes from "containers/App.module.css";
import User from "components/User/User";
import SetVimeoAccount from "components/User/SetVimeoAccount/SetVimeoAccount";
import PersonalDetails from "components/User/PersonalDetails/PersonalDetails";
import PaymentDetails from "components/User/PaymentDetails/PaymentDetails";
import MyVideos from "components/User/MyVideos/MyVideos";
import Earnings from "components/User/Earnings/Earnings";
import GeneralStatus from "components/User/GeneralStatus/GeneralStatus";
import Preferences from "components/User/Preferences/Preferences";

class UserBuilder extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <Container className={classes.Mycontainer}>
        <User />
        <Switch>
          {/* <Route
            path={this.props.match.path + '/contact'}
            render={(props) => (<BlogBuilder {...props} />)}
          /> */}
          <Route path={"/user/personal-details"} component={PersonalDetails} />
          <Route path={"/user/payment-details"} component={PaymentDetails} />
          <Route path={"/user/my-videos"} component={MyVideos} />
          <Route path={"/user/earnings"} component={Earnings} />
          <Route path={"/user/general-status"} component={GeneralStatus} />
          <Route path={"/user/preferences"} component={Preferences} />
          <Route exact path={"/user"} component={SetVimeoAccount} />
        </Switch>
      </Container>
    );
  }
}

export default UserBuilder;
