import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';

import classes from './Page.module.css';

const page = (props) => {

  return (
    <div className={classes.Page}>
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <h1>{props.title}</h1>
          <h3>{props.content}</h3>
        </Col>
      </Row>
    </div>
  )
}


export default page;
