import styled from "styled-components";
import EdiText from "react-editext";

const StyledEditText = styled(EdiText)`
  button {
    border-radius: 5px;
  }
  button[editext="edit-button"] {
    color: #000;
    width: 50px;
    display: none;
  }
  button[editext="save-button"] {
    width: 50px;
    &:hover {
      background: greenyellow;
    }
    display: none;
  }
  button[editext="cancel-button"] {
    &:hover {
      background: crimson;
      color: #fff;
    }
    display: none;
  }
  input {
    background: #1d2225;
    color: #f4c361;
    // font-weight: bold;
    border-radius: 50px;
    height: 30px;
  }
  ,
  textarea {
    background: #1d2225;
    color: #f4c361;
    font-weight: bold;
    border-radius: 5px;
  }
  div[editext="view-container"] {
    border-radius: 50px;
    display: inline-block;
    background: #b0b0b0;
    padding: 3px 15px 3px 15px;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.2rem;
    padding-top: 7px;
  }
  ,
  div[editext="edit-container"] {
    background: #6293c3;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    border-radius: 50px;
    display: inline-block;
    background: #b0b0b0;
    padding: 3px 15px 3px 15px;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.2rem;
    padding-top: 7px;
  }
`;

export default StyledEditText;
