import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import classes from 'components/UI/Error/Error.module.css';

const input = (props) => {
    let inputElement = null;
    const wrapperClass = props.wrapperClass ? [props.wrapperClass] : [];
    const inputClasses = props.inputClass ? [props.inputClass] : [];
    const labelClasses = props.labelClass ? [props.labelClass] : [];
    const hasError = {};

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
        hasError.class = classes.InvalidText;
        hasError.message = props.invalidMessage;
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <Input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ('textarea'):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ('select'):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            break;
        case ('checkbox'):
            inputElement = (
                <FormGroup check className={wrapperClass.join(' ')}>
                    {props.elementConfig.options.map(option => (
                        <Label check className={labelClasses.join(' ')}>
                            <Input
                                key={option.value}
                                className={inputClasses.join(' ')}
                                {...props.elementConfig}
                                value={option.value}
                                onChange={props.changed} />{' '}{option.displayValue}
                        </Label>
                    ))}
                </FormGroup>
            );
            break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }

    return (
        <FormGroup>
            {props.label ?
                <label className={labelClasses.join(' ')}>{props.label}</label>
                : ""
            }
            {inputElement}
            {hasError.message ? <span className={hasError.class}>{hasError.message}</span> : ''}
        </FormGroup>
    );

};

export default input;