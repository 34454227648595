import React, {  } from "react";
import classes from "./MetaInfoHelper.module.css";
import { css } from "emotion";
import Business from "./Business";
import Product from "./Product";
import Choosecategory from "./Choosecategory";
import Addsubcategory from "./Addsubcategory";
import Choosevideo from "./Choosevideo";
import Choosevideolanguage from "./Choosevideolanguage";
import Choosevideoenvironment from "./Choosevideoenvironment";
import Event from "./Event";
import PrivateCategory from "./PrivateCategory";
import Videosymbols from "./Videosymbols";
import Greate from "./Greate";
import arm from "../../../assets/arm.png";
import armre from "../../../assets/armre.png";
import AppContext from "../../../contexts/AppContext";
import $ from 'jquery';
import { faGrinTongueSquint } from "@fortawesome/free-solid-svg-icons";


const field = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:25px;
    padding:10px;
`;

export default class MetaInfoHelper extends React.Component {
  static contextType = AppContext;
  constructor() {
    super()
    this.state = {
      count: 0,
      prevflag: false,
      nextflag: false,
      armreimage: armre,
      next: 0,
      completed: [],
    }
    this.handleclick = this.handleclick.bind(this);
    this.handleclick1 = this.handleclick1.bind(this);
    this.updatedfunction = this.updatedfunction.bind(this);
    this.setNext=this.setNext.bind(this);
    // this.context.setMetaPickerState({
    //   count: 0,
    //   prevflag: false,
    //   nextflag: false,
    //   armreimage: armre,
    //   next: 0,
    //   completed: [],
    // });
    this.components = [
      <Business setNext={this.setNext}/>,
      <Event setNext={this.setNext}/>,
      <Product setNext={this.setNext}/>,
      <PrivateCategory setNext={this.setNext} />,
      <Choosecategory setNext={this.setNext} />,
      <Addsubcategory setNext={this.setNext} />,
      <Choosevideo setNext={this.setNext} />,
      <Choosevideolanguage setNext={this.setNext} />,
      <Choosevideoenvironment setNext={this.setNext} />,
      <Videosymbols setNext={this.setNext} />,
      <Greate updatedfunction={this.updatedfunction} />
      // <h1>Hello</h1>
    ];
  }

  componentDidMount() {
    if(this.context.metaPickerState.completed){
      this.setState({
        count: this.context.metaPickerState.count,
        completed: this.context.metaPickerState.completed,
        next: this.context.metaPickerState.next
      });
    }
    for(let i=1;i<this.context.metaPickerState.count-1;i++){
      if(i%2==0){
        $(`#timelinePanel${i}`).addClass(classes.newtimlepaintl1);
        $(`#timelinePanel${i}`).addClass(classes.newtimlepaintl2);
      } else{
        $(`#timelinePanel${i}`).addClass(classes.timelinePane2css);
        $(`#timelinePanel${i}`).addClass(classes.timelinePane2cssafter);
      }
      $(`#timelineBadge${i}`).css({ "--customback": "#27da11" });
    }
    console.log("Added State"); 
  }
  
  
  updatedfunction() {
    const armvar = localStorage.getItem('armflag');
    if (armvar === "on") {
      this.setState({
        armreimage: arm
      });
      localStorage.removeItem('armflag');
    }

  }

  handleclick() {
    this.state.completed.pop();
    const li = this.state.completed.length-1;
    this.setState({
      count: li<0? 0 : this.state.completed[li],
      completed: this.state.completed,
    }, ()=>{
      console.log(this.state.completed);
      this.context.setMetaPickerState({
        completed: this.state.completed,
        count: this.state.count
      })
    })
    this.setState({ prevflag: true })
    if (this.state.nextflag == true) {
      this.setState({ nextflag: false })
    }
  }
  setNext(next){
    console.log("Changing", next);
    this.setState({next:next});
  }
  handleclick1() {
    this.setState({
      completed: [...this.state.completed, this.state.next],
      count: this.state.next
    }, ()=>{
      console.log(this.state.completed);
      this.context.setMetaPickerState({
        count: this.state.count,
        completed: this.state.completed
      })
    });
    
    this.setState({ nextflag: true })
    if (this.state.prevflag == true) {
      this.setState({ prevflag: false })
    }
  }

  render() {
    const items = []
    for(let i = 0; i < 8; i++){
      items.push(<li key={`timelineItem${i+1}`} className={classes[`timelineItem${i+1}`]}>
        <div className={classes[`timelinePanel${i%2+1}`]} id={`timelinePanel${i+1}`}>
          <div className={classes.timelineHeading}>
            <h4 className={classes[`timelineTitle${parseInt(i%2)+1}`]}>{`0${i+1}`}</h4>
          </div>
          {/* <span className={classes.step1}>step 1</span> */}
        </div>
        <div className={classes[`timelineBadge${i+1}`]} id={`timelineBadge${i+1}`}></div>
      </li>);
    }
    
    
    console.log(`Component=${this.components[this.state.count].toString()}, ${this.state.count}`)
    return (
      <div className={classes.bodycontaineryy}>
        <div className={classes.container}>
          <div className={classes.containerblow}>
            <ul className={classes.timelineHorizontal}>
              {items}
              <li className={classes.timelineItem9}>
                <div className={classes.timelinePanel9} id="timelinePanel9">
                  <div className={classes.timelineHeading}>
                    <h4 className={classes.timelineTitle3}></h4>
                  </div>
                  <div>
                    <img src={this.state.armreimage} id="armpre" className={classes.armpre} alt="my image" />
                  </div>
                  <span className={classes.step9} id="finished">FINISH</span>
                </div>
                <div className={classes.timelineBadge9} id="timelineBadge9"></div>
              </li>
            </ul>
          </div>
          <div className={field}>
          <div id="preimagediv">
            <button 
              className={classes.custombutton} 
              onClick={this.handleclick}>
                <div id="preimageicon" 
                  className={this.state.count === 0 ? classes.preimageicon : classes.preimageicongreen}>
                </div>
              </button>
          </div>
            {this.components[this.state.count]}
            <div id="nextimage" >
              <button 
                className={classes.custombutton} 
                onClick={this.handleclick1} >
                  <div id="nexticon" 
                    className={this.state.count == this.state.next? classes.nexticon : classes.nexticongreen}>
                  </div>
                </button>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
