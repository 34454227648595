import React from "react";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
} from "reactstrap";

import classes from "./Collaborations.module.css";
import SearchHeader from "components/Gallery/SearchHeader/SearchHeader";
import BannerImg from "assets/colabration.jpg";

import Icon1 from "assets/collicon1.png";
import Icon2 from "assets/collicon2.png";
import Icon3 from "assets/collicon3.png";
import Icon4 from "assets/collicon4.png";
import Icon5 from "assets/collicon5.png";
import Icon6 from "assets/collicon6.png";
import Graphics2 from "assets/Collabora-graph.png";
import Graphics1 from "assets/graphic1.png";

const collaborations = (props) => {
  return (
    <div className={classes.Collaborations}>
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <SearchHeader />
      </Col>
      <div className={classes.ImgBanner}>
        {" "}
        <img src={BannerImg} alt="IMG" className="img-fluid" />
        <div className={classes.Bannertext}>
          <h1>Collaborations</h1>
          <p>Partner with us</p>
        </div>
        <div className={classes.OuterIcon}>
          <Container>
            <Row className="m-0">
              <Col sm="12" md={{ size: 2 }} className="text-center">
                {" "}
                <div className={classes.ImgIcon}>
                  <img src={Icon1} alt="IMG" className="img-fluid" />
                </div>
                <p>Targeted audience </p>{" "}
              </Col>
              <Col sm="12" md={{ size: 2 }} className="text-center">
                <div className={classes.ImgIcon}>
                  <img src={Icon2} alt="IMG" className="img-fluid" />
                </div>
                <p>reat ROI</p>{" "}
              </Col>
              <Col sm="12" md={{ size: 2 }} className="text-center">
                <div className={classes.ImgIcon}>
                  <img src={Icon3} alt="IMG" className="img-fluid" />{" "}
                </div>
                <p>Exclusive display</p>{" "}
              </Col>
              <Col sm="12" md={{ size: 2 }} className="text-center">
                <div className={classes.ImgIcon}>
                  {" "}
                  <img src={Icon4} alt="IMG" className="img-fluid" />
                </div>
                <p>Video ads in loop mode</p>{" "}
              </Col>
              <Col sm="12" md={{ size: 2 }} className="text-center">
                <div className={classes.ImgIcon}>
                  {" "}
                  <img src={Icon5} alt="IMG" className="img-fluid" />
                </div>
                <p>Exposure by categories</p>{" "}
              </Col>
              <Col sm="12" md={{ size: 2 }} className="text-center">
                <div className={classes.ImgIcon}>
                  {" "}
                  <img src={Icon6} alt="IMG" className="img-fluid" />
                </div>
                <p>harmonic ad structure</p>{" "}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <div>
          <p className={classes.SentanceHead}>
            Reach your audience according to our categories. Our site is
            focusing on business traffic.
          </p>
          <p className={classes.Sentance}>
            Our site is made up of dozens of categories and sub-categories, so
            it is easy to analyze which pages exposing best to your niche
            audience. We offer ads spots that can display videos & banners that
            do not harm the site’s texture and give tremendous value to
            advertisers. Smart on-site analytics & optimization tools.
          </p>
        </div>
        <div className="mt-4">
          <h2 className={classes.Headings}>Video Ad Players</h2>
          <div>
            {" "}
            <img src={Graphics1} alt="IMG" className="img-fluid" />
          </div>
        </div>
        <div className="mt-4">
          <h2 className={classes.Headings}>Video Ad Players</h2>

          <div>
            {" "}
            <img src={Graphics2} alt="IMG" className="img-fluid" />
          </div>
        </div>
      </Col>
      <div className="clearfix bg-white pt-4 pb-4">
        <p className={classes.Every}>Every ad has the seat of honor</p>
        <p className={classes.MoreDetail}>
          {" "}
          Please contact us for more details.
        </p>
        <Col sm="12" md={{ size: 4, offset: 4 }} className="p-0">
          <Form>
            <FormGroup>
              <Input
                type="text"
                name="name"
                id=""
                placeholder="Name"
                bsSize="sm"
                className={classes.Myinput}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="companylast_name"
                id=""
                placeholder="Company"
                bsSize="sm"
                className={classes.Myinput}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="url"
                name="url"
                id=""
                placeholder="url"
                bsSize="sm"
                className={classes.Myinput}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                id=""
                placeholder="Email"
                bsSize="sm"
                className={classes.Myinput}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="phone"
                id=""
                placeholder="Phone"
                bsSize="sm"
                className={classes.Myinput}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="country"
                id=""
                placeholder="Country"
                bsSize="sm"
                className={classes.Myinput}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="industry"
                id=""
                placeholder="Industry"
                bsSize="sm"
                className={classes.Myinput}
              />
            </FormGroup>
            <FormGroup className="mb-0">
              {" "}
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                rows="7"
                className={classes.Myinput}
              />{" "}
            </FormGroup>

            <FormGroup className="text-center">
              <Button className={classes.Send}> SEND </Button>
            </FormGroup>
          </Form>
        </Col>
      </div>
    </div>
  );
};

export default collaborations;
