import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classes from "./ImprintandtermsNav.module.css";
import classnames from "classnames";
//import Imprint from "./Imprint";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";

const ImprintandtermsNav = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div>
      <div className={classes.ContributorNav}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={[
                classnames({ active: activeTab === "1" }),
                "nav-item",
              ].join(" ")}
              onClick={() => {
                toggle("1");
              }}
              activeClassName={classes.active}
            >
              Imprint
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
              activeClassName={classes.active}
            >
              Terms
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
                activeClassName={classes.active}
            >
              Privacy Policy
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">{/* <Imprint /> */}</TabPane>
        <TabPane tabId="2">
          <Terms />
        </TabPane>
        <TabPane tabId="3">
          <PrivacyPolicy />
        </TabPane>
      </TabContent>
    </div>
    // <div className={classes.ContributorNav}>
    //   <Nav>
    //     <NavItem>
    //       <NavLink href="#"> Contributor introduction </NavLink>
    //     </NavItem>
    //     <NavItem>
    //       <NavLink href="#"> Upload & organizing set up </NavLink>
    //     </NavItem>
    //     <NavItem>
    //       <NavLink link='/ContributorGuidelineTwo'> Video wish list </NavLink>
    //     </NavItem>
    //     <NavItem>
    //       <NavLink href="#"> License </NavLink>
    //     </NavItem>
    //     <NavItem>
    //       <NavLink href="#"> Support </NavLink>
    //     </NavItem>
    //   </Nav>
    //   </div>
  );
};

export default ImprintandtermsNav;
