import React, { Component } from 'react';

import Blog from 'components/Blog/Blog';

class BlogBuilder extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <Blog />
    )
  }
}

export default BlogBuilder;
