import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";

import classes from "./VideoItems.module.css";
import VideoItem from "./VideoItem/VideoItem";
import Shifting from "./Shifting/Shifting.jsx";

const seriesVideoItems = (props) => {
  let videos = [];
  let seriesVideos = Object.keys(props.videos);
  videos = seriesVideos.map((seriedId, i) => {
    if (props.loadSeriesIDVideos === parseInt(seriedId)) {
      let series = props.videos[seriedId];
      return (
        // <React.Fragment>
        <Col key={series.id} sm="12" className={classes.VedioSec}>
          <div className={classes.SeriesTitleDiv}>
            {/* <Container style={{margin:0}}> */}
            <Row>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <Shifting
                  loadSeriesIDVideos={props.loadSeriesIDVideos}
                  seriesList={props.seriesList}
                  loadSeriesVideos={props.loadSeriesVideos}
                />
              </Col>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <p sm="12" className={classes.SeriesText}>
                  {" "}
                  <span>Series {i + 1}: </span> {series.series_sign} +{" "}
                  {series.name} + {series.short_name}
                </p>
              </Col>
            </Row>
            {/* </Container> */}
          </div>
          {/* <div className={classes.PlayersIcon}>
            <Link to={"/"}>
              <div className={classes.User}> </div>
            </Link>
            <Link to={"/"}>
              <div className={classes.Chat}> </div>
            </Link>
            <Link to={"/"}>
              <div className={classes.Share}> </div>
            </Link>
            <Link to={"/"}>
              <div className={classes.Heart}> </div>
            </Link>
            <Link to={"/"}>
              <div className={classes.Music}> </div>
            </Link>
          </div> */}
          {series.videos ? (
            <Row key={"seriesItemRow_" + series.id}>
              {series.videos.map((res, i) => {
                return (
                  <Col
                    key={"seriesItemCol_" + res.id}
                    sm="12"
                    md={{ size: 3 }}
                    className="p-2"
                  >
                    <VideoItem
                      key={"VideoItem" + i}
                      name={res.title}
                      is_series_item={true}
                      poster_link={res.image}
                      file_link={res.video_url}
                      showPopup={() => props.showPopup(res)}
                      showEmbedingPopup={() => props.showEmbedingPopup(res)}
                      loadSeries={() => props.loadSeries(res.id)}
                      thumbnail_video_id={res.id}
                      video_id={res.video_id}
                      is_sponser={res.is_sponser ? res.is_sponser : false}
                      onMouseOver={() =>
                        props.onMouseOverPlayVideo(res.video_id)
                      }
                      onMouseOut={() =>
                        props.onMouseOutPauseVideo(res.video_id)
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            ""
          )}

          <div className={classes.ShiftingBottom}>
            <Shifting
              loadSeriesIDVideos={props.loadSeriesIDVideos}
              seriesList={props.seriesList}
              loadSeriesVideos={props.loadSeriesVideos}
            />
          </div>
        </Col>
        // </React.Fragment>
      );
    }
  });

  return <Row className={classes.SeriesVideoItems}>{videos}</Row>;
};

export default seriesVideoItems;
