import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-component';

import classes from './VideoItems.module.css';
import VideoItem from './VideoItem/VideoItem';

import {
  loadGalleries
} from 'store/actions';

const mapStateToProps = state => ({
  galleries: state.galleries,
  searchQuery: state.searchQuery
});
const mapDispatchToProps = dispatch => ({
  loadGalleries: categories => dispatch(loadGalleries(categories))
});

class VideoItems extends Component {

  componentDidMount() {
    let payload = {};
    // payload.endpoint = 'gallery/?query=lightning';
    payload.endpoint = 'gallery/';
    if (!this.props.searchQuery && this.props.galleries === undefined) {
      this.props.loadGalleries(payload);
    }
  }

  handler = {
    setOnMouseOverPlayVideo: (video_id) => {
      if (video_id) {
        var t = document.getElementById(video_id);
        t.play()
      }
    },

    setOnMouseOutPauseVideo: (video_id) => {
      if (video_id) {
        var t = document.getElementById(video_id);
        t.pause()
      }
      // console.log('out' + video_id);
    },

    uploadMoreVideos: (endpoint) => {
      let payload = {};
      payload.endpoint = endpoint;
      this.props.loadGalleries(payload);
    }
  }

  render() {
    const masonryOptions = {
      transitionDuration: 0
    };

    if (!this.props.galleries) {
      return null;
    }

    const videos = this.props.galleries.results ?
      this.props.galleries.results.map((res, i) => {
        let name = res.name;
        if (res.videos_count > 1) {
          name = "Total- " + res.series_count + " Series / " + res.videos_count + " Movies";
        }
        let customStyle = {
          // float: "left",
          // flex: "none",
          // marginBottom: "10px"
        }

        if (res.thumbnail_video.resolutions && res.thumbnail_video.resolutions.length > 0 && res.thumbnail_video.resolutions[0].height && res.thumbnail_video.resolutions[0].width) {
          let current_hght = (window.screen.width * 25) / 100;
          let height = (parseInt(res.thumbnail_video.resolutions[0].height) / parseInt(res.thumbnail_video.resolutions[0].width)) * current_hght;
          customStyle.height = height + "px";
          // let width = res.thumbnail_video.resolutions[0].width;
          // let height = res.thumbnail_video.resolutions[0].height;
          // let ration = (height / width) * 100;
          // customStyle.paddingTop = ration + '%';
        }

        return <Col sm="12" md={{ size: 3 }} key={"Col" + i} className="p-2">
          <VideoItem
            key={"VideoItem" + i}
            name={name}
            poster_link={res.thumbnail_video.video_id ? res.thumbnail_video.image : ''}
            file_link={(res.thumbnail_video.resolutions && res.thumbnail_video.resolutions.length > 0) ? res.thumbnail_video.resolutions[0].file_link : ''}
            resolutions={(res.thumbnail_video.resolutions && res.thumbnail_video.resolutions.length > 0) ? res.thumbnail_video.resolutions[0] : {}}
            showPopup={() => this.props.showPopup(res.thumbnail_video)}
            showEmbedingPopup={() => this.props.showEmbedingPopup(res.thumbnail_video)}
            totalVideos={res.videos_count}
            thumbnail_video_id={res.thumbnail_video.id}
            video_id={res.thumbnail_video.video_id}
            is_sponser={res.is_sponser ? res.is_sponser : false}
            onMouseOver={() => this.handler.setOnMouseOverPlayVideo(res.thumbnail_video.video_id)}
            onMouseOut={() => this.handler.setOnMouseOutPauseVideo(res.thumbnail_video.video_id)} />
        </Col>
      })
      : "";

    return (
      <InfiniteScroll
        dataLength={this.props.galleries.results.length} //This is important field to render the next data
        next={() => this.handler.uploadMoreVideos(this.props.galleries.next)}
        hasMore={this.props.galleries.next ? true : false}
        // hasMore={false}
        // loader={<h4>Loading....</h4>}
        // endMessage={
        //   <p style={{ textAlign: 'center' }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
        useWindow={false}
        style={{ height: "100%", width: "100%", overflow: "hidden", marginBottom: "7px" }}
      >
        <Masonry
          className={['my-gallery-class', classes.VideoItems].join(" ")}
          options={masonryOptions}
        // style={style}
        // onClick={this.handleClick}
        >
          {videos}
        </Masonry>
      </InfiniteScroll >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoItems);