import React, {useState, useEffect} from 'react'
import { MDBCol, MDBIcon } from "mdbreact";

const FilterableList = ({heading, items, checkedValue, handleChange, multiChecked, valueLabel}) => {
    const [filtered, setFiltered] = useState([...items]);
    const [filterString, setFilterString] = useState("");
    useEffect(() => {
        setFiltered([...items.filter(item=>item.country.toLowerCase().indexOf(filterString.toLowerCase()) !== -1)])
    }, [items, checkedValue, handleChange, filterString]);
    console.log(checkedValue);
    return (
        <div className="container-fluid">
        <div className="filed">
          <MDBCol md="10" className="searchcategory">
            <div className="input-group md-form form-sm form-1 pl-0">
              <div className="input-group-prepend">
                <span className="input-group-text purple lighten-3" id="basic-text1">
                  <MDBIcon className="text-white" icon="search" />
                </span>
              </div>
              <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={e=>{
                  console.log(e.target.value);
                  setFilterString(e.target.value);
              }} />
            </div>
          </MDBCol>
          <div className="headerspell">
            <span>{heading}</span>
          </div>
          <div className="col-xs-5" id="radioview">
            {filtered.map((option, i) => (
              <div key={i} className="radioitem">
                <input
                  type= {multiChecked?"checkbox":"radio"}
                  name="site_name"
                  value={valueLabel?option.country:items.indexOf(option)}
                  className={multiChecked?"checkbox":"radiobutton"}
                  checked={multiChecked?multiChecked(option) : checkedValue === option.country}
                  onChange={handleChange}
                />
                <label className="radiolabel">{option.country}</label>
              </div>
            ))}
          </div>

        </div>
      </div>
    )
}

export default FilterableList
