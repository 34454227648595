import React from "react";

const appContext = React.createContext({
  vimeoIntegrated: false,
  vimeoVideos: [],
  myVideos: [],
  inProgressUploads: [],
  uploadStats: {},
  metaPickerState : {},
  setBottomRightContent: (bottomRightContent) => null,
  toggleBusy: (boolean) => null,
  setVimeoIntegrationStatus: (boolean) => null,
  setVimeoVideos: (videos) => null,
  setMyVideos: (videos) => null,
  setInProgressUploads: (files) => null,
  setUploadStats: (stats) => null,
  setMetaPickerState: (state) => null
});

export default appContext;
