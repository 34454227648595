import React from "react";
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import classes from "./CustomModals.module.css";

const SoundtrackLicenseModal = (props) => {
  const modal = props.isOpen;
  const toggle = () => !modal;

  return (
    <Modal
      size={props.modalSize ? props.modalSize : "lg"}
      isOpen={modal}
      backdrop={false}
      fade={false}
      toggle={toggle}
      className={classes.SoundtrackLicenseModal}
      contentClassName={classes.Test}
    >
      <ModalHeader className={classes.MHeader} toggle={props.closed}>
        <p>Sound Track License for Winner promo</p>
      </ModalHeader>
      <ModalBody className={classes.ModalBody}>
        <Col sm="12" dangerouslySetInnerHTML={{ __html: props.license }}></Col>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.closed}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SoundtrackLicenseModal;
