import {
    SET_APP_CONFIG,
    LOAD_CATEGORIES,
    LOAD_GALLERIES,
    LOAD_SERIES,
    LOAD_SERIES_VIDEOS,
    LOAD_SEARCH_GALLERIES,
    SET_SEARCH_QUERY,
    UPDATE_PROFILE_DETAIL
} from './actionTypes';

import httpService from 'services/httpService';

export const setAppConfig = (payload) => {
    return ({ type: SET_APP_CONFIG, payload });
}
export const loadCategories = () => {
    // return ({ type: LOAD_CATEGORIES, payload });
    return dispatch => {
        httpService.get('gallery/categories/').then(function (response) {
            if (response.data) {
                let payload = {};
                payload.categories = response.data;
                return dispatch({ type: LOAD_CATEGORIES, payload })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
}
export const loadGalleries = (payload) => {
    // return ({ type: LOAD_GALLERIES, payload });
    return dispatch => {
        httpService.get(payload.endpoint).then(function (response) {
            if (response.data) {
                let payload = {};
                payload.galleries = response.data;
                return dispatch({ type: LOAD_GALLERIES, payload })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
}
export const searchGalleries = (payload) => {
    // return ({ type: LOAD_GALLERIES, payload });
    return dispatch => {
        let updatePayload = {};
        updatePayload.searchQuery = payload.searchQuery;
        dispatch({ type: SET_SEARCH_QUERY, payload: updatePayload })
        httpService.get(payload.endpoint).then(function (response) {
            if (response.data) {
                updatePayload.galleries = response.data;
                return dispatch({ type: LOAD_SEARCH_GALLERIES, payload: updatePayload })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
}
export const loadSeries = (payload) => {
    return dispatch => {
        // let updatePayload = {};
        // updatePayload.series = {};
        // updatePayload.loadSeriesIDVideos = '';
        // updatePayload.seriesList = {};
        // dispatch({ type: LOAD_SERIES, payload: updatePayload });
        httpService.get(payload.endpoint).then(function (response) {
            if (response.data) {
                let payload = {};
                payload.series = response.data;
                let seriesList = {};
                if (response.data.series && response.data.series.length > 0) {
                    for (let key in response.data.series) {
                        seriesList[response.data.series[key].id] = response.data.series[key];
                    }
                    payload.loadSeriesIDVideos = response.data.series[0].id;
                }
                payload.seriesList = seriesList;
                return dispatch({ type: LOAD_SERIES, payload })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
}
export const loadSeriesVideos = (payload) => {
    // return ({ type: LOAD_SERIES_VIDEOS, payload });
    return (dispatch, getState) => {
        if (getState().seriesVideos[payload.series_id] === undefined) {
            httpService.get(payload.endpoint).then(function (response) {
                if (response.data) {
                    let payload = {};
                    payload.seriesVideos = response.data;
                    return dispatch({ type: LOAD_SERIES_VIDEOS, payload });
                }
            }).catch(function (error) {
                console.log(error);
            })
        } else {
            if (getState().loadSeriesIDVideos !== payload.series_id) {
                let updatedPayload = {};
                updatedPayload.seriesVideos = getState().seriesVideos[payload.series_id];
                return dispatch({ type: LOAD_SERIES_VIDEOS, payload: updatedPayload })
            }
        }

    }
}
export const updateProfileDetails = (payload) => {
    return ({ type: UPDATE_PROFILE_DETAIL, payload });
}
