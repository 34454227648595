import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap';

import classes from './AuthModal.module.css';

const LogoutModal = (props) => {

    const toggle = () => !props.isOpen;

   

    return (
        <Modal
            size="md"
            isOpen={props.isOpen}
            backdrop={true}
            toggle={toggle}
            contentClassName={classes.Test} >
            <ModalHeader className={classes.MHeader}>

            </ModalHeader>
            <ModalBody className={[classes.ModalBody, 'text-center'].join(" ")}>
                <Col sm="12">
                    <h2>Are you sure?</h2>
                </Col>
            </ModalBody>
            <ModalFooter>
                {/* <Button color="primary" onClick={props.logout} className={classes.Jion}>Logout</Button> */}
                <Button color="primary" onClick={props.logout} className={classes.Jion}>Logout</Button>
                <Button color="secondary" onClick={props.closed} className={classes.Jion}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

export default LogoutModal;