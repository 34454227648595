import React from "react";

import { Row, Col } from "reactstrap";

import classes from "./Upload.module.css";
import SearchHeader from "components/Gallery/SearchHeader/SearchHeader";
import UserProfileImg from "components/UI/UserProfileImg/UserProfileImg";

import UploadOptions from "./UploadOptions";

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      player: null,
    };
  }

  render() {
    return (
      <div className={classes.Upload}>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <SearchHeader
            isSearchHederTextHide={true}
            isRoyaltyFreeTextHide={true}
          />
        </Col>

        <div className={classes.UploadContent}>
          <p>
            {" "}
            100% control over your content distribution via your Vimeo account!
          </p>
          <p> You will never have a storage issue! </p>
          <p>
            {" "}
            Whatever your Vimeo account can store, we can distribute it on
            VimeoBzz!{" "}
          </p>
          <p>
            {" "}
            Immediate access without long upload time as long your content is
            stored on your Vimeo account!{" "}
          </p>
          <p>
            {" "}
            Connect your Vimeo account and start sharing your content right away
            on VideoBzz!{" "}
          </p>
        </div>
        <div style={{
          marginLeft:"auto",
          marginRight: "auto",
          maxWidth: "35%"
        }}>

              <UploadOptions
                showVimeo={true}
                showUploads={true}
                showDropTarget={true}
                handler={() => console.log("not here")}
              />
            </div>
        
      </div>
    );
  }
}

export default Upload;
