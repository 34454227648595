import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Label,
  Input,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faMapMarkerAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./Series.module.css";
import SearchHeader from "../SearchHeader/SearchHeader";
import UserProfileImg from "components/UI/UserProfileImg/UserProfileImg";
import SoundtrackLicenseModal from "components/UI/CustomModals/SoundtrackLicenseModal";

import Msg from "assets/msg.png";
import Social from "assets/socialicon.png";

const Basic = () => {
  return (
    <div>
      <div className={classes.Rate}>
        <p>Rated</p>
        <div>
          <span>
            <FontAwesomeIcon
              className={classes.CogIcon}
              icon={faStar}
            ></FontAwesomeIcon>
          </span>
          <span>
            <FontAwesomeIcon
              className={classes.CogIcon}
              icon={faStar}
            ></FontAwesomeIcon>
          </span>
          <span>
            <FontAwesomeIcon
              className={classes.CogIcon}
              icon={faStar}
            ></FontAwesomeIcon>
          </span>
          <span>
            <FontAwesomeIcon
              className={classes.CogIcon}
              icon={faStar}
            ></FontAwesomeIcon>
          </span>
          <span>
            <FontAwesomeIcon
              className={classes.CogIcon}
              icon={faStar}
            ></FontAwesomeIcon>
          </span>
        </div>
      </div>
      <div className={classes.Mapmark}>
        <p>
          <FontAwesomeIcon
            className={classes.MapIcon}
            icon={faMapMarkerAlt}
          ></FontAwesomeIcon>{" "}
          Israel
        </p>
        <p>
          <span>
            <FontAwesomeIcon
              className={classes.MapIcon}
              icon={faUser}
            ></FontAwesomeIcon>{" "}
            Member Since
          </span>
          <span className="float-right"> June 2020</span>
        </p>
      </div>
    </div>
  );
};
const ProfileDescription = () => {
  return (
    <div className={classes.Slide2}>
      <p className={classes.Para}>
        {" "}
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.Lorem Ipsum is simply dummy text of the printing and
        typesetting industry.Lorem Ipsum is simply dummy text of the printing
        and typesetting industry.Lorem Ipsum is simply dummy text of the
        printing and typesetting industry.Lorem Ipsum is simply dummy text of
        the printing and typesetting industry.{" "}
      </p>
    </div>
  );
};

const items = [
  {
    key: "slide1",
    component: <Basic />,
  },
  {
    key: "slide2",
    component: <ProfileDescription />,
  },
];

class Series extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      animating: false,
      isShowSoundtrackLicenseModal: false,
    };
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.series ? this.props.series.id !== nextProps.series.id : true;
  // }

  handler = {
    setAnimating: (val) => {
      this.setState({ animating: val });
    },
    next: () => {
      if (this.state.animating) return;
      const nextIndex =
        this.state.activeIndex === items.length - 1
          ? 0
          : this.state.activeIndex + 1;
      this.setState({ activeIndex: nextIndex });
    },
    previous: () => {
      if (this.state.animating) return;
      const nextIndex =
        this.state.activeIndex === items.length - 1
          ? 0
          : this.state.activeIndex - 1;
      this.setState({ activeIndex: nextIndex });
    },
    goToIndex: (i) => {
      if (this.state.animating) return;
      this.setState({ activeIndex: i });
    },
    isShowSoundtrackLicenseModal: () => {
      this.setState({ isShowSoundtrackLicenseModal: true });
    },
    closeSoundtrackLicenseModal: () => {
      this.setState({ isShowSoundtrackLicenseModal: false });
    },
  };

  render() {
    const slides = items.map((item, i) => {
      return (
        <CarouselItem
          onExiting={() => this.handler.setAnimating(true)}
          onExited={() => this.handler.setAnimating(false)}
          key={"Carousel_Item_" + i}
        >
          {item.component}
        </CarouselItem>
      );
    });

    const seriesLinks =
      this.props.series && this.props.series.series
        ? this.props.series.series.map((item, i) => {
            return (
              <span
                key={"Series_Link_" + item.id}
                className={
                  this.props.loadSeriesIDVideos === item.id
                    ? classes.ActiveSeris
                    : ""
                }
                onClick={() => this.props.loadSeriesVideos(item.id)}
              >
                {i + 1}. {item.series_sign} + {item.name} + {item.short_name}
              </span>
            );
          })
        : "";
    // Resolution, Heigh and Width
    const resolutionOptions =
      this.props.series && this.props.series.resolutions
        ? this.props.series.resolutions.map((video, i) => {
            let checked = "";
            if (i === 0) {
              checked = true;
            }
            return (
              <div
                className={classes.SeriesRightRadioPad}
                key={"series_id_" + i}
              >
                <Label check>
                  <Input
                    type="radio"
                    name="video_resolution"
                    defaultChecked={checked}
                  />{" "}
                  {video.quality}, {video.width} x {video.height}
                </Label>
              </div>
            );
          })
        : "";

    let videoStyle = {},
      seriesSidesStyle = {},
      ration = "0";
    if (
      this.props.series &&
      this.props.series.resolutions &&
      this.props.series.resolutions[0]
    ) {
      let width = this.props.series.resolutions[0].width;
      let height = this.props.series.resolutions[0].height;

      if (height > width) {
        ration = (width / height) * 100;
        videoStyle.minHeight = "600px";
        seriesSidesStyle.height = "auto";
      } else {
        ration = (height / width) * 100;
      }
      videoStyle.paddingTop = ration + "%";
    }

    return (
      <div className={classes.Series}>
        {this.props.series &&
        this.props.series.license &&
        this.state.isShowSoundtrackLicenseModal ? (
          <SoundtrackLicenseModal
            license={this.props.series.license}
            isOpen={this.state.isShowSoundtrackLicenseModal}
            closed={this.handler.closeSoundtrackLicenseModal}
          />
        ) : (
          ""
        )}
        <Row className={classes.Norow}>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <SearchHeader
              isSearchHederTextHide={this.props.isSearchHederTextHide}
            />
          </Col>
        </Row>
        <Row className={classes.SeriesTop}>
          <Col
            sm="12"
            md={{ size: 4 }}
            lg={{ size: 4 }}
            xl={{ size: 3 }}
            className="mt-sm-1"
          >
            <div className={classes.SeriesLeft} style={seriesSidesStyle}>
              <UserProfileImg />
              <Link to={"/chat/" + this.props.id}>
                <img src={Msg} alt="VIDEO_IMG" className={classes.Msg} />
              </Link>
              <p className={classes.ProfileName}>{localStorage.getItem("__vz__user")}</p>
              <p className={classes.ProfileDisc}>
                Living for creation and breathing for satisfaction
              </p>
              <Carousel
                activeIndex={this.state.activeIndex}
                next={this.handler.next}
                previous={this.handler.previous}
                className={classes.CarouselSlider}
              >
                {slides}

                <CarouselIndicators
                  className={classes.Indicator}
                  items={items}
                  activeIndex={this.state.activeIndex}
                  onClickHandler={this.handler.goToIndex}
                />
              </Carousel>

              <div className={classes.Socialsec}>
                <img src={Social} alt="Social" />
                <p>Get connected</p>
              </div>
            </div>
          </Col>
          <Col
            style={{ marginTop: 10 }}
            sm="12"
            md={{ size: 4 }}
            lg={{ size: 4 }}
            xl={{ size: 5 }}
            className="mt-sm-1"
          >
            <div className={classes.SeriesMiddle}>
              <div
                className={[classes.SeriesMiddlimg, "embed-responsive"].join(
                  " "
                )}
                style={videoStyle}
              >
                <div className={classes.PlayersIcon}>
                  <Link to={"/"}>
                    <div className={classes.User}> </div>
                  </Link>
                  <Link to={"/"}>
                    <div className={classes.Chat}> </div>
                  </Link>
                  <Link to={"/"}>
                    <div className={classes.Share}> </div>
                  </Link>
                  <Link to={"/"}>
                    <div className={classes.Heart}> </div>
                  </Link>
                  <Link to={"/"}>
                    <div className={classes.Music}> </div>
                  </Link>
                </div>

                {/* {
                  this.props.series.id ? */}
                <video
                  className="embed-responsive-item"
                  // preload={"none"}
                  autoPlay
                  controls
                  loop
                  id={
                    this.props.series && this.props.series.id
                      ? this.props.series.id
                      : ""
                  }
                  // poster={(this.props.series && this.props.series.id) ? this.props.series.image : ''}
                  src={
                    this.props.series && this.props.series.id
                      ? this.props.series.video_url
                      : ""
                  }
                />
                {/* : ''
                } */}
              </div>
              <div className={classes.SeriesMiddlecontent}>
                <span onClick={() => this.props.showPopup(this.props.series)}>
                  Free Download
                </span>
                <span
                  onClick={() =>
                    this.props.showEmbedingPopup(this.props.series)
                  }
                >
                  Free Embedding Code
                </span>
              </div>
            </div>
          </Col>
          <Col
            style={{ marginTop: 20 }}
            sm="12"
            md={{ size: 4 }}
            lg={{ size: 4 }}
            xl={{ size: 3 }}
            className="mt-sm-1"
          >
            <div className={classes.SeriesRight} style={seriesSidesStyle}>
              {this.props.series ? (
                <div>
                  <Col xs={{ size: 10, offset: 2 }}>
                    <div className={classes.SeriesRightRadio}>
                      {resolutionOptions}
                    </div>
                  </Col>
                  <hr />
                  <div className={classes.Duration}>
                    <p>Duration: {this.props.series.duration}</p>
                    <p>Alpha Channel: </p>
                    <p>
                      Resolution:{" "}
                      {this.props.series.resolutions
                        ? this.props.series.resolutions[0].width +
                          " x " +
                          this.props.series.resolutions[0].height +
                          " " +
                          this.props.series.resolutions[0].quality
                        : ""}
                    </p>
                    <p>Downloads: 0</p>
                  </div>
                  <hr />
                  <div className={classes.Soundtrack}>
                    <span></span>{" "}
                    <span onClick={this.handler.isShowSoundtrackLicenseModal}>
                      {" "}
                      * Check The Soundtrack License
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <hr />
              <div className={classes.pad}>
                <span className={classes.TextSet}>Check series</span>
                <div className={classes.Serieslink}>{seriesLinks}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Series;
