import React from 'react';
import { Col } from 'reactstrap';
import SearchHeader from 'components/Gallery/SearchHeader/SearchHeader';
import classes from './ContributorGuideline.module.css';
import BannerImg from '../../../assets/contributor_guideline.png';
import siteLogo from 'assets/grayLogo.png'
import ContributorNav from './ContributorNav';

const contributorGuideline = (props) => {

  return (
    <div className={classes.ContributorGuideline}>

      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <SearchHeader isSearchHederTextHide={true} isRoyaltyFreeTextHide={true} />
      </Col>
      <Col sm="12" md={{ size: 8, offset: 2 }} className="text-center mt-3">
        <p className={classes.IntroContriTop}>Let potential clients ﬁnd you, let them experience your skills through your free content,
           Let them initiate a chat with you that might lead to your next project!</p>
        <p className={classes.Orange}>Yes, it's all free!</p>
      </Col>
      <div className={classes.ImgBanner}> <img src={BannerImg} alt="IMG" className={classes.ImgBack} />
        <div className={classes.Imgtext}>
          <h1> Welcome to the <img src={siteLogo} alt="LOGO" /></h1>
          <h3> Contributor introducton & guideline</h3>
        </div>
      </div>

      <ContributorNav />


    </div>
  )
}


export default contributorGuideline;
