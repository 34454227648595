import React from "react";

import { Col, Button } from "reactstrap";

import classes from "./SetVimeoAccount.module.css";
import httpService from "services/httpService";
import queryString from "query-string";

import UploadOptions from "../../Contributor/Upload/UploadOptions";

import AppContext from "../../../contexts/AppContext";
import { VimeoPicker } from "./VimeoPicker";

class setVimeoAccount extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    const parsedQueryString = queryString.parse(props.location.search);
    if (parsedQueryString && parsedQueryString.code) {
      httpService
        .get("user/vimeo-setup/?code=" + parsedQueryString.code)
        .then((res) => {
          this.setState({
            videosList: res.data.data,
          });
          window.location.href = "/user";
        })
        .catch((error) => {
          console.log(error);
        });
    }
    console.log("Cosntructor");
    this.state = {
      videosList: [],
      showSelector: false,
      pageCount: 1,
      selectedList: {},
      vimeo_integrated: false,
      showMetaPicker: false,
      ascending: false,
      selectedPage: 0,
      sortMethod: "date",
      query: "",
      typingTimeout: 0,
      step: 0,
    };
  }

  // componentDidMount() {
  //   // console.log(this.context);
  //   // if (this.context.vimeo_integrated) {
  //   //   this.setState({ videosList: this.context.video_list });
  //   // }
  //   this.refreshData();
  // }

  myMethod = () => {
    if (!this.context.vimeoIntegrated) {
      httpService
        .get("user/vimeo-setup/")
        .then((res) => {
          window.location.href = res.data.vimeo_url;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        showSelector: true,
      });
    }
  };

  render() {
    const check_left = {
      float: "right",
      marginTop: "32px",
      marginLeft: "-5px",
      marginRight: "34px",
    };

    return (
      <div className={classes.SetVimeoAccount}>

        <div className={classes.LoginVimeo}>
          <span className={classes.LogVimeo}>
            {!this.context.vimeoIntegrated ? (
              <UploadOptions
                showVimeo={true}
                showUploads={true}
                showDropTarget={true}
                handler={this.myMethod}
              />
            ) : (
              <div>
                <p>Integrated already. Go to my videos and start publishing videos.</p>
                <Button onClick={() => window.location.href = "/user/my-videos"}>My videos</Button>
              </div>
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default setVimeoAccount;
