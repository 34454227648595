import React from 'react';
import { Link } from 'react-router-dom';

import classes from './FooterHeader.module.css';

import {
  Row,
  Col
} from 'reactstrap';
import FooterLogo from 'assets/VideoBzz-Logo.png'
const FooterHeader = (props) => {
  return (
    <div className={classes.FooterHeader}>
      <Row className={classes.Norow}>
        <Col sm="12" md={{ size: 3 }} lg={{ size: 2, offset: 1 }}>
          <div className={classes.FLogo}>
            <img src={FooterLogo} alt="LOGO" className="img-fliud" />
            <p><Link to={"/"} >www.videobuzz.com</Link></p>
          </div>
        </Col>
        <Col xs="6" sm="6" md={{ size: 3 }} lg={{ size: 2 }}>
          <p>57K</p>
          <p>Free DownLoad </p>
        </Col>
        <Col xs="6" sm="6" md={{ size: 3 }} lg={{ size: 2 }}>
          <p>57K</p>
          <p>Free Videos </p>
        </Col>
        <Col xs="6" sm="6" md={{ size: 3 }} lg={{ size: 2 }}>
          <p>57K</p>
          <p>Free Series </p>
        </Col>
        <Col xs="6" sm="6" md={{ size: 3 }} lg={{ size: 2 }}>
          <p>57K</p>
          <p>Contributors </p>
        </Col>

      </Row>
    </div>
  )
}

export default FooterHeader;
