import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classes from "./ContributorNav.module.css";
import classnames from "classnames";
import ContributorGuidelineTwo from "./ContributorGuidelineTwo";
import ContributorGuidelineOne from "./ContributorGuidelineOne";
import UploadandOrg from "./UploadandOrg";

const ContributorNav = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div>
      <div className={[classes.ContributorNav].join(" ")}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={[
                // classnames({ active: activeTab === "1" }),
                "nav-item",
                "Contributor_Nav",
              ].join(" ")}
              onClick={() => {
                toggle("1");
              }}
              // activeClassName={classes.active}
            >
              Contributor introduction & Tutorials
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Upload & organizing set up
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Video wish list
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              License
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggle("5");
              }}
            >
              Support
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ContributorGuidelineOne />
        </TabPane>
        <TabPane tabId="2">
          <UploadandOrg />
        </TabPane>
        <TabPane tabId="3">
          <ContributorGuidelineTwo />
        </TabPane>
        <TabPane tabId="4"></TabPane>
        <TabPane tabId="5"></TabPane>
      </TabContent>
    </div>
  );
};

export default ContributorNav;
