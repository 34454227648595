import React from "react";

import classes from "./BlogBanner.module.css";
import BannerImg from "assets/Blog/BlogBanner.png";

const blogBanner = (props) => {
  return (
    <div className={classes.BlogBanner}>
      {" "}
      <img src={BannerImg} alt="IMG" className="img-fluid" />
      <div className={classes.BannerText}>
        <p>OUR BLOG</p>
      </div>
    </div>
  );
};

export default blogBanner;
