import React from 'react';

import profileImgURL from 'assets/demo.png';
import classes from './UserProfileImg.module.css';
// import LoginModal from './Auth/LoginModal'

const userProfileImg = (props) => {
  return (
    <div className={classes.UserProfileImg} style={{ height: props.height, margin: props.margin }}>
      <img src={(localStorage.getItem("__vz__picture")) ? localStorage.getItem("__vz__picture") :profileImgURL} alt="UserProfileImg" />
    </div>
  )
}

export default userProfileImg;
