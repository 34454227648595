import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './FooterItem.module.css';

const footerItem = (props) => {
  return (
    <li className={classes.FooterItem}>
      <NavLink
        exact
        to={props.link}
        activeClassName={classes.active}
      >
        {props.children}
      </NavLink>
    </li>
  )
}
export default footerItem
