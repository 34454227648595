import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import classes from "./Upload.module.css";
import { Button } from "reactstrap";

const baseStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingTop: "50px",
  paddingLeft: "auto",
  paddingRight: "auto",
  paddingBottom: "50px",
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  // backgroundColor: "#ffffff",
  color: "darkblue",
  transition: "border .3s ease-in-out",
  border: "dashed grey 2px",
  borderRadius: "15px",
  //   width: "700px",
  //   height: "150px",
  minWidth: "500px",
  marginLeft: "auto",
  marginRight: "auto",
};

const activeStyle = {
  //   borderColor: "#2196f3",
  backgroundColor: "lightblue",
};

const acceptStyle = {
  //   borderColor: "#00e676",
  backgroundColor: "lightblue",
};

const rejectStyle = {
  //   borderColor: "#ff1744",
};

function DropzoneComponent(props) {
  const onDrop = useCallback((acceptedFiles) => {
    props.handleDrop(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "video/mp4",
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div>
      <div style={{ color: "gray", paddingBottom: "15px", fontSize: 20 }}>
        Browse or drag & drop
      </div>
      <div {...getRootProps({ style })}>
        {/* <input style={{}} type="file"></input> */}
        <div className={classes.Instructions}>
          <div className={classes.FakeButton}>
            <input {...getInputProps()} />
            Browse
          </div>{" "}
          <div style={{ color: "gray" }}>Or</div>
          <div className={classes.Outlier}>Drag & Drop</div>
        </div>
      </div>
    </div>
  );
}

export default DropzoneComponent;
