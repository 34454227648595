import React, { useState, useEffect, useContext } from "react";
import classes from "./VimeoPicker.module.css";
import {
  Col,
  Input,
  Row,
  CardColumns,
  Form,
  Button,
  Modal,
  Card,
  ModalBody,
  ModalHeader,
  CardImg,
  CardBody,
  CardText,
  Progress,
  Collapse
} from "reactstrap";
import StyledPaginate from "./StyledPaginate";
import ReactPaginate from "react-paginate";
import Greenicon from "assets/ContriAdmin/Greencheck.png";
import Grayicon from "assets/ContriAdmin/Icongray.png";
import httpService from "services/httpService";
import AppContext from "../../../contexts/AppContext";
import MetaInfoHelper from "./MetaInfoHelper";
import MetaInfoHelperOriginal from "./MetaInfoHelperOriginal"

export const VimeoPicker = (props) => {
  const [pageCount, setPageCount] = useState(0);
  const [videosList, setVideosList] = useState({
    bzz_videos: { data: [] },
    vimeo_acount: { data: [] },
  });
  const [selectedPage, setSelectedPage] = useState(0);
  const [ascending, setAscending] = useState(false);
  const [sortMethod, setSortMethod] = useState("date");
  const [query, setQuery] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [selectedList, setSelectedList] = useState({});
  const [showMetaPicker, setShowMetaPicker] = useState(false);
  const [step, setStep] = useState(0);
  const [beta, setBeta] = useState(false);
  const app = useContext(AppContext);

  const VIDEOS_PER_PAGE = 8;
  const sortMethods = {
    alphabetical: "Alphabatical",
    date: "Date Added",
    duration: "Video Duration",
    last_user_action_event_date: "Last interacted",
    likes: "Likes",
    modified_time: "Last modified Time",
    plays: "Number of plays",
  };

  useEffect(() => {
    refreshData();
  }, [selectedPage, sortMethod, ascending, query]);
  const refreshData = () => {
    const sort_order = ascending ? "asc" : "desc";
    app.toggleBusy(true);
    httpService
      .get(
        `user/vimeo-list/?per_page=${VIDEOS_PER_PAGE}&page=${
          selectedPage + 1
        }&direction=${sort_order}&sort=${sortMethod}&query=${query}`
      )
      .then((res) => {
        app.toggleBusy(false);

        const pageCount =
          Math.floor(
            parseInt(
              props.selectUpload
                ? res.data.bzz_videos.total
                : res.data.vimeo_account.total
            ) /
              parseInt(
                props.selectUpload
                  ? res.data.bzz_videos.per_page
                  : res.data.vimeo_account.per_page
              )
          ) + 1;

        console.log(res);
        setVideosList(res.data);

        setPageCount(pageCount);
      })
      .catch((error) => {
        app.toggleBusy(false);
        console.error(`error=${error}`);
        // this.setState({ vimeo_integrated: false });
      });
  };

  const toggleSortOrder = () => {
    setAscending(!ascending);
    // refreshData();
  };

  const updateSort = (e) => {
    setSortMethod(e.target.value);
    // refreshData();
  };
  const handlePageClick = (data) => {
    setSelectedPage(data.selected);
    // console.log(selectedPage);
    // refreshData();
  };
  const toggleSelection = (video) => {
    if (selectedList[video.uri] === undefined) {
      selectedList[video.uri] = video;
    } else {
      delete selectedList[video.uri];
    }

    setSelectedList({ ...selectedList });
    app.setMetaPickerState({selectedList: {...selectedList}});
  };

  const genThumbnails = (videos) => {
    return videos.map((video) => {
      const card = (
        <Card key={videos.indexOf(video)}>
          <CardImg
            top
            src={`${video.pictures.sizes[2].link}`}
            alt="thumbnail"
          />

          <CardBody className={classes.cardBody}>
            <CardText>{video.name.substr(0, 30)}</CardText>
            <Row>
              {/* <div className={classes.cardControls}> */}
              <Col>
                <CardText>
                  Created{" "}
                  {Math.trunc(
                    (Date.now() - Date.parse(video.created_time)) /
                      (24 * 3600 * 1000)
                  )}{" "}
                  days ago
                </CardText>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  disabled={video.status == "transcoding" || video.status == "transcode_starting"}
                  className={classes.SelectedState}
                  onClick={() => toggleSelection(video)}
                >
                  {
                    <img
                      src={
                        selectedList[video.uri] === undefined
                          ? Grayicon
                          : Greenicon
                      }
                    />
                  }
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );

      return card;
    });
  };
  const searchUpdated = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setQuery(e);
        //refreshData();
      }, 1000)
    );
  };
  const progressHandler = (step) => {
    setStep(step);
  };
  const queueForPublish = (beta) => {
    setBeta(beta);
    setShowMetaPicker(true);
  };

  const getSelector = () => {
    return (
      <div className={classes.selectionPreview}>
        <Row>
          <Col>
            <div className={classes.spacedOut}>
              <b>All videos</b>
            </div>
            <Row>
              <Input type="checkbox"></Input>
              <Input
                className={classes.selectionPreviewSorter}
                type="select"
                className={classes.FilterOpt}
                onChange={updateSort}
              >
                {Object.keys(sortMethods).map((sm) => {
                  return (
                    <option key={sm} value={sm}>
                      {sortMethods[sm]}
                    </option>
                  );
                })}
              </Input>
              <button
                style={{ maxHeight: "25px", border: "0" }}
                onClick={toggleSortOrder}
              >
                {ascending ? <span>&#8593;</span> : <span>&#8595;</span>}
              </button>
            </Row>
          </Col>
          <Col>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <input
                className={classes.rightSearchinput}
                onChange={(e) => searchUpdated(e.target.value)}
              />
              <button
                className={classes.rightSearchinput}
                disabled={true}
                style={{ marginLeft: "-30px" }}
              >
                &#128269;
              </button>
            </div>
          </Col>
        </Row>
        <hr></hr>
        <CardColumns className={classes.myColumns}>
          {props.selectUpload
            ? genThumbnails(videosList.bzz_videos.data)
            : genThumbnails(videosList.vimeo_account.data)}
        </CardColumns>
        <StyledPaginate>
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            containerClassName={"pagination"}
            activeClassName={"active"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageClassName={"page"}
            nextClassName={"next-class"}
            previousClassName={"previous-class"}
            onPageChange={handlePageClick}
          ></ReactPaginate>
        </StyledPaginate>
      </div>
    );
  };
  return (
    props.showSelector && <div>
      <div className={classes.box}>{props.showSelector && getSelector()}</div>
      {props.showSelector && (
        <div className={classes.SelectedState}>
          <b>Next</b> To complete video selection{" "}
          <Button color="primary" variant="contained" onClick={() => queueForPublish(false)}>Next</Button>
          <Button color="warning" variant="contained" onClick={() => queueForPublish(true)}>Next (Beta)</Button>
        </div>
      )}
      {showMetaPicker && (
       
        <Collapse  style={{marginTop: "40px"}} isOpen={showMetaPicker} size="xl">
          
           <ModalHeader
            style={{ background: "#f7f7f7", top:"40px" }}
            toggle={() => setShowMetaPicker(!showMetaPicker)}
          >
            
          </ModalHeader>
          <div  className={classes.box}>
          
         
          {/* <ModalBody style={{ background: "#efefef" }}> */}
            {beta?<MetaInfoHelper
              handler={progressHandler}
              selectedList={selectedList}
            />:<MetaInfoHelperOriginal
              handler={progressHandler}
              selectedList={selectedList}
              />
            }
          {/* </ModalBody> */}
          </div>
        </Collapse>
        
      )}
    </div>
  );
};
