import React from "react";
import { Col, Row } from "reactstrap";
import classes from "./ContributorGuidelineOne.module.css";
import ContributorFooter from "./ContributorFooter";
import Traingle from "assets/traingle.png";

const contributorGuidelineOne = (props) => {
  return (
    <div className={classes.ContributorGuideline}>
      <Col sm="12" md={{ size: 12 }}>
        <div>
          <div className={classes.ContributorTxt}>
            <p className={classes.para1}>
              If you are here, it means you are a passionate video animator
              creator that loves creating video marketing content and wants to
              be known for your skills
            </p>
            <p className={classes.para2}>
              {" "}
              As video marketing creator, we are all underﬆanding, in order to
              make a name for ourselves in this competitive induﬆry, ﬁrﬆ we need
              to be exposed as much as possible to the right business clients
              and to be spotted by their recruiters, and that is a big part of
              our mission for our contributors on{" "}
              <span className={classes.Videobuzz}> VideoBzz </span>
            </p>
            <Row className={classes.ImageRow}>
              <Col sm="12" md={{ size: 6 }}>
                <div className={classes.Traingleimg}>
                  {" "}
                  <img src={Traingle} alt="IMG" className="img-fluid" />{" "}
                  <div className={classes.ImgPara}>
                    <h4>Check</h4>
                    <h2>Contributor</h2>{" "}
                    <p className={classes.ImgPara2}>
                      <span className={classes.black}>Video series Vs</span>{" "}
                      single video upload
                      <br /> settings and series rename guidline
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm="12" md={{ size: 6 }} className="pr-0">
                <div className={classes.TratextUpper}>
                  <div className={classes.Tratext}>
                    <p>Video gallery of tutorials for cuntributors</p>
                    <p>This is the video player of the Video tutorials</p>
                  </div>
                </div>
                <div className={classes.Topthreep}>
                  <Row>
                    <Col sm="12" md={{ size: 4 }} className="p-0 border-right">
                      <p className={classes.Video}>video</p>
                    </Col>
                    <Col sm="12" md={{ size: 4 }} className="p-0 border-right">
                      <p className={classes.Video}>video</p>
                    </Col>
                    <Col sm="12" md={{ size: 4 }} className="p-0">
                      <p className={classes.Video}>video</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <p className={classes.para3}>
              Unlike any other platform, we believe the ﬆage should be yours! We
              call it "The ﬆage of opportunities"!
            </p>
            <p className={classes.para4}>
              <span className={classes.Videobuzz}>Video</span>
              <span>Bzz</span> is a B2B targeted platform available for an
              inﬆant download of generic marketing video content that can ﬁt moﬆ
              business promotional and
              <br />
              advertising video content.
            </p>

            <p className={classes.para5}>
              Think of your next project, think of how to attract attention with
              your generic marketing content, provide your beﬆ poﬆs, create
              followers with excellent video content,
              <br />
              and base clients that want you on their next video project!
            </p>
          </div>
          <div className={classes.Footer}>
            <ContributorFooter />
          </div>
        </div>
      </Col>
    </div>
  );
};

export default contributorGuidelineOne;
