import axios from 'axios';
import authService from './authService';


let api_url_base = `http://localhost:8000`;
if (window.location.href.startsWith(`https://stag.videobzz.com`)){
    api_url_base = `https://api-stag.videobzz.com`;
} else if(window.location.href.startsWith(`https://videobzz.com`)){
    api_url_base = `https://api.videobzz.com`;
}

var axiosInstance = axios.create({
    baseURL: api_url_base,
});

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
// axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
if (authService.isLoggedIn() != null && typeof authService.isLoggedIn() != 'undefined') {
    axiosInstance.defaults.headers.common['Authorization'] = "Token " + authService.isLoggedIn();
    console.log(authService.isLoggedIn())
}

axiosInstance.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status !== 401 && error.response.status >= 400 && error.response.status < 500;
    if (!expectedError) {
        console.error("http service error", error);
    }
    if (error.response.status === 401) {
        console.log("unauthorised, logging out ...");
        // window.location.replace("/");
    }

    return Promise.reject(error);
})

export default {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete
}