import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PropagateLoader from 'react-spinners/PropagateLoader';

const Loader = (props) => {
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                color: "#f99207"
            }}
            // spinner
            spinner={<PropagateLoader
                // css={{ margin: "10px", padding: "20px", textAlign: "center" }}
                color={"#f99207"}
            />}
        // text="Loading...."
        />

    );
}

export default Loader;