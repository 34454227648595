import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Progress,
  Collapse,
} from "reactstrap";
import httpService from "services/httpService";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AppContext from "../../contexts/AppContext";
import MetaInfoHelper from "../User/SetVimeoAccount/MetaInfoHelperOriginal";
import MetaInfoHelperBeta from "../User/SetVimeoAccount/MetaInfoHelper";
import UploaderActivity from "assets/Upload_activity.gif";
import classes from "./Uploader.module.css";
import ExpandIcon from "assets/expand.jpeg";
import CollapseIcon from "assets/collapse.png";
import SuccessIcon from "assets/success.png";

const Uploader = (props) => {
  const app = useContext(AppContext);
  const [intervalVar, setIntervalVar] = useState(null);
  const [showMetaPicker, setShowMetaPicker] = useState(false);
  const [showBetaPicker, setShowBetaPicker] = useState(false);
  const [selectedList, setSelectedList] = useState({});
  const [step, setStep] = useState(0);
  let x = null;
  let publishables = {};
  const [uploads, setUploads] = useState([]);
  const [uploaded, setUploaded] = useState({});
  const [ready, setReady] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [showContinue, setShowContinue] = useState(false);
  const [counter, setCounter] = useState(0);
  const [done, setDone] = useState(false);

  const progressHandler = (step) => {
    setStep(step);
  };

  const publish = () => {
    if (uploads.length == 0) {
      console.log(`Empty list`);
      return;
    }
    console.log(`Publishing:`);
    console.log(uploads);

    // stats[file.name] = { ...stats[file.name], uri: res };
    // setStats({...stats});
    // if (!x) {
    // x = setInterval(() => {
    httpService
      .get("/gallery/upload")
      .then((res) => {
        const vstats = res.data;
        const stats = { ...app.uploadStats };
        const keys = Object.keys(stats);
        console.log(`counter=${counter}`);

        console.log(vstats, stats);
        keys.forEach((k) => {
          const total = stats[k].total;
          const completed = stats[k].completed;
          if (vstats[k] == "complete") {
            console.log(`Clearing ${x}`);
            stats[k] = {
              completed: total,
              total: total,
              status: `Transcoding ${vstats[k]}`,
            };
          } else {
            const increment = total / 7;
            console.log(
              `continuing to search more x= ${x} , increment=${increment}, total=${total}`
            );
            if (uploaded[k]) {
              stats[k] = {
                completed: completed + increment,
                total: total,
                status: `Transcoding ${vstats[k] || "complete"}`,
              };
            }
          }
          // setStats({ ...stats });
          app.setUploadStats(stats);
        });
        console.log("------debug------");

        console.log(app.uploadStats, stats);

        if (Object.keys(vstats).length === 0) {
          console.log("-----------Finishing----------");
          const newstats = { ...app.uploadStats };
          Object.keys(newstats).map((k) => {
            newstats[k].status = `Transcoding complete`;
          });
          app.setUploadStats(newstats);
          httpService
            .get(
              `user/vimeo-list/?per_page=8&page=1&direction=asc&sort=date&query=`
            )
            .then((res) => {
              // app.toggleBusy(false);
              app.setVimeoVideos(res.data);
            })
            .catch((error) => {
              console.error(`error=${error}`);
            });

          setDone(true);
          setCounter((counter) => 0);
          clearInterval(x);
          setIntervalVar(null);

          httpService
            .post("/user/vimeo-uploaded-list/", {
              uris: uploads,
            })
            .then((resp) => {
              console.log(resp.data);
              publishables = { ...publishables, ...resp.data };
              console.log(publishables);
              setSelectedList(publishables);
              console.log(selectedList);
              setShowContinue(true);
              setUploads([]);
              setUploaded({});
              setReady(false);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        // clearInterval(x);
      });
    // }, 3000);
    // setIntervalVar(x);
    // }
  };

  const upload = useCallback((file) => {
    const stats = { ...app.uploadStats };
    const newStats = { ...stats };
    newStats[file.name] = { completed: 0, total: file.size * 5 };
    // setStats(newStats);
    app.setUploadStats(newStats);
    console.log("Submitted");
    const formData = new FormData();

    formData.append(`inputFile`, file);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        console.log(progressEvent);
        newStats[file.name] = {
          // uri: stats[file.name].uri || "/",
          completed: progressEvent.loaded,
          total: progressEvent.total * 5,
          status: "Uploading",
        };
        // setStats({ ...stats });
        app.setUploadStats({ ...newStats });
      },
    };
    httpService
      .post("/gallery/upload/", formData, config)
      .then((res) => {
        console.log("id", res.data.split("/")[2]);
        uploads.push(res.data);
        setUploads([...uploads]);
        uploaded[file.name] = res.data;
        setUploaded({ ...uploaded });
        console.log(uploads);
        if (!intervalVar) {
          setReady(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const [stats, setStats] = useState({});
  useEffect(() => {
    // const allFiles = [...app.inProgressUploads, ...props.files];
    props.files.forEach((file) => {
      upload(file);
    });
    // allFiles.forEach((file) => {
    //   stats[file.name] = { status: file.status };
    // });
    // setStats({ ...stats });
    app.setInProgressUploads(props.files);
  }, [props.files]);

  useEffect(() => {
    if (done || counter == 20) {
      return;
    }
    setTimeout(() => {
      publish();

      setCounter((counter) => counter + 1);
    }, 3000);

    return () => {
      setDone(false);
    };
  }, [ready, counter]);

  // console.log(props.files);
  // console.log(app);
  return (
    Object.keys(app.uploadStats).length > 0 && (
      <div style={{ justifyContent: "center" }}>
        {showContinue && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // marginLeft: "25px"
            }}
          >
            {/* <Button
          
          color="warning"
          onClick={() => {
            setShowBetaPicker(!showBetaPicker);
          }}>New TimeLine (BETA)</Button> */}
            <Button
              color="primary"
              onClick={() => {
                // setShowMetaPicker(!showMetaPicker);

                window.location.href = "/user/my-videos";
                app.setUploadStats({});
                app.setBottomRightContent(null);
              }}
            >
              Finish categories your videos
            </Button>
          </div>
        )}
        <div className={classes.Root}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "5px",
            }}
          >
            <img
              style={{ height: "30px", margin: "10px" }}
              src={UploaderActivity}
            />
            <h5 style={{ marginLeft: "10px", color: "gray" }}>
              Uploading {Object.keys(app.uploadStats).length} Videos
            </h5>
            <Button
              onClick={() => {
                setIsOpen(!isOpen);
                //app.setBottomRightContent(null);
                //window.location.href = "/user/my-videos";
              }}
              style={{
                border: "0px",
                backgroundColor: "white",
                marginLeft: "auto",
                marginRight: 0,
              }}
            >
              <img height="20px" src={isOpen ? CollapseIcon : ExpandIcon}></img>
            </Button>
          </div>
          {/* <hr></hr> */}
          <Collapse isOpen={isOpen}>
            <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
              {Object.keys(app.uploadStats).map((fname) => {
                // const file = app.uploadStats[fname];
                return (
                  app.uploadStats[fname] && (
                    <div key={fname} className={classes.Item}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // backgroundColor: "pink",
                          }}
                        >
                          <span className={classes.Line1}>
                            {fname.substring(0, 20)}
                            {fname.length > 20 ? "..." : ""}
                          </span>
                          <span className={classes.Line2}>
                            {app.uploadStats[fname].status}
                          </span>
                        </div>

                        <div
                          style={{
                            height: 30,
                            width: 30,
                            marginLeft: "auto",
                            marginRight: 0,
                          }}
                        >
                          {app.uploadStats[fname].status !=
                          "Transcoding complete" ? (
                            <CircularProgressbar
                              value={app.uploadStats[fname].completed}
                              maxValue={app.uploadStats[fname].total}
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: "green",
                                height: 30,
                                width: 30,
                                borderRadius: "50px",
                              }}
                            >
                              <img
                                height="30"
                                width="30"
                                src={SuccessIcon}
                                alt="done"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </Collapse>
          {showMetaPicker && (
            <Modal isOpen={showMetaPicker} size="xl">
              <Progress
                color="orange"
                style={{
                  width: "100%",
                  height: "50%",
                  backgroundColor: "orange",
                }}
                value={step}
                max={8}
              />
              <ModalHeader
                style={{ background: "#efefef" }}
                toggle={() => setShowMetaPicker(!showMetaPicker)}
              >
                <div className={classes.flexibleh}>
                  <p>Choose what do you promote</p>
                </div>
              </ModalHeader>
              <ModalBody style={{ background: "#efefef" }}>
                <MetaInfoHelper
                  handler={progressHandler}
                  selectedList={selectedList}
                />
              </ModalBody>
            </Modal>
          )}
          {showBetaPicker && (
            <Modal isOpen={showBetaPicker} size="xl">
              <Progress
                color="orange"
                style={{
                  width: "100%",
                  height: "50%",
                  backgroundColor: "orange",
                }}
                value={step}
                max={8}
              />
              <ModalHeader
                style={{ background: "#efefef" }}
                toggle={() => setShowBetaPicker(!showBetaPicker)}
              >
                <div className={classes.flexibleh}>
                  <p>Choose what do you promote</p>
                </div>
              </ModalHeader>
              <ModalBody style={{ background: "#efefef" }}>
                <MetaInfoHelperBeta
                  handler={progressHandler}
                  selectedList={selectedList}
                />
              </ModalBody>
            </Modal>
          )}
        </div>
      </div>
    )
  );
};
export default Uploader;
