import React from 'react';

import { FormGroup, Input, CustomInput } from 'reactstrap';

import classes from './PaymentDetails.module.css';

const paymentDetails = (props) => {

  return (
    <div className={classes.PaymentDetails}>
      <div className={classes.Payment}><p>PAYMENT SETUP FROM  DONATE</p></div>
      <div className={classes.Line1}>
        <div className={classes.part1}><span>!</span></div>
        <div className={classes.part2}> <FormGroup>
          <Input type="select" name="select" id="exampleSelect" className={classes.MySelect}>
            <option>Paypal settings for donating payments</option>
            <option>2</option>

          </Input>
        </FormGroup>
        </div>
        <div className={classes.OnOff}>
          <span className={classes.On}>On</span> <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" className={classes.MySwitch} /><span className={classes.Off}>Off</span>
        </div>

      </div>
      <div className={classes.Line1}>
        <div className={classes.part1}><span>!</span></div>
        <div className={classes.part2}> <FormGroup>
          <Input type="select" name="select" id="exampleSelect1" className={classes.MySelect}>
            <option>Paypal Email</option>
            <option>2</option>

          </Input>
        </FormGroup>
        </div>
        <div className={classes.OnOff}>
          <span className={classes.On}>On</span> <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" className={classes.MySwitch} /><span className={classes.Off}>Off</span>
        </div>

      </div>
      <div className={classes.Line1}>
        <div className={classes.part1}><span>!</span></div>
        <div className={classes.part2}> <FormGroup>
          <Input type="select" name="select" id="exampleSelect2" className={classes.MySelect}>
            <option>Choose other payment options</option>
            <option>2</option>

          </Input>
        </FormGroup>
        </div>
        <div className={classes.OnOff}>
          <span className={classes.On}>On</span> <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" className={classes.MySwitch} /><span className={classes.Off}>Off</span>
        </div>

      </div>

    </div>
  )
}


export default paymentDetails;
