import React from "react";

import classes from "./SearchHeader.module.css";
import SearchInput from "components/UI/SearchInput/SearchInput";

const SearchHeader = (props) => {
  return (
    <React.Fragment>
      {props.isSearchHederTextHide ? (
        ""
      ) : (
        <h3 className={classes.Headinglne}>
          The best <span className={classes.Textorange}>Free</span> Royalty-free
          video stock of pre-made promotional videos & video ads on the{" "}
          <span className={classes.Textorange}>web!</span>
          <span className={classes.spacing}>
            {" "}
            <br />
          </span>
        </h3>
      )}

      <SearchInput />
      <p className={classes.ParaRoyal}>
        All the content your business needs to start to promote!
      </p>
    </React.Fragment>
  );
};

export default SearchHeader;
