import React, { Component } from 'react';
import { connect } from 'react-redux';

import Series from 'components/Gallery/Series/Series'
import Chat from 'components/Chat/Chat'
import FreeDownloadModal from 'components/UI/CustomModals/FreeDownloadModal';
import EmbeddingCodeModal from 'components/UI/CustomModals/EmbeddingCodeModal';
import ThankYouModal from 'components/UI/CustomModals/ThankYouModal';

import {
  loadSeries,
  loadSeriesVideos
} from 'store/actions';

const mapStateToProps = state => ({
  series: state.series,
  seriesList: state.seriesList
});
const mapDispatchToProps = dispatch => ({
  loadSeries: id => dispatch(loadSeries(id)),
  loadSeriesVideos: videos => dispatch(loadSeriesVideos(videos))
});

class ChatBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      video: {},
      isShowFreeDownloadModal: false,
      isShowFreeEmbeddingCodeModal: false,
      isShowThankYouModal: false
    }
  }

  componentDidMount() {
    if (this.state.id) {
      if (this.props.series === undefined) {
        let payload = {};
        payload.endpoint = "gallery/" + this.state.id;
        this.props.loadSeries(payload);
      }
    }
  }

  handler = {
    showFreeDownloadModal: (video) => {
      if (video) {
        this.setState({ video: video, isShowFreeDownloadModal: true });
      }
    },

    closeFreeDownloadModal: (is_show_ty_modal) => {
      this.setState({
        isShowFreeDownloadModal: false,
        isShowThankYouModal: is_show_ty_modal
      });
    },
    showFreeEmbeddingCodeModal: (video) => {
      this.setState({ video: video, isShowFreeEmbeddingCodeModal: true });
    },
    closeFreeEmbeddingCodeModal: () => {
      this.setState({ isShowFreeEmbeddingCodeModal: false });
    },
    showThankYouModal: () => {
      this.setState({ isShowThankYouModal: true });
    },
    closeThankYouModal: () => {
      this.setState({ isShowThankYouModal: false });
    },
    loadSeriesVideos: (series_id) => {
      let payload = {};
      payload.series_id = series_id;
      payload.endpoint = "gallery/" + this.state.id + "/series/" + series_id;
      this.props.loadSeriesVideos(payload);
    }
  }

  render() {

    return (
      <React.Fragment>
        {this.state.isShowThankYouModal ?
          <ThankYouModal
            isOpen={this.state.isShowThankYouModal}
            closed={this.handler.closeThankYouModal} />
          : ""}
        {this.state.isShowFreeDownloadModal ?
          <FreeDownloadModal
            video={this.state.video}
            isOpen={this.state.isShowFreeDownloadModal}
            closed={this.handler.closeFreeDownloadModal} />
          : ""}
        {this.state.isShowFreeEmbeddingCodeModal ?
          <EmbeddingCodeModal
            video={this.state.video}
            isOpen={this.state.isShowFreeEmbeddingCodeModal}
            closed={this.handler.closeFreeEmbeddingCodeModal} />
          : ""}
        <Series
          id={this.state.id}
          series={this.props.series}
          // loadSeriesIDVideos={this.props.loadSeriesIDVideos}
          isSearchHederTextHide={true}
          showPopup={this.handler.showFreeDownloadModal}
          showEmbedingPopup={this.handler.showFreeEmbeddingCodeModal}
          loadSeriesVideos={this.handler.loadSeriesVideos}
        />

        <Chat />
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBuilder);
