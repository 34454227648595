import React from "react";
import classes from "./FullScreen.module.css";
import Lottie from "react-lottie";
import animationData from "../../../assets/lotties/lf20_xlceleoi.json";

const FullScreen = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={classes.full_screen}>
      <div className={classes.lottie_content}>
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </div>
  );
};

export default FullScreen;
