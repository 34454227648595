import React from "react";
import { Link } from "react-router-dom";
import classes from "./VideoItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const videoItem = (props) => {
  if (!props.thumbnail_video_id || !props.video_id) {
    return (
      <div className={classes.VideoItem}>
        <div className={classes.VideoItemImg}>
          <video />
        </div>
      </div>
    );
  }

  let videoStyle = {};
  let videoParentDivClass = [classes.VideoItemImg];
  let videoClass = "";
  if (!props.is_series_item) {
    if (props.resolutions) {
      let width = props.resolutions.width;
      let height = props.resolutions.height;
      let ration = (height / width) * 100;
      videoStyle.paddingTop = ration + "%";
    }
    videoParentDivClass.push("embed-responsive");
    videoClass = "embed-responsive-item";
  }

  return (
    <div className={classes.VideoItem}>
      <div className={classes.PlayersIcon}>
        <Link to={"/"}>
          <div className={classes.User} title="User">
            {" "}
          </div>
        </Link>
        <Link to={"/"}>
          <div className={classes.Chat} title="Chat"> </div>
        </Link>
        <Link to={"/"}>
          <div className={classes.Share} title="Share">
            {" "}
          </div>
        </Link>
        <Link to={"/chat/" + props.thumbnail_video_id}>
          <div className={classes.Heart} title="Heart">
            {" "}
          </div>
        </Link>
        <Link to={"/"}>
          <div className={classes.Music} title="Music">
            {" "}
          </div>
        </Link>
      </div>
      {props.is_sponser ? (
        ""
      ) : (
        <p
          className={
            props.is_series_item ? classes.VedioTopSeries : classes.VedioTop
          }
          title={props.name}
        >
          {props.name}
        </p>
      )}

      <div className={videoParentDivClass.join(" ")} style={videoStyle}>
        <video
          className={videoClass}
          id={props.video_id}
          preload={"none"}
          poster={props.poster_link}
          src={props.file_link}
          // onMouseOver={event => event.target.play()}
          // onMouseOut={event => event.target.pause()}
          onMouseOver={props.onMouseOver}
          onMouseOut={props.onMouseOut}
          onClick={props.is_series_item ? props.loadSeries : ""}
        />
      </div>
      {props.is_sponser ? (
        ""
      ) : (
        <div
          className={
            props.is_series_item
              ? classes.VideoDescriptionSeries
              : classes.VideoDescription
          }
        >
          {!props.is_series_item &&
          props.totalVideos &&
          props.totalVideos > 1 ? (
            <Link
              target={props.is_series_item ? "_self" : "_blank"}
              to={"/series/" + props.thumbnail_video_id}
              className={classes.SeriesLink}
            >
              Check series range
            </Link>
          ) : (
            <React.Fragment>
              <span onClick={props.showPopup}>
                Free <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>{" "}
                Download
              </span>
              <span onClick={props.showEmbedingPopup}>Free EmbeddingCode</span>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};
export default videoItem;
