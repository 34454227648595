import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';

import classes from './ContactUs.module.css';

const contactUs = (props) => {

  return (
    <div className={classes.ContactUs}>
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <h1>ContactUs</h1>
          <h3>This is Contact Us Page!</h3>
        </Col>
      </Row>
    </div>
  )
}


export default contactUs;
