import React, { useState } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem

} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import classes from './Gallery.module.css';
import SearchHeader from 'components/Gallery/SearchHeader/SearchHeader';

const Gallery = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <div className={classes.Gallery}>
      <Row className={classes.Norow}>
        <Col sm="12" md="12" lg={{ size: 8, offset: 2 }}>
          <SearchHeader />
          <Row className={[classes.Norow, classes.Mgbsm15].join(" ")}>
            <Col sm="6" md={{ size: 2 }}>

              <Input type="select" name="select" id="exampleSelect" className={classes.FilterOpt}>
                <option className={classes.Option}></option>
                <option className={classes.Option}>Filter option 1</option>
                <option className={classes.Option}>Filter option 2</option>
                <option className={classes.Option}>Filter option 3</option>
                <option className={classes.Option}>Filter option 4</option>
                <option className={classes.Option}>Filter option 5</option>
              </Input>

            </Col>
            <Col sm="6" md={{ size: 2 }}>

              <Input type="select" name="select" id="exampleSelect1" className={classes.FilterOpt}>
                <option className={classes.Option}></option>
                <option className={classes.Option}>Filter option 1</option>
                <option className={classes.Option}>Filter option 2</option>
                <option className={classes.Option}>Filter option 3</option>
                <option className={classes.Option}>Filter option 4</option>
                <option className={classes.Option}>Filter option 5</option>
              </Input>

            </Col>
            <Col sm="6" md={{ size: 2 }}>

              <Input type="select" name="select" id="exampleSelect2" className={classes.FilterOpt}>
                <option className={classes.Option}></option>
                <option className={classes.Option}>Filter option 1</option>
                <option className={classes.Option}>Filter option 2</option>
                <option className={classes.Option}>Filter option 3</option>
                <option className={classes.Option}>Filter option 4</option>
                <option className={classes.Option}>Filter option 5</option>
              </Input>

            </Col>
            <Col sm="6" md={{ size: 2 }}>

              <Input type="select" name="select" id="exampleSelect3" className={classes.FilterOpt}>
                <option className={classes.Option}></option>
                <option className={classes.Option}>Filter option 1</option>
                <option className={classes.Option}>Filter option 2</option>
                <option className={classes.Option}>Filter option 3</option>
                <option className={classes.Option}>Filter option 4</option>
                <option className={classes.Option}>Filter option 5</option>
              </Input>

            </Col>
            <Col sm="6" md={{ size: 2 }}>

              <Input type="select" name="select" id="exampleSelect4" className={classes.FilterOpt}>
                <option className={classes.Option}></option>
                <option className={classes.Option}>Filter option 1</option>
                <option className={classes.Option}>Filter option 2</option>
                <option className={classes.Option}>Filter option 3</option>
                <option className={classes.Option}>Filter option 4</option>
                <option className={classes.Option}>Filter option 5</option>
              </Input>

            </Col>
            <Col sm="6" md={{ size: 2 }}>

              <Button className={classes.ResetBtn}>Reset</Button>

            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={classes.Norow}>
        <Col sm="12" md={{ size: 2 }} className="position-relative">
          {/* <select name="select" id="exampleSelect5" className={classes.FilterSlt} >
            <option>Filter</option>
            <option>New </option>
            <option>Single video</option>
            <option>Videos from series</option>
            <option>Vertical resolution</option>
            <option>Horizontal resolution</option>
          </select> */}

          <Dropdown isOpen={dropdownOpen} toggle={toggle} className={classes.FilterOpt2}>
            <DropdownToggle caret
              tag="span"
              data-toggle="dropdown"
              aria-expanded={dropdownOpen}
            >
              Filter
      </DropdownToggle>
            <DropdownMenu className={classes.FilterDropmenu}>
              <DropdownItem>New <FontAwesomeIcon className={classes.CogIcon} icon={faCog}></FontAwesomeIcon></DropdownItem>
              <DropdownItem>Single video</DropdownItem>
              <DropdownItem>Videos from series</DropdownItem>
              <DropdownItem>Vertical resolution</DropdownItem>
              <DropdownItem>Horizontal resolution</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  )
}


export default Gallery;
