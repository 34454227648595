import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { Table, Button } from "reactstrap";

import StyledEditText from "./StyledEditText";

import classes from "./MyVideos.module.css";
import httpService from "services/httpService";
import { useTable, useColumnOrder } from "react-table";
import { createDndContext, DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { DRow } from "./DRow";

const MyAlbumDetails = ({ album_detail_id, album_id, handler }) => {
  const [videos, setVideos] = useState([]);
  const [series, setSeries] = useState([]);
  const [addLabel, setAddLabel] = useState("Click to add Series");
  const [album, setAlbum] = useState([]);
  const [ui, setUi] = useState({});
  const [test, setTest] = useState(false);

  const refreshData = () => {
    httpService
      .get(`/gallery/${album_id}/`)
      .then((res) => {
        setSeries(res.data);
        const sm = {};
        for (const v of res.data.results) {
          sm[v.id] = false;
        }
        setUi(sm);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const columns = useMemo(
    () => [
      // { Header: "Id", accessor: "id" },
      { Header: "Thumbnail", accessor: "video" },
      { Header: "Series", accessor: "name" },
      { Header: "Upload Date", accessor: "date" },
      // { Header: "Status", accessor: "status" },
      // { Header: "Drag", accessor: "grip" },
    ],
    []
  );

  // const data = React.useMemo(() => makeData(20), []);
  const data = useMemo(() => {
    const vlist = series.series;
    return vlist
      ? vlist.map((s) => {
          return {
            id: s.id,
            video: (
              <img
                src={series.image}
                className="img-fluid"
                width="50"
                height="50"
                alt="IMG"
              />
            ),
            name: (
              <Button
                onClick={() =>
                  handler("SERIESDETAIL", {
                    album_id: album_id,
                    series_id: s.id,
                  })
                }
              >
                {s.name}
              </Button>
            ),
            date: "now",
          };
        })
      : [];
  }, [videos.results, series, ui]);

  const DTable = ({ columns, data }) => {
    // console.log(columns, data);
    const manager = useRef(createDndContext(HTML5Backend));
    const [records, setRecords] = useState(data);
    const getRowId = useCallback((row) => {
      return row.id;
    }, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ data: records, columns, getRowId }, useColumnOrder);

    const moveRow = (dragIndex, hoverIndex) => {
      const dragRecord = records[dragIndex];
      console.log(dragIndex, hoverIndex);

      setRecords(
        update(records, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      );
    };
    // console.log(`rows=${JSON.stringify(rows)}`);
    console.log(rows);
    // saveOrder(rows.map((r) => r.id));

    return (
      <DndProvider manager={manager.current.dragDropManager}>
        <Table striped className={classes.MyvideoTB} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th>Drag</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, index) =>
                prepareRow(row) || (
                  <DRow
                    index={index}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                  />
                )
            )}
          </tbody>
        </Table>
      </DndProvider>
    );
  };

  const saveOrder = (ordered) => {
    if (videos.videos) {
      const original = videos.videos.map((v) => v.id);
      if (JSON.stringify(ordered) != JSON.stringify(original)) {
        httpService
          .post(`gallery/series/${videos.videos[0].series[0].id}/`, {
            ordered: ordered,
          })
          .then((res) => {
            console.log(res);
            // setVideos(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleSave = (val) => {
    console.log(`Value=${val}`);
    httpService
      .post(`gallery/${album_detail_id}/`, {
        addSeries: val,
      })
      .then((res) => {
        console.log(res);
        refreshData();
        setAddLabel("Click to add Series");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(series.series);
  return (
    <div>
      <div>
        <span className="mr-3">
          {/* <span>Series</span> */}
          {}
          <StyledEditText
            editOnViewClick={true}
            submitOnEnter={true}
            showButtonsOnHover={true}
            cancelOnEscape={true}
            // className={classes.HeadSeries}
            type="text"
            value={addLabel}
            onSave={handleSave}
          />
        </span>
      </div>
      <DTable columns={columns} data={data} saveOrder={saveOrder} />
    </div>
  );
};

export default MyAlbumDetails;
