import React from 'react';
import classes from './FooterToolbar.module.css';
import FooterItems from './FooterItems';
import FooterHeader from './FooterHeader';

const FooterToolbar = (props) => {
  return (
    <div className={classes.FooterToolbar}>
      <FooterHeader />
      <FooterItems />
    </div>
  )
}

export default FooterToolbar;
