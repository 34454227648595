import React from 'react';
import classes from "./MetaInfoHelper.module.css";
import { css } from "emotion";
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import history from "./history";

const field1 = css`
    display: contents;
    align-items: center;
    justify-content: space-around;
    padding:10px;
`;
const labelStyle = css`
  display: flex;
  align-items: center;
`;
const fontstyle = css`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #b5b3b7;
      &:checked {
    background-color: #ffffff;
    border: 2px solid #0ce40c;
    font-color:blue;
    &:after {
      display: block;
    }
  }
`;

class Event extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedValue: '',
      prevflag: false,
      nextflag: false
      // setSelectedValue: []
    }
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleclick1 = this.handleclick1.bind(this);
  }
  componentDidMount() {
    $("#timelineBadge1").css({ "--customback": "#27da11" })
    $("#nexticon1").addClass(classes.nexticon);
    $("#eventd").show();
  }
  handleChange1 = (event) => {
    this.setState({
      selectedValue: event.target.value
    }, ()=>{
      this.props.setNext(3);
    });
    
  };
  handleclick1() {
    if (this.state.selectedValue != '') {
      history.push("/user/choosecategorypivate");
    }
  }
  componentDidUpdate() {
    this.updatetimline();
  }
  updatetimline() {
    if (this.state.selectedValue != '') {
      $("#timelinePanel2").addClass(classes.timelinePane2css);
      $("#timelinePanel2").addClass(classes.timelinePane2cssafter);
      $("#timelineBadge1").css({ "--customback": "#27da11" })
      $("#timelineBadge2").css({ "--customback": "#a39d9d" })
      $("#timelineTitle2").css({ "color": "#ca0dd7" });
      $("#nexticon1").css({ "border-top-color": "#0be830" });
    } else {
      $("#nexticon1").css({ "border-top-color": "#b9bcbf" });
    }

  }

  render() {
    return (
      <div className={field1} id="eventd">
      
        <div>
          <label className={labelStyle}>
            <input
              type="radio"
              checked={this.state.selectedValue === 'Event'}
              onChange={this.handleChange1}
              value="Event"
              className={classes.input}
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Event' }}
            />
            <span className={fontstyle}>Event</span>
          </label>
        </div>
        <div>
          <label className={labelStyle}>
            <input
              type="radio"
              checked={this.state.selectedValue === 'Generic'}
              onChange={this.handleChange1}
              value="Generic"
              className={classes.input}
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Generic' }}
            />
            <span className={fontstyle}>Generic</span>
          </label>
        </div>
       
      </div>
    );
  }
}
export default withRouter(Event);