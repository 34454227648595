import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';

import classes from './NavItem.module.css';

const navItem = (props) => {
  let naveItemClasses = [];
  naveItemClasses.push('nav-link');
  if (props.isActive === true) {
    // naveItemClasses.push('active');
  }
  naveItemClasses = naveItemClasses.join(" ");

  return (
    <NavItem className={classes.NavItem}>
      {
        props.clicked ?
          <span key={props.link} className="nav-link" onClick={props.clicked}>{props.children}</span>

          :
          <NavLink
            key={props.link}
            className={naveItemClasses}
            exact
            to={props.link}
            activeClassName={classes.active}
          >
            {props.children}
          </NavLink>
      }
    </NavItem>
  )
}
export default navItem
