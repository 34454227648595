import React from "react";
import { Col } from "reactstrap";
import SearchInput from "components/UI/SearchInput/SearchInput";
import classes from "./Imprint.module.css";
import ImprintandtermsNav from "./ImprintandtermsNav";

const imprintandTerms = (props) => {
  return (
    <div className={classes.ContributorGuideline}>
      <Col className={classes.PaddingTop} sm="12" md={{ size: 8, offset: 2 }}>
        <SearchInput />
      </Col>
      { <ImprintandtermsNav /> }
    </div>
  );
};
export default imprintandTerms;
