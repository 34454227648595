
import './app.css';
import * as React from 'react';
import { getData } from './data';
import classes from "./MetaInfoHelper.module.css";
import $ from 'jquery';
import { css } from "emotion";
import history from "./history";
import httpService from "../../../services/httpService";
import AppContext from "../../../contexts/AppContext";
import FilterableList from './FilterableList';

const field1 = css`
display: contents;
align - items: center;
justify - content: space - around;
padding: 10px;
`;
export default class Choosevideoenvironment extends React.Component {
    static contextType=AppContext;
    constructor(props) {
        super(props);
        this.state = {
            catedata: getData(),
            checkedOptionValue: ""
        };
        this.handleclick1 = this.handleclick1.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }


    componentDidMount() {
        $("#timelineBadge6").css({ "--customback": "#27da11" })
        $("#timelineBadge1").css({ "--customback": "#27da11" })
        $("#nexticon6").addClass(classes.nexticon);
        $("#choosevideoenviorment").show();
        httpService.get("/gallery/environments").then(resp=>{
            this.setState({
              catedata: resp.data.map(category=>{
                return{
                  country: category.title,
                  subs: category.sub_category
                }
              })
            },()=>{
                if (this.context.metaPickerState.Environment) {
                  this.setState({
                      catedata: this.state.catedata,
                      ...this.context.metaPickerState.Environment
                  });
                }
            })
        })
    }
    componentDidUpdate() {
        this.updatetimline();

    }
    handleRadioChange(e) {
        this.setState({ checkedOptionValue: e.target.value }, ()=>{
            this.context.setMetaPickerState({
                Environment:{
                    ...this.state
                }
            })
            this.props.setNext(9);
        });
    }
    handleclick1() {
        if (this.state.checkedOptionValue != "") {
            history.push("/user/Videosymbols");
        }
    }
    updatetimline() {
        if (this.state.checkedOptionValue != "") {
            $("#timelinePanel7").addClass(classes.newtimlepaintl1);
            $("#timelinePanel7").addClass(classes.newtimlepaintl2);
            $("#timelineBadge7").css({ "--customback": "#a39d9d" })
            $("#timelineTitle7").css({ "color": "red" });
            $("#nexticon6").css({ "border-top-color": "#0be830" });

        } else {
            $("#nexticon6").css({ "border-top-color": "#b9bcbf" });
        }
    }
    render() {
        return (
          <div className={field1} id="choosevideoenviorment">
            <FilterableList
              heading="Choose category"
              items={this.state.catedata}
              checkedValue={this.state.checkedOptionValue}
              handleChange={this.handleRadioChange}
              valueLabel
            />
          </div>
        );
    }
}