
import './app.css';
import * as React from 'react';
import { getData } from './data';
import classes from "./MetaInfoHelper.module.css";
import $ from 'jquery';
import { MDBCol, MDBIcon } from "mdbreact";
import { css } from "emotion";
import history from "./history";
import httpService from "../../../services/httpService";
const field1 = css`
display: contents;
align - items: center;
justify - content: space - around;
padding: 10px;
`;
export default class PivateCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedOptionValue: "",
      checkedOptionValue1: "",
      catedata: getData(),
    };
    this.handleclick1 = this.handleclick1.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }
  componentDidMount() {
    $("#timelineBadge1").css({ "--customback": "#27da11" })
    $("#timelineBadge2").css({ "--customback": "#27da11" })
    $("#nexticon2").addClass(classes.nexticon);
    $("#privatecatecory").show();
    httpService.get("/gallery/categories").then(resp=>{
      this.setState({
        catedata: resp.data.map(category=>{
          return{
            country: category.title,
            subs: category.sub_category
          }
        })
      })
    })
  }
  handleclick1() {
    if (this.state.checkedOptionValue != "") {
      history.push("/user/Addsubcategory");
    }
  }
  componentDidUpdate() {
    this.updatetimline();
  }
  handleRadioChange(e) {
    this.setState({ checkedOptionValue: e.target.value }, ()=>{
      this.props.setNext(5);
    });
  }

  updatetimline() {
    if (this.state.checkedOptionValue != "") {
      $("#timelinePanel3").addClass(classes.newtimlepaintl1);
      $("#timelinePanel3").addClass(classes.newtimlepaintl2);
      $("#timelineBadge1").css({ "--customback": "#27da11" });
      $("#timelineBadge3").css({ "--customback": "#a39d9d" })
      $("#timelineTitle3").css({ "color": "red" });
      $("#nexticon2").css({ "border-top-color": "#0be830" });

    } else {
      $("#nexticon2").css({ "border-top-color": "#b9bcbf" });
    }

  }
  render() {
    return (
      <div className={field1} id="privatecatecory">
     
        <div className="contain">
          <div className="container-fluid">
            <div className="filed">
              <MDBCol md="7" className="searchcategory">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <div className="input-group-prepend">
                    <span className="input-group-text purple lighten-3" id="basic-text1">
                      <MDBIcon className="text-white" icon="search" />
                    </span>
                  </div>
                  <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" />
                </div>
              </MDBCol>
              <div className="headerspell2">
                <span>Choose category</span>
              </div>
              <div className="col-xs-5" id="radioviewprivete">
                {this.state.catedata.map((option, i) => (
                  <div key={i} className="radioitem">
                    <input
                      type="radio"
                      name="site_name"
                      value={option.country}
                      className="radiobutton"
                      checked={this.state.checkedOptionValue === option.country}
                      onChange={this.handleRadioChange}
                    />
                    <label className="radiolabel">{option.country}</label>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
        
      </div>
    )
  }
}