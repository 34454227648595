

import './app.css';
import * as React from 'react';
import classes from "./MetaInfoHelper.module.css";
import $ from 'jquery';
import { MDBCol, MDBIcon } from "mdbreact";
import { css } from "emotion";
import history from "./history";
import imagedram from "../../../assets/darm.png";
import httpService from "../../../services/httpService";
import AppContext from "../../../contexts/AppContext";


const field1 = css`
display: contents;
align - items: center;
justify - content: space - around;
padding: 10px;
`;
export default class Videosymbols extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.state = {
            checkedOptionValue1: "",
            checkedOptionValue: "",
            isCherry: false,
            percent: false,
            armreimage: imagedram,
            filterText : ""
        };
        this.handleclick1 = this.handleclick1.bind(this);
        this.onChangeCherry = this.onChangeCherry.bind(this);
        this.onChangepercent = this.onChangepercent.bind(this);
        this.handleRadioChange1 = this.handleRadioChange1.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);

    }

    handleclick1() {
        if ((this.state.checkedOptionValue1 != "" && this.state.checkedOptionValue === "percent") || this.state.checkedOptionValue === "no_symbols") {
            history.push("/user/Greate");
        }
    }
    onChangeCherry() {
        this.setState(initialState => ({
            isCherry: !initialState.isCherry,
        }));
    }
    onChangepercent() {
        this.setState(initialState => ({
            percent: !initialState.percent,
        }));
    }
    handleRadioChange1(e) {
        this.setState({ checkedOptionValue1: e.target.value }, ()=>{
            this.props.setNext(10);
            this.context.setMetaPickerState({
                Symbols: {...this.state}
            })
        })
    }
    handleRadioChange(e) {
        this.setState({ checkedOptionValue: e.target.value, checkedOptionValue1:""}, ()=>{
            if(this.state.checkedOptionValue=="no_symbols" || this.state.checkedOptionValue == "percent"){
                this.props.setNext(10);
            }else{
                this.props.setNext(9);
            }
            this.context.setMetaPickerState({
                Symbols: {...this.state}
            })
        })
    }
    componentDidMount() {
        $("#timelineBadge7").css({ "--customback": "#27da11" })
        $("#timelineBadge1").css({ "--customback": "#27da11" })
        $("#nexticon7").addClass(classes.nexticon);
        $("#videosymbols").show();
        $("#choosevideoenviorment").show();
        httpService.get("/gallery/values").then(resp=>{
            this.setState({
              catedata: resp.data.map(category=>{
                return{
                  country: category.title,
                  subs: category.sub_category
                }
              })
            }, ()=>{
                if (this.context.metaPickerState.Symbols) {
                  this.setState({
                      catedata: this.state.catedata,
                      ...this.context.metaPickerState.Symbols
                  });
                }
            })
        })
    }
    componentDidUpdate() {
        this.updatetimline();
    }
    updatetimline() {
        if ((this.state.checkedOptionValue1 != "" && this.state.checkedOptionValue === "percent") || this.state.checkedOptionValue === "no_symbols") {
            $("#finished").text("PUBLISH").css({ "left": "-76px" });
            $("#timelinePanel8").addClass(classes.timelinePane2css);
            $("#timelinePanel8").addClass(classes.timelinePane2cssafter);
            $("#timelineBadge8").css({ "--customback": "#a39d9d" })
            $("#timelineTitle8").css({ "color": "#f99207" });
            $("#timelineBadge1").css({ "--customback": "#27da11" })
            $("#nexticon7").css({ "border-top-color": "#0be830" });
            localStorage.setItem('armflag', 'on');
            
        } else {
            localStorage.removeItem('armflag');
            $("#nexticon7").css({ "border-top-color": "#b9bcbf" });
        }
        if (this.state.checkedOptionValue === "no_symbols") {
            this.state.checkedOptionValue1 = "";
        }
    }
    render() {
        console.log(this.state.filterText);
        return (
            <div className={field1} id="videosymbols">
               
                <div className="contain">
                    <div className="container-fluid">
                        <div className="filedviode">
                            <div className="headerspellare">
                                <span>Are there any value symbols in your video?</span>
                            </div>
                            <div className="serchvideolanguage">
                                <MDBCol md="7">
                                    <div className="input-group md-form form-sm form-1 pl-1">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text purple lighten-3" id="basic-text1">
                                                <MDBIcon className="text-white" icon="search" />
                                            </span>
                                        </div>
                                        <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" onChange={(e)=>{
                                            this.setState({
                                                filterText: e.target.value
                                            }) 
                                        }}/>
                                    </div>
                                </MDBCol>
                            </div>
                            <div className="categoryfield">
                                <div className="videoheader"><span className="valuespan">Video values Symbols</span></div>
                                <div className="col-xs-5" id="radioviewvideo">
                                    <div className="radioitem">
                                        <input
                                            type="radio"
                                            value="no_symbols"
                                            className="radiobutton"
                                            checked={this.state.checkedOptionValue === "no_symbols"}
                                            onChange={this.handleRadioChange}
                                        />
                                        <label className="radiolabel">No currency symbols</label>
                                    </div>
                                    <div className="radioitem">
                                        <input
                                            type="radio"
                                            value="symbols"
                                            className="radiobutton"
                                            checked={this.state.checkedOptionValue === "symbols"}
                                            onChange={this.handleRadioChange}
                                        />
                                        <label className="radiolabel">Currency symbols</label>
                                    </div>
                                    <div className="radioitem">
                                        <input
                                            type="radio"
                                            value="percent"
                                            className="radiobutton"
                                            checked={this.state.checkedOptionValue === "percent"}
                                            onChange={this.handleRadioChange}
                                        />
                                        <label className="radiolabel">%</label>
                                    </div>
                                    <br />
                                    {this.state.checkedOptionValue === "symbols" && this.state.catedata && 
                                    this.state.catedata.filter(option=>option.country.toLowerCase().indexOf(this.state.filterText.toLowerCase())!==-1).map(c=>{
                                        return <div className="radioitem">
                                        <input
                                            key={c.country}
                                            type="radio"
                                            value={c.country}
                                            className="radiobutton"
                                            checked={this.state.checkedOptionValue1 === c.country}
                                            onChange={this.handleRadioChange1}
                                        />
                                        <label className="radiolabel">{c.country}<span className="afg"></span></label>
                                    </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div >
        )
    }
}