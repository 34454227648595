import React, { Component } from 'react';
import { connect } from 'react-redux';

import Gallery from 'components/Gallery/Gallery'
import VideoItems from 'components/VideoItems/VideoItems';
import FreeDownloadModal from 'components/UI/CustomModals/FreeDownloadModal';
import EmbeddingCodeModal from 'components/UI/CustomModals/EmbeddingCodeModal';
import ThankYouModal from 'components/UI/CustomModals/ThankYouModal';
import { Container } from 'reactstrap';
import classes from 'containers/App.module.css';
// import UploadMore from '../../components/VideoItems/UploadMore';
import Loader from 'components/UI/Loader/Loader';

const mapStateToProps = state => ({
  galleries: state.galleries
});

class GalleryBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: [],
      isShowFreeDownloadModal: false,
      isShowFreeEmbeddingCodeModal: false,
      isShowThankYouModal: false
    }
  }

  handler = {
    showFreeDownloadModal: (video) => {
      this.setState({ video: video, isShowFreeDownloadModal: true });
    },
    closeFreeDownloadModal: (is_show_ty_modal) => {
      this.setState({
        isShowFreeDownloadModal: false,
        isShowThankYouModal: is_show_ty_modal
      });
    },
    showFreeEmbeddingCodeModal: (video) => {
      this.setState({ video: video, isShowFreeEmbeddingCodeModal: true });
    },
    closeFreeEmbeddingCodeModal: () => {
      this.setState({ isShowFreeEmbeddingCodeModal: false });
    },
    showThankYouModal: () => {
      this.setState({ isShowThankYouModal: true });
    },
    closeThankYouModal: () => {
      this.setState({ isShowThankYouModal: false });
    },
  }

  render() {
    // if (!this.props.galleries) {
    //   return null;
    // }

    return (
      <React.Fragment>
        {this.state.isShowThankYouModal ?
          <ThankYouModal
            isOpen={this.state.isShowThankYouModal}
            closed={this.handler.closeThankYouModal} />
          : ""}
        {this.state.isShowFreeDownloadModal ?
          <FreeDownloadModal
            video={this.state.video}
            isOpen={this.state.isShowFreeDownloadModal}
            closed={this.handler.closeFreeDownloadModal} />
          : ""}
        {this.state.isShowFreeEmbeddingCodeModal ?
          <EmbeddingCodeModal
            video={this.state.video}
            isOpen={this.state.isShowFreeEmbeddingCodeModal}
            closed={this.handler.closeFreeEmbeddingCodeModal} />
          : ""}
        <Container className={classes.Mycontainer}>
          <Gallery />
          <VideoItems
            showPopup={this.handler.showFreeDownloadModal}
            showEmbedingPopup={this.handler.showFreeEmbeddingCodeModal} />
          <Loader loading={(this.props.galleries === undefined || this.props.galleries.next) ? true : false} />
          {/* <UploadMore /> */}
        </Container>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(GalleryBuilder);