import React, { useState } from "react";

import MyAlbums from "./MyAlbums";
import MyAlbumDetails from "./MyAlbumDetails";
import MySeries from "./MySeries";

const MyVideos = (props) => {
  const handler = (type, meta) => {
    let admin = null;
    console.log(type, meta);
    if (type === "ALBUMDETAILS") {
      admin = (
        <MyAlbumDetails
          album_detail_id={meta.album_detail_id}
          album_id={meta.album_id}
          handler={handler}
        />
      );
    }
    if (type == "SERIESDETAIL") {
      admin = (
        <MySeries
          series_id={meta.series_id}
          album_id={meta.album_id}
          handler={handler}
        />
      );
    }
    admin && setAdminType(admin);
  };
  const [adminType, setAdminType] = useState(<MyAlbums handler={handler} />);
  // const adminTypes = [ALBUMS, ALBUMDETAILS];

  return adminType;
};

export default MyVideos;
