import React, { Component } from "react";

import Page from "components/Page/Page";
import About from "components/Page/About/About";
import ImprintandTerms from "components/Page/ImprintandTerms/ImprintandTerms";
import ContactUs from "components/Page/ContactUs/ContactUs";
import LicensePage from "components/Page/LicensePage/LicensePage";
import ContributorGuideline from "components/Page/ContributorGuideline/ContributorGuideline";
import Collaborations from "components/Page/Collaborations/Collaborations";
import FaqPage from "components/Page/FaqPage/FaqPage.jsx";
import FaqClient from "components/Page/FaqClient/FaqClient.jsx";
//import FaqContributor from "components/Page/FaqContributor/FaqContributor.jsx";

import {
  ABOUT,
  CONTACT_US,
  LICENSE,
  CONTRIBUTOR_GUIDELINE,
  COLLABORATIONS,
  FAQ,
  FAQCLIENT,
  IMPRINT_AND_TERMS,
  USER_TYPE,
  //FAQ_CONTRIBUTOR,
} from "../AppConstant";
import UserType from "../../components/Page/UserType/UserType";

class PageBuilder extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    switch (this.props.type) {
      case FAQ:
        return <FaqPage />;
      case ABOUT:
        return <About />;
      case CONTACT_US:
        return <ContactUs />;
      case LICENSE:
        return <LicensePage />;
      case IMPRINT_AND_TERMS:
        return <ImprintandTerms />;
      case CONTRIBUTOR_GUIDELINE:
        return <ContributorGuideline />;

      case COLLABORATIONS:
        return <Collaborations />;
      case FAQCLIENT:
        return <FaqClient />;
      // case FAQ_CONTRIBUTOR:
      //   return <FaqContributor />;
      case USER_TYPE:
        return <UserType />;
      default:
        return (
          <Page
            title={this.props.type}
            content={"This is " + this.props.type + " Page!"}
          />
        );
    }
  }
}

export default PageBuilder;
