import React from 'react';
import { FormGroup, Input, CustomInput } from 'reactstrap';

import classes from './Preferences.module.css';

const preferences = (props) => {

  return (
    <div className={classes.Preferences}>
      <div className={classes.PreferHead}><p>Preferences</p></div>
      <div className={classes.Line1}>
        <div className={classes.part1}><span>!</span></div>
        <div className={classes.part2}> <FormGroup>
          <Input type="select" name="select" id="exampleSelect" className={classes.MySelect}>
            <option>Set your default for online/offline site status</option>
            <option>2</option>

          </Input>
        </FormGroup>
        </div>
        <div className={classes.OnOff}>
          <span className={classes.On}>On</span> <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" className={classes.MySwitch} /><span className={classes.Off}>Off</span>
        </div>

      </div>
      <div className={classes.Line1}>
        <div className={classes.part1}><span>!</span></div>
        <div className={classes.part2}> <FormGroup>
          <Input type="select" name="select" id="exampleSelect" className={classes.MySelect}>
            <option>Set your default for online/offline chat status</option>
            <option>2</option>

          </Input>
        </FormGroup>
        </div>
        <div className={classes.OnOff}>
          <span className={classes.On}>On</span> <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" className={classes.MySwitch} /><span className={classes.Off}>Off</span>
        </div>

      </div>
      <div className={classes.Line1}>
        <div className={classes.part1}><span>!</span></div>
        <div className={classes.part2}> <FormGroup>
          <Input type="select" name="select" id="exampleSelect" className={classes.MySelect}>
            <option>Choose account global time zone</option>
            <option>2</option>

          </Input>
        </FormGroup>
        </div>
        {/* <div className={classes.OnOff}>
        <span className={classes.On}>On</span> <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" className={classes.MySwitch} /><span className={classes.Off}>Off</span>
      </div>  */}

      </div>

    </div>
  )
}


export default preferences;
