import React from "react";
import { Row, Col } from "reactstrap";

import classes from "./BlogDetail.module.css";
import SearchHeader from "components/Gallery/SearchHeader/SearchHeader";
import BlogBanner from "../BlogBanner/BlogBanner";
import Demo from "assets/Blog/demo.png";

const blogDetail = (props) => {
  return (
    <div className={classes.BlogDetail}>
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <SearchHeader
          isSearchHederTextHide={true}
          isRoyaltyFreeTextHide={true}
        />
      </Col>
      <BlogBanner />
      <div className={classes.Headings}>
        <h1>HeadlIne will be here</h1>
        <h4>Sub HeadlIne will be here and all </h4>
      </div>
      <div className={classes.Detailing}>
        <Row className="ml-0 mr-0  mb-4 p-4">
          <Col sm="12" md={{ size: 6 }} className="text-center">
            <img src={Demo} alt="IMG" className="img-fluid w-100" />
          </Col>
          <Col sm="12" md={{ size: 6 }} className="pt-5 pb-5">
            <p className={classes.Description}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel dolor in hendrerit in
              vulputate velit esse molestie consequat, vel{" "}
            </p>
            <p className={classes.Description}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel dolor in hendrerit in
              vulputate velit esse molestie consequat, vel{" "}
            </p>
            <p className={classes.Description}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel dolor in hendrerit in
              vulputate velit esse molestie consequat, vel{" "}
            </p>
          </Col>
          <div className="text-center p-4">
            {" "}
            <p className={classes.Description}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel dolor in hendrerit in
              vulputate velit esse molestie consequat, vel{" "}
            </p>
          </div>
        </Row>
        <Row className="ml-0 mr-0  mb-4">
          <Col sm="12" md={{ size: 4 }} className="text-center">
            <img src={Demo} alt="IMG" className="img-fluid w-100" />
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </Col>
          <Col sm="12" md={{ size: 4 }} className="text-center">
            <img src={Demo} alt="IMG" className="img-fluid w-100" />
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </Col>
          <Col sm="12" md={{ size: 4 }} className="text-center">
            <img src={Demo} alt="IMG" className="img-fluid w-100" />
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default blogDetail;
