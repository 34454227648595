import React from 'react';

import classes from './GeneralStatus.module.css';

const generalStatus = (props) => {

     return (
          <div className={classes.GeneralStatus}>
               <div className={classes.Roundover}>
                    <div className={classes.Circle}>
                         <p> 60 </p>
                         <p> Active </p>
                         <p> Videos </p>
                    </div>
                    <div className={classes.Circle}>
                         <p> 1,824</p>
                         <p> Total </p>
                         <p> Downloads </p>
                    </div>
                    <div className={classes.Circle}>
                         <p> 58$ </p>
                         <p> Total </p>
                         <p> Earnings</p>
                    </div>
                    <div className={classes.Circle}>
                         <p> 12 </p>
                         <p> Total </p>
                         <p> Leads</p>
                    </div>
               </div>
          </div>
     )
}


export default generalStatus;
