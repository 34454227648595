import React, { useState } from 'react';
import {
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  Dropdown
} from 'reactstrap';

import classes from './Chat.module.css';
import UserProfileImg from 'components/UI/UserProfileImg/UserProfileImg';

const Chat = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <div className={classes.Chat}>
      <Row className={[classes.Norow, classes.ChatBox].join(" ")}>
        <Col sm="12" md={{ size: 8 }}>
          <div className={classes.ChatLeft}>
            <div className={classes.ChatHead}><p className={classes.Refresh}><i className="fas fa-redo-alt"></i></p> <p>You are chatting now with - <span className={classes.Name}>{localStorage.getItem("__vz__user")} </span></p><p><span className={classes.Windowicon}><i className="fas fa-minus"></i></span><span className={classes.Windowicon}><i className="fas fa-times"></i></span></p></div>
            <Row>
              <Col sm="12" md={{ size: 2 }}>
                <div className={classes.MeProfile}>
                  <span className={classes.ActiveModeMe}><i className="fas fa-circle"></i></span><UserProfileImg />
                  <p>Lior Bazak and Lior Bazak Lior Bazak</p></div>
              </Col>
              <Col sm="12" md={{ size: 3 }} className="p-md-0">
                <div className={classes.Contact}>
                  <div className={classes.ContactHead}>
                    <span><i className="fas fa-user-friends"></i></span> <span><i className="far fa-comments"></i></span><span className={classes.Setting}><i className="fas fa-cog"></i></span>
                  </div>
                  <div className={classes.ChatListing}>
                    <div>
                      <ul>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                        <li><span className={classes.ActiveMode}><i className="fas fa-circle"></i></span><span><UserProfileImg /></span>
                          <span> kjjkdfjsk ksdmjsk</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="12" md={{ size: 7 }}>
                <div className={classes.History}>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret
                      tag="span"
                      data-toggle="dropdown"
                      aria-expanded={dropdownOpen}
                    >
                      History
      </DropdownToggle>
                    <DropdownMenu className={classes.HistorDrop}>
                      <div onClick={toggle} className={classes.DropdownItem}>Custom dropdown item</div>
                      <div onClick={toggle} className={classes.DropdownItem}>Custom dropdown item</div>
                      <div onClick={toggle} className={classes.DropdownItem}>Custom dropdown item</div>
                      <div onClick={toggle} className={classes.DropdownItem}>Custom dropdown item</div>
                    </DropdownMenu>
                  </Dropdown></div>
                <div className={classes.ChattingUpper}>

                  <div className={classes.ChattingBox}>

                    <div className={classes.MsgBlockright}>
                      <p className={classes.Msgtime}> <span>11/5/2020</span><span>10:30am</span></p>
                      <p className={classes.Me}>me</p>
                      <p className={classes.GoingFirst}>Hi</p>
                    </div>
                    <div className={classes.MsgBlockleft}>
                      <p className={classes.Msgtime}> <span>11/5/2020</span><span>10:30am</span></p>
                      <p className={classes.Me}>me</p>
                      <p className={classes.ComingFirst}>Helo Lior</p>

                    </div>
                    <div className={classes.MsgBlockright}>
                      <p className={classes.Msgtime}> <span>11/5/2020</span><span>10:30am</span></p>
                      <p className={classes.Me}>me</p>
                      <p className={classes.GoingFirst}>Hi</p>
                    </div>
                    <div className={classes.MsgBlockleft}>
                      <p className={classes.Msgtime}> <span>11/5/2020</span><span>10:30am</span></p>
                      <p className={classes.Me}>me</p>
                      <p className={classes.ComingFirst}>Helo Lior</p>

                    </div>
                    <div className={classes.MsgBlockright}>
                      <p className={classes.Msgtime}> <span>11/5/2020</span><span>10:30am</span></p>
                      <p className={classes.Me}>me</p>
                      <p className={classes.GoingFirst}>Hi</p>
                    </div>
                    <div className={classes.MsgBlockleft}>
                      <p className={classes.Msgtime}> <span>11/5/2020</span><span>10:30am</span></p>
                      <p className={classes.Me}>me</p>
                      <p className={classes.ComingFirst}>Helo Lior</p>

                    </div>

                    <div>

                    </div>
                  </div>
                </div>
                <div className={classes.Dropmsg}>Write Your Message here</div>
                <div className={classes.SendSec}><p><span><i className="fas fa-file-alt"></i></span><span><i className="far fa-smile"></i></span><span>Paste Screenshot</span></p>
                  <p><button className={classes.Sendbtn}>Send</button></p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12" md={{ size: 3 }}>
          <div className={classes.ChatRight}>
            right deiv
          </div>
        </Col>
      </Row>
    </div>
  )
}


export default Chat;
