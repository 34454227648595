import React from 'react';
import { Col } from 'reactstrap';
import SearchHeader from 'components/UI/SearchInput/SearchInput';
import classes from './LicensePage.module.css';
import BannerImg from '../../../assets//legal_simplicity_photo.jpg';

const licensePage = (props) => {

  return (
    <div className={classes.LicensePage}>

      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <SearchHeader isSearchHederTextHide={true} isRoyaltyFreeTextHide={true} />
      </Col>
      <div className={classes.ImgBanner}> <img src={BannerImg} alt="IMG" />
        <div className={classes.Imgtext}>
          <h1>Friendly <br></br>Legel Terms</h1>

          <h3>All Videos on VideoBzz can be<br></br>downloaded/embedded and used for free</h3>
        </div></div>


      <Col sm="12" md={{ size: 6, offset: 3 }}>
        <div className={classes.LicContent}> <h4>What is allowed?</h4>
          <p>VideoBzz license is the most friendly, generous, and straightforward License as possible. </p>
          <h5>Check it out</h5>
          <ul className={classes.Check}>
            <li>All videos on VideoBzz are free to use.</li>
            <li>For all videos without soundtrack, attribution is not required.</li>
            <li>Giving credit to the photographer or Video price signs is not necessary but always appreciated.
           <br></br>
           You can modify videos from Video price signs. Be creative and edit them as you like as a whole or part of your work.
           </li>
          </ul>
        </div>

        <div className={classes.Soundtrack}>
          <h5>Videos with soundtrack</h5>
          <ul>
            <li>Videos with soundtrack versions require license attribution in some cases, which means the creator of the soundtrack
            wants to get credit for his work; however, all tracks offered in the library are Royalty-Free!
        <br></br> You can always check the License in the "series page" of the video design you have chosen, to be sure.
         </li>
            <li>For all videos without soundtrack, attribution is not required. </li>
          </ul>
        </div>
        <div className={classes.Impotant}>
          <h6>Important Note: </h6>
          <ul>
            <li>VideoBzz made sure to provide all videos with soundtrack also available without the soundtrack for your convenience.
            You can modify videos from VideoBzz. Be creative and edit them as you like as a whole or part of your work.
       </li>
          </ul>
        </div>
        <div className={classes.Promote}>
          <h5>Promote your products and services freely!</h5>
          <ul>
            <li>Create unique ads, banners, and marketing campaigns with photos from VideoBzz and promote your product.
            On your website, blog or app
         </li>
            <li>Use the videos online – whether it's a website, e-commerce shop, newsletter, e-book, presentation, blog, or a template
            you sell.
         </li>
          </ul>
        </div>
        <div className={classes.Share}>
          <h5>Share them on social media</h5>
          <ul>
            <li>Grow your audience by posting authentic and engaging photos and videos on social media like Facebook, Instagram, or YouTube.
         </li>
          </ul>
        </div>
        <div className={classes.Print}>
          <h5>Print marketing material</h5>
          <ul>
            <li>Use the photos for flyers, postcards, invitations, magazines, albums, books, CD covers, and more </li>
          </ul>
        </div>
        <div className={classes.Notallowed}>
          <h5>What is not allowed?</h5>
          <ul>
            <li>Respect the hard work of our contributors and keep these restrictions in mind </li>

            <li>Identifiable people may not appear in a bad light or in an offensive way</li>
            <li>Don't sell unaltered copies of a photo or video, e.g., as a poster, print, <br></br>or on a physical product without modifying it first</li>
            <li>Don't imply endorsement of your product by people or brands on the imagery.</li>
            <li>Don't redistribute or sell the photos and videos on other stock photo or wallpaper platforms.</li>
          </ul>
        </div>
      </Col>

    </div>
  )
}


export default licensePage;
