import React, { useEffect, useState } from "react";
import { Col, Container, Button, Input, Label, Row, Table } from "reactstrap";
import httpService from "services/httpService";
import classes from "./MetaInfoHelperOriginal.module.css";
import Greenicon from "assets/ContriAdmin/Greencheck.png";
import Grayicon from "assets/ContriAdmin/Icongray.png";

export default function MetaInfoHelper({ selectedList, handler }) {
  const [categories, setCategories] = useState([]);
  const [methods, setMethods] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [values, setValues] = useState([]);

  const [first, setFirst] = useState(-1);
  const [second, setSecond] = useState(-1);
  const [category, setCategory] = useState(-1);
  const [subCategory, setSubCategory] = useState(-1);
  const [method, setMethod] = useState(-1);
  const [language, setLanguage] = useState(-1);
  const [environment, setEnvironment] = useState(-1);
  const [value, setValue] = useState(-1);
  const [step, setStep] = useState(0);

  const header_first = "First";
  const header_second = "Second";
  const header_category = "Video Product General Category";
  const header_sub_category = "Video Sub Category";
  const header_creative_method = "Video Creative Method";
  const header_language = "Video Language";
  const header_environment = "Video Enviornment";
  const header_currencies = "Video Values Symbols";

  const meta_map = {
    "creative-methods": [setMethods, methods, header_creative_method, category],
    languages: [setLanguages, languages, header_language, language],
    environments: [
      setEnvironments,
      environments,
      header_environment,
      environment,
    ],
    values: [setValues, values, header_currencies, value],
    categories: [setCategories, categories, header_category, category],
  };
  const field_map = {
    [header_first]: [first, setFirst, 1],
    [header_second]: [second, setSecond, 2],
    [header_category]: [category, setCategory, 3],
    [header_sub_category]: [subCategory, setSubCategory, 4],
    [header_creative_method]: [method, setMethod, 5],
    [header_language]: [language, setLanguage, 6],
    [header_environment]: [environment, setEnvironment, 7],
    [header_currencies]: [value, setValue, 8],
  };

  useEffect(() => {
    Object.keys(meta_map).forEach((k) => {
      httpService
        .get(`gallery/${k}`)
        .then((res) => {
          meta_map[k][0]([...res.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    return () => {
      console.log("cleanup.");
    };
  }, []);
  // console.log(classes);

  const handleChange = (e) => {
    console.log(e.target);
    const nextStep = field_map[e.target.name][2];
    setStep(nextStep);
    handler(nextStep);
    try {
      field_map[e.target.name][1](parseInt(e.target.value));
    } catch (e) {
      console.error(e);
      console.error(field_map);
    }
  };

  const genChoices = (categories, header) => {
    return (
      <div className={classes.flexible}>
        {categories.map((c) => {
          return (
            <div key={c.id}>
              <button
                className={classes.SelectedState}
                onClick={() =>
                  handleChange({
                    target: { name: header, value: c.id },
                  })
                }
              >
                {
                  <img
                    src={field_map[header][0] !== c.id ? Grayicon : Greenicon}
                  />
                }
              </button>

              <span
                style={
                  field_map[header][0] !== c
                    ? { color: "black" }
                    : { color: "green" }
                }
              >
                {c.title}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const genListBox = (categories, header) => {
    return (
      <div>
        <div className={classes.fixedBox}>
          <p>{header}</p>

          <div className={classes.grow}>
            <Container fluid={true} className={classes.container}>
              <div>
                {categories.map((c) => {
                  return (
                    <div key={c.id}>
                      <button
                        className={classes.SelectedState}
                        onClick={() =>
                          handleChange({
                            target: { name: header, value: c.id },
                          })
                        }
                      >
                        {
                          <img
                            src={
                              field_map[header][0] !== c.id
                                ? Grayicon
                                : Greenicon
                            }
                          />
                        }
                      </button>
                      <span
                        style={
                          field_map[header][0] !== c.id
                            ? { color: "black" }
                            : { color: "green" }
                        }
                      >
                        {c.title}
                      </span>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  };

  // console.log(categories);
  // console.log(categories[category]);

  const queueForPublish = () => {
    httpService
      .post(`gallery/publish/`, {
        publishables: selectedList,
        category: category,
        subCategory: subCategory,
        method: method,
        language: language,
        environment: environment,
        value: value,
      })
      .then((res) => {
        window.location.href = "/user/my-videos";
        console.log("done");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const chosen_categories = categories.filter((c) => c.id === category)[0];
  console.log(chosen_categories);
  return (
    <div>
      <div>
        <div>
          {step >= 0 &&
            genChoices(
              [
                { title: "Business", id: 0 },
                { title: "Private", id: 1 },
              ],
              header_first
            )}
          {step >= 1 &&
            genChoices(
              [
                { title: "Product", id: 0 },
                { title: "Service", id: 1 },
              ],
              header_second
            )}
        </div>
        <div className={classes.flexible}>
          {step >= 2 && genListBox(categories, header_category)}
          {step >= 3 &&
            chosen_categories &&
            genListBox(
              chosen_categories.sub_category || [],
              header_sub_category
            )}
          {step >= 4 && genListBox(methods, header_creative_method)}
          <Col>{step >= 5 && genListBox(languages, header_language)}</Col>
          <Col>{step >= 6 && genListBox(environments, header_environment)}</Col>
          <Col>{step >= 7 && genListBox(values, header_currencies)}</Col>
        </div>
        <Button
          style={{ height: "50px" }}
          disabled={
            !(
              category !== -1 &&
              subCategory !== -1 &&
              method !== -1 &&
              environment !== -1 &&
              language !== -1 &&
              value !== -1
            )
          }
          onClick={queueForPublish}
        >
          Publish
        </Button>
      </div>
    </div>
  );
}
