import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import classes from './UploadandOrg.module.css';
import Ssdd from 'assets/Uploadandorg/ssdd.png';
import Pfor from 'assets/Uploadandorg/p4.png';
import { Link } from 'react-router-dom';

import ContributorFooter from './ContributorFooter';
const contributorGuidelineOne = (props) => {

  return (
    <div className={classes.UploadOrg}>
     <Container className="mt-5">
     <Row>
       <Col sm="12" md={{ size: 8 }}>
         <div  className={classes.TextSec}>
       <h4 className={classes.SecHead} >ABSOLUTE PROHIBITION</h4>
       <p>Copyright issue: you cannot upload videos that you do not own the copyright to or do not create yourself.</p>
       <p>Insufficient permission: depicted people or owners of the described property did not permit you to publish the videos.</p>
       <p> You cannot upload graphic violence, gore, or nudity.</p>
       <p>You cannot upload offensive content. Video Requirements</p>
       </div>
       </Col>
       <Col sm="12" md={{ size: 4 }}>
         <div className={classes.ImgSec}><img src={Ssdd} alt="IMG" className="img-fluid" />
         </div>
         </Col>
     </Row>
     <Row className="mt-5">
       <Col sm="12" md={{ size: 8 }}>
         <div  className={classes.TextSec2}>
       <h4 className={classes.SecHead}> VIDEO REQUIREMENTS </h4>
       <p>- Minimum resolution of 1920 x 1080 / 1080 x1920.</p>
       <p>- Minimum resolution of 1920 x 1080 / 1080 x1920.</p>
       <p>- Cinemagraphs, vertical videos, stop motion videos, analog film, and digital formats are all welcome.</p>
       <p>- Avoid shaky, overexposed, underexposed, or unclear videos.</p>
       <p>- In case of sound included in uploads, the track must be no copyright music, royalty-free 
           music Attribution-NoDerivs 3.0 Unported — CC BY-ND 3.0 and documented in the "License Section” that you fill in when setting the video for upload.</p>
       <p>- In the case of artist Creative Commons — Attribution-NoDerivs 3.0 Unported — CC BY-ND 
       3.0, pls make sure Copy paste it as well to the License. To learn more about Creative Commons </p>
       <p>— Attribution</p>
       <p> Get to this link: https://creativecommons.org/licenses/by-nd/3.0/  </p>
       <p className={classes.TxtDark}>*In some cases, videos maybe be removed from VideoBzz due to the unsuitable content nature or due to poor quality.</p>
       <div className={classes.Question}>
         <h4>Questions?</h4>
     <p>Get in touch at <Link>support@videobzz.com </Link></p>
  </div>
     <h4 className={classes.SecHead}> BE ON THE LIST OF OUR DEMAND CONTENT </h4>
     <p>For more users to be able to view your profile and the conent you have created, you need to create content which is on high demand and, of course, create content that interests you so the results will be astonishing.</p>
<p className={classes.Wishlist}>For on-demand categories list pls enter the <span className={classes.Orange}>video wish list</span> page</p>
       </div>
       </Col>
       <Col sm="12" md={{ size: 4 }}>
         <div className={classes.ImgSec}><img src={Pfor} alt="IMG" className="img-fluid" />
         </div>
         </Col>
     </Row>
     <Row>
       <Col sm="12" md={{ size: 8 }}>
         <div  className={classes.TextSec3}>
       <h4 className={classes.SecHead} >SMALL TIPS SIGNIFICANT IMPACT!</h4>
       <div> <p className={classes.TxtDark}>No matter which topic you choose to create a promotional video for, try to keep it generic enough so it can be widely used for more services within this category.</p>
       </div>
      <div> <p><span className={classes.TxtDark}>The mission is to create a signature of your creative style,</span>  which means that you both create a High-end graphic/animation/infographic/cinematic effect scene, name it, but do it with a
       bright punch line that makes it brilliants, unexpected and inspiring at the same time.  
       </p>
       </div>
      <div> <p className={classes.TxtDark}> We also believe in simplicity. </p>
       <p>Example: Video for promoting blogs or a brand new site that just launched to the air, some-times all it takes is a clean little animation with a single sentence that can generate curiosity and inspiration at the same.</p>
      
      <p>By the way, this technique can work for most topics. </p> 
      <p>The mission is to find the principle of how to do it right and up to date.</p></div>

     <div> <p className={classes.TxtDark}>Not need long ads nor long advertising scenes.</p>
      <p>Up to 30 sec is more than enough. Keep it fresh, light, and straightforward. </p>
      <p>There are plenty of topics that even a 15-sec advertising scene can win it all. So it's all about how you are directing it.</p></div>
      </div>
       </Col>
       <Col sm="12" md={{ size: 4 }}>
         <div className={classes.ImgSec}><img src={Ssdd} alt="IMG" className="img-fluid" />
         </div>
         </Col>
     </Row>
     <div className={classes.Cantwait}><p>WE CANNOT WAIT AND SEE YOUR NEXT PIECE OF MARKETING ART!</p></div>
     <ContributorFooter />
     </Container>
    </div>
  )
}


export default contributorGuidelineOne;
