import React from 'react';
import { Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap';

import classes from './MyProfile.module.css';
import SearchHeader from 'components/Gallery/SearchHeader/SearchHeader';
import Profile from 'assets/collection/profile.png';
import Demo from 'assets/collection/collectiondemo.png';

const MyProfile = (props) => {

  return (
    <div className={classes.MyProfile}>
    
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <SearchHeader isSearchHederTextHide={true} isRoyaltyFreeTextHide={true} />
      </Col>
      
      <Col sm="12" md={{ size: 4, offset:  4}} className="mt-5">

      <a href="#" className={classes.ProfileComplete}>
        <span> Complet Your Profile</span>
        <img src={Profile} className="img-fluid" alt="IMG" /> 
      </a>
      </Col>
      <Row className="mt-5 ml-0 mr-0">
      <Col sm="12" md={{ size: 4, offset:2}}>
        <div className={classes.Box}>
       <div> <img src={Demo} className="img-fluid" alt="IMG" /> </div>
       <div className={classes.BottomSec}>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
       </div>
       <div className={classes.LastStrip}>
       <div className={classes.Download}> <span >Your Downloads</span></div> 
       <div className={classes.Iconsbtm}><span><i className="fa fa-lock" aria-hidden="true"></i></span>
        <span><i className="fa fa-file-image-o" aria-hidden="true"></i></span>
        <span>70</span></div> 
       </div>
       </div> 
    </Col>
    <Col sm="12" md={{ size: 4}}>
        <div className={classes.Box}>
       <div> <img src={Demo} className="img-fluid" alt="IMG" /> </div>
       <div className={classes.BottomSec}>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
         <div><img src={Demo} className="img-fluid" alt="IMG" /></div>
       </div>
       <div className={classes.LastStrip}>
       <div className={classes.Download}> <span >Your Downloads</span></div> 
       <div className={classes.Iconsbtm}><span><i className="fa fa-lock" aria-hidden="true"></i></span>
        <span><i className="fa fa-file-image-o" aria-hidden="true"></i></span>
        <span>70</span></div> 
       </div>
       </div> 
    </Col>
    </Row>
    </div>
  )
}


export default MyProfile;
