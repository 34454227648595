import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import classes from './ContributorGuidelineTwo.module.css';
import LongImg from 'assets/LongImg.png';
import ContributorFooter from './ContributorFooter';
const contributorGuidelineTwo = (props) => {

  return (
    <div className={classes.ContributorGuideline}>
      <Container className="pr-5 pl-5">

        <div className={classes.ContriTwoTxt}>
          <p>We are looking both for individual creators and creative marketing ﬁrms, which can be part of our contributors' community who can take over a wide range of advertising categories for niche business segments.</p>
          <p>Each artiﬆ has an aﬃnity for a speciﬁc creative niche, or whether in the creative technique or the choice of the topics he likes to produce his content.</p>
          <p>We recommend creating marketing video content on topics that intereﬆ you more because the moﬆ essential value of this platform is to embody your true potential as a creator of marketing content.</p>
          <p>Talk to your potential cuﬆomers about the issues you excel at, empower your attention through fantaﬆic content that you feel represents your uniqueness.</p>
          <p className={classes.Check}>Check the recommended upload videos liﬆ on demand.</p>
        </div>
        <Row>
          <Col sm="12" md={{ size: 10 }}>
            <div className={classes.ContributorContent}>
              <div className="mt-5">
                <p className={classes.UlHeading}>Retails - niche products</p><ul>
                  <li>Electrics & electronics</li>
                  <li>Mobile & computers</li>
                  <li>Garden & home</li>
                  <li>Sports & Hobbies, outdoor...</li>
                  <li>Books & Study equipment...</li>
                  <li>Fashion/shoes/underwear...</li>
                  <li>jewelry & accessories/glasses...</li>
                  <li>Cosmetics & beauty care products...</li>
                  <li>Automobile, motorcycle, bicycles, scooters sell & lease...</li>
                  <li>Office equipment & furniture... </li>
                  <li>Restaurants, fast food, kiosks & convenience stores...</li>
                  <li>Lifestyle, culture, music, </li>
                </ul>
              </div>
              <div className="mt-5">
                <p className={classes.UlHeading}>services solutions for businesses and private generic advertising</p>
                <ul>
                  <li>Electricians, plumbers, carpenters, cleaning cleaning, renovation, and all other popular services...</li>
                  <li>Business coaching, accounting & law services, business advisers...</li>
                  <li>Courses, education services, schools & colleges, couching, inspiring...</li>
                  <li>Local Delivery & door to door services, courier... </li>
                  <li>Electronic laboratories for PCs and mobiles, garages... </li>
                  <li>Dentist, doctors, Physiotherapists health care specialist, spa, Beauticians, saloons services...</li>
                  <li>Real estate, private homes, apartments, industrial or commercial space for sale & lease...</li>
                  <li>Marketing services, SEO, content, site, blog services...</li>
                  <li>HR services, insurance, banking, loan & financing services...</li>
                  <li>Holidays, travel packages, tour guides, resorts intersections... </li>
                  <li>Private events, birthdays activities, weddings, photographers... </li>
                  <li>Music & Entertainment</li>
                </ul>
              </div>
              <div className="mt-5">
                <p className={classes.UlHeading}>All those categories can be made in various creative production method such as:</p>
                <ul>
                  <li>Generic casting scene</li>
                  <li>Your casting footage made</li>
                  <li>Infographic </li>
                  <li>Animation</li>
                  <li>BG footages scene</li>
                  <li>Cartoons </li>
                  <li>Whiteboard</li>
                  <li>With or without the voice over </li>
                  <li>With our without a soundtrack </li>
                  <li>Can be with English or any other specific native languages & texts</li>
                  <li>Can be without any text/voice or soundtrack, under a particular category</li>

                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md={{ size: 2 }} className="d-flex p-0">
            <div className={classes.LongImg}> <img src={LongImg} alt="IMG" className="img-fluid" /> </div>

          </Col>
        </Row>
        <ContributorFooter />

      </Container>




    </div>
  )
}


export default contributorGuidelineTwo;
