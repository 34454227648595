import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";

import {
  Table,
  CustomInput,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

import classes from "./MyVideos.module.css";
import Greenicon from "assets/ContriAdmin/Greencheck.png";
import Grayicon from "assets/ContriAdmin/Icongray.png";
import httpService from "services/httpService";
import { useTable, useColumnOrder } from "react-table";
import { createDndContext, DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { DRow } from "./DRow";
import YesNoModal from "../../UI/CustomModals/YesNoModal";
import AppContext from "../../../contexts/AppContext";
import { VimeoPicker } from "../SetVimeoAccount/VimeoPicker";
import UploadOptions from "../../Contributor/Upload/UploadOptions";
import { Collapse } from "reactstrap";
import Uploadfile from "assets/contriupload/uploadfile.png";
import ExpandIcon from "assets/expand.jpeg";
import CollapseIcon from "assets/collapse.png";
import authService from "../../../services/authService";

const MyAlbums = ({ handler }) => {
  const [videos, setVideos] = useState([]);
  const [series, setSeries] = useState([]);
  const [album, setAlbum] = useState([]);
  const [ui, setUi] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [chosen, setChosen] = useState(null);
  const app = useContext(AppContext);
  const [showPicker, setShowPicker] = useState(false);
  const [showUploadPicker, setShowUploadPicker] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const refreshData = () =>
    httpService
      .get(`/gallery/mine/`)
      .then((res) => {
        setVideos(res.data);
        setSeries(
          res.data.results
            .filter((v) => v.videos_count > 1)
            .map((v) => v.thumbnail_video.series)
            .flat()
        );
        const sm = {};
        for (const v of res.data.results) {
          sm[v.id] = false;
        }
        setUi(sm);
      })
      .catch((error) => {
        console.log(error);
      });
  useEffect(() => {
    refreshData();
  }, []);

  const columns = useMemo(
    () => [
      // { Header: "Id", accessor: "id" },
      { Header: "Video", accessor: "video" },
      { Header: "Vid/Ser name", accessor: "name" },
      { Header: "Single / Series", accessor: "single" },
      { Header: "Upload Date", accessor: "date" },
      { Header: "Online Custom", accessor: "custom" },
      { Header: "Share link", accessor: "slink" },
      { Header: "Download link", accessor: "dlink" },
      { Header: "Embed link", accessor: "elink" },
      { Header: "Views", accessor: "views" },
      { Header: "Video source", accessor: "vsource" },
      { Header: "Status", accessor: "status" },
      { Header: "Delete", accessor: "delete" },
      // { Header: "Drag", accessor: "grip" },
    ],
    []
  );

  const getSeriesForAlbum = (id) => {
    httpService
      .get(`/gallery/${id}/`)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVideosForSeries = (meta) => {
    httpService
      .get(
        `/gallery/${meta.thumbnail_video.id}/series/${meta.thumbnail_video.series[0].id}`
      )
      .then((res) => {
        console.log(res);
        setVideos(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moveVideo = (video, series) => {
    console.log(video, series);
    httpService
      .post(`gallery/series/${series.id}/`, {
        moveVideo: video.id,
      })
      .then((res) => {
        console.log(res);
        refreshData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const data = useMemo(() => {
    const vlist = videos.results || videos.videos;
    return vlist
      ? vlist.map((videoi) => {
          const video = videoi.thumbnail_video
            ? videoi.thumbnail_video
            : videoi;
          return {
            id: video.id,
            video: (
              <img
                src={video.image}
                className="img-fluid"
                width="50"
                height="50"
                alt="IMG"
              />
            ),
            name: video.thumbnail_video
              ? video.thumbnail_video.title
              : video.title,
            single: videoi.thumbnail_video ? (
              videoi.videos_count == 1 ? (
                <Dropdown
                  isOpen={ui[video.id]}
                  toggle={() => {
                    console.log(`test=${ui[video.id]}`);
                    ui[video.id] = !ui[video.id];
                    setUi({ ...ui });
                    // setTest(test ? false : true);
                  }}
                >
                  <DropdownToggle caret>Move to series</DropdownToggle>
                  <DropdownMenu>
                    {series.map((s) => {
                      return (
                        <DropdownItem
                          key={s}
                          onClick={() => moveVideo(video, s)}
                        >
                          {s.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <Button
                  onClick={() =>
                    handler("ALBUMDETAILS", {
                      album_id: videoi.thumbnail_video.id,
                      album_detail_id: videoi.id,
                    })
                  }
                >
                  {"Details"}
                </Button>
              )
            ) : (
              <Dropdown
                isOpen={ui[video.id]}
                toggle={() => {
                  console.log(`test=${ui[video.id]}`);
                  ui[video.id] = !ui[video.id];
                  setUi({ ...ui });
                  // setTest(test ? false : true);
                }}
              >
                <DropdownToggle caret>Move to series</DropdownToggle>
                <DropdownMenu>
                  {series.map((s) => {
                    return (
                      <DropdownItem onClick={() => moveVideo(video, s)}>
                        {s.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            ),
            date: "now",
            custom: "cs",
            elink: (
              <Button
                onClick={() =>
                  navigator.clipboard.writeText(
                    video.resolutions[0].embeded_url
                  )
                }
              >
                Copy
              </Button>
            ),
            dlink: <a href={video.resolutions && video.resolutions[0] && video.resolutions[0].file_link}>download</a>,
            slink: <a href={video.video_url}>share</a>,
            views: "vc",
            vsource: video.series[0].name,
            status: video.is_active ? (
              <div
                style={{
                  backgroundColor: "green",
                  height: "100%",
                  width: "100%",
                  display: "absolute",
                }}
              >
                <span style={{ color: "white" }}>Active</span>
              </div>
            ) : (
              <div style={{ backgroundColor: "red" }}>
                <span>Pending</span>
              </div>
            ),
            delete: (
              <i
                onClick={() => deleteVideo(video)}
                className="far fa-trash-alt"
              ></i>
            ),
          };
        })
      : [];
  }, [videos.results, series, ui]);

  const DTable = ({ columns, data, saveOrder }) => {
    // console.log(columns, data);
    const manager = useRef(createDndContext(HTML5Backend));
    const [records, setRecords] = useState(data);
    const getRowId = useCallback((row) => {
      return row.id;
    }, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      visibleColumns,
      prepareRow,
      setColumnOrder,
    } = useTable({ data: records, columns, getRowId }, useColumnOrder);

    const moveRow = (dragIndex, hoverIndex) => {
      const dragRecord = records[dragIndex];
      console.log(dragIndex, hoverIndex);

      setRecords(
        update(records, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      );
    };

    return (
      <DndProvider manager={manager.current.dragDropManager}>
        <Table striped className={classes.MyvideoTB} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th>Drag</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, index) =>
                prepareRow(row) || (
                  <DRow
                    index={index}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                  />
                )
            )}
          </tbody>
        </Table>
      </DndProvider>
    );
  };

  const saveOrder = (ordered) => {
    if (videos.videos) {
      const original = videos.videos.map((v) => v.id);
      if (JSON.stringify(ordered) != JSON.stringify(original)) {
        httpService
          .post(`gallery/series/${videos.videos[0].series[0].id}/`, {
            ordered: ordered,
          })
          .then((res) => {
            console.log(res);
            // setVideos(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const deleteVideo = (video) => {
    console.log(video);
    setChosen(video);
    setConfirm(true);
  };
  const confirmDelete = () => {
    console.log("Deleting");
    httpService
      .delete(`gallery/${chosen.album}/`)
      .then((res) => {
        refreshData();
        setChosen(null);
        setConfirm(false);
      })
      .catch((error) => console.error(error));
  };
  const getTable = () => {
    return (
      <Table striped className={classes.MyvideoTB}>
        <thead>
          <tr>
            <th> </th>
            <th className="text-right">Video Name </th>
            <th>Single / Series</th>
            <th>Upload Data</th>
            <th>Views</th>
            <th>Downloads</th>
            <th>Earning</th>
            <th>On/Offline</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {videos.results &&
            videos.results.map((video) => {
              return (
                <tr key={videos.results.indexOf(video)}>
                  <td scope="row">
                    {" "}
                    <img
                      src={video.thumbnail_video.image}
                      className="img-fluid"
                      width="50"
                      height="50"
                      alt="IMG"
                    />{" "}
                  </td>
                  <td> {video.thumbnail_video.title}</td>
                  <td>
                    <span className={classes.GreenIcon}>
                      {" "}
                      <img src={Greenicon} className="img-fluid" alt="IMG" />
                    </span>
                    <span className={classes.GrayIcon}>
                      {" "}
                      <img src={Grayicon} className="img-fluid" alt="IMG" />
                    </span>
                  </td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>
                    <CustomInput
                      type="switch"
                      id="exampleCustomSwitch1"
                      name="customSwitch"
                      className={classes.MySwitch}
                    />
                  </td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>
                    <i
                      onClick={() => deleteVideo(video)}
                      className="far fa-trash-alt"
                    ></i>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };
  // console.log(videos.results);
  // console.log(series);
  // console.log(chosen && chosen.short_name);
  const tv =
    videos.results &&
    videos.results.map((v) => v.videos_count).reduce((acc, c) => acc + c, 0);
  console.log(app);
  return (
    <div>
      {authService.isLoggedIn() && <div style={{ width: "100%" }}>
        <div
          style={{
            width: "200px",
            marginLeft: "auto",
            marginRight: "0px",
            height: "100px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "20px",
              marginLeft: "20%",
            }}
          >
            <p>Upload Video</p>
            <img
              src={Uploadfile}
              width="20"
              alt="IMG"
              className="img-fluid"
              onClick={() => (window.location.href = "/upload")}
            />
            <img
              height="20px"
              onClick={() => setIsOpen(!isOpen)}
              src={isOpen ? CollapseIcon : ExpandIcon}
            ></img>
          </div>
          <Collapse isOpen={isOpen}>
            <div className={classes.Root}>
              <p>
                If you have a Vimeo Pro account and up - You can integrate it{" "}
                <a href="/user">here</a>
              </p>
            </div>
          </Collapse>
        </div>
      </div>}
      {app.vimeoVideos.vimeo_account &&
        app.vimeoVideos.vimeo_account.data.length > 0 && (
          <div style={{padding: "5px"}}>
            <Button color="primary" variant="contained" onClick={() => setShowPicker(!showPicker)}>
              Publish Vimeo Videos
            </Button>
            <VimeoPicker showSelector={showPicker} selectUpload={false} />
          </div>
        )}
      {app.vimeoVideos.bzz_videos &&
        app.vimeoVideos.bzz_videos.data.length > 0 && (
          <div style={{padding: "5px"}}>
            <Button color="primary" onClick={() => setShowUploadPicker(!showUploadPicker)}>
              Publish Uploaded Videos
            </Button>
            <VimeoPicker showSelector={showUploadPicker} selectUpload={true} />
          </div>
        )}
      <div className={classes.MyVideos}>
        {app.myVideos.results && app.myVideos.results.length > 0 && (
          <div>
            <div className="mb-4">
              {videos.results && (
                <p className={classes.Head}>
                  <span className="mr-3">
                    Total Albums - {videos.results && videos.results.length}
                  </span>{" "}
                  / <span className="mr-3">Total Videos - {tv}</span> /{" "}
                  <span className="ml-3">
                    {" "}
                    Total Series - {series && series.length}
                  </span>
                </p>
              )}
            </div>
            {/* {app.myVideos.results && app.myVideos.results.length > 0 && (
              <div>
                <Button onClick={() => setShowPicker(!showPicker)}>
                  Add Videos
                </Button>
                <VimeoPicker showSelector={showPicker} />
              </div>
            )} */}
            <DTable columns={columns} data={data} saveOrder={saveOrder} />
          </div>
        )}

        {app.myVideos.results && app.myVideos.results.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                fontSize: "8",
                margin: "auto",
                textAlign: "left",
                maxWidth: "500px",
              }}
            >
              {/* <div style={{ textAlign: "center" }}>
                <b>Upload Photos and Videos</b>
              </div>
              <p>
                Your uploads will be distributed for free under the VideoBzzz
              license. <br /> Learn more about the{" "}
                <a href="/">Term of use policy</a> <br /> To increase the chance
              of beign featured, please ensure that your submissions meet our
              guidelines
              <br /> We'll review your submission. If it gets selected, you'll
              recieve an eamil notification, and your content will be featured
              in our search. <br /> You can always check your video status and
              video approvals in your admin <a href="/">Here</a>
              </p>
              <p></p>
              <p></p>
              <p></p>
            </div> */}
              <UploadOptions
                showDropTarget={true}
                handler={() => console.log("not here")}
              />
            </div>
          </div>
        )}

        <YesNoModal
          isOpen={confirm}
          modalSize="lg"
          onConfirm={() => confirmDelete()}
          onCancel={() => {
            setChosen(null);
            setConfirm(false);
          }}
          children={
            <p>
              Do you want to delete Album <b>{chosen && chosen.short_name}</b>
            </p>
          }
        />
      </div>
    </div>
  );
};

export default MyAlbums;
