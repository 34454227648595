import React from "react";
import { Row, Col } from "reactstrap";

import classes from "./Shifting.module.css";
import Left from "assets/shifting/left.png";
import Right from "assets/shifting/right.png";
import PlayerIcons from "assets/Playersicons.png";

const shifting = (props) => {
  if (!props.seriesList) {
    return null;
  }
  const seriesListKeys = Object.keys(props.seriesList);
  if (seriesListKeys.length < 1) {
    return null;
  }
  let nextSeriesID = "",
    prevSeriesID = "";
  const seriesPagination = seriesListKeys.map((seriedId, i) => {
    let next = i + 1;
    let prev = i - 1;
    let viewIndex = next < seriesListKeys.length ? next < +" - " : next;
    let indexClass = [classes.ShifName];
    if (props.loadSeriesIDVideos === parseInt(seriedId)) {
      nextSeriesID =
        next < seriesListKeys.length ? parseInt(seriesListKeys[next]) : "";
      prevSeriesID = prev >= 0 ? parseInt(seriesListKeys[prev]) : "";
      indexClass.push(classes.activeIndex);
    }
    return (
      <span
        className={indexClass.join(" ")}
        onClick={() => props.loadSeriesVideos(parseInt(seriedId))}
      >
        {next < seriesListKeys.length ? next + " - " : next}
      </span>
    );
  });

  return (
    <div className={classes.Shifting}>
      <span
        onClick={prevSeriesID ? () => props.loadSeriesVideos(prevSeriesID) : ""}
      >
        <img src={Left} alt="IMG" className="img-fluid" />
      </span>
      <span className={classes.ShifName}> View series </span> {seriesPagination}
      <span
        onClick={nextSeriesID ? () => props.loadSeriesVideos(nextSeriesID) : ""}
      >
        {" "}
        <img src={Right} alt="IMG" className="img-fluid" />
      </span>
    </div>
  );
};

export default shifting;
