import React, { useState, useContext } from "react";
import Vimeo from "assets/contriupload/Vimeologo.png";
import Uploadfile from "assets/contriupload/uploadfile.png";
import Sign from "assets/contriupload/Sign.png";
import classes from "./Upload.module.css";
import AppContext from "../../../contexts/AppContext";
import Uploader from "../../Uploader/Uploader";
import DropzoneComponent from "./DropzoneComponent";
import authService from "../../../services/authService";

export default function UploadOptions({
  showVimeo,
  showUploads,
  handler,
  showDropTarget,
}) {
  const [player, setPlayer] = useState(null);
  const [files, setFiles] = useState([]);
  const app = useContext(AppContext);

  const handleDrop = (droppedFiles) => {
    const fl = [];
    for (let i = 0; i < droppedFiles.length; i++) {
      fl.push(droppedFiles[i]);
    }
    setFiles([...files, ...fl]);
    handleSubmit(droppedFiles);
  };

  const handleSubmit = (droppedFiles) => {
    app.setBottomRightContent(<Uploader files={droppedFiles} />);
  };

  const onChangeHandler = (event) => {
    console.log(event);
    handleSubmit(event.target.files[0]);
  };

  console.log(app.set_background_operation);

  return (
    authService.isLoggedIn() ? < div >
      {showVimeo && (
        <div>
          <div className={classes.Mainp}>
            Connect your{" "}
            <div
              className={classes.ConnectVimeo}
              onClick={() => handler()}
              style={{ display: "inline" }}
            >
              <img src={Vimeo} alt="IMG" className="img-fluid" />{" "}
            </div>
            account{" "}
            {showUploads && (
              <div className={classes.UploadBtn}>
                {/* <button className={classes.MyBtn} onClick={() => handler()}>
                  {" "}
                  <img src={Uploadfile} alt="IMG" className="img-fluid" />{" "}
                </button> */}
                <input type="file" name="myfile" />
              </div>
            )}
            <img src={Sign} alt="IMG" className="img-fluid" />{" "}
          </div>
          <p className={classes.Italicline}>
            Unlimited content integration directely from your vimeo account!{" "}
          </p>
        </div>
      )}
      {
        showUploads && (
          <div>
            <p className={classes.Or}>OR</p>

            <div>
              {!player ? (
                <div className={classes.UploadBtn}>
                  <form onSubmit={handleSubmit}>
                    <button className={classes.MyBtn}>
                      {" "}
                    Upload{" "}
                      <img
                        src={Uploadfile}
                        alt="IMG"
                        className="img-fluid"
                      />{" "}
                    </button>
                    <input
                      type="file"
                      name="file_data"
                      onChange={onChangeHandler}
                    />
                  </form>
                </div>
              ) : (
                <>
                  <h2>
                    Video Uploaded successfully, video available{" "}
                    <a
                      href={`https://player.vimeo.com/video${player}`}
                      target="_blank"
                    >
                      here
                  </a>
                  </h2>
                  <button
                    className={classes.MyBtn}
                    onClick={() => window.location.reload()}
                  >
                    {" "}
                  Upload More{" "}
                  </button>
                </>
              )}


              <p className={classes.Italicline}>
                Weekly remain upload space - 15.74GB{" "}
                <img src={Sign} alt="IMG" className="img-fluid ml-2" />
              </p>
            </div>

            {/* {files.length > 0 && <Button onClick={handleSubmit}>Start</Button>} */}
          </div>
        )
      }
      {
        showDropTarget && (
          <div className={classes.UploadText} >
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  fontSize: "8",
                  margin: "auto",
                  textAlign: "left",
                  maxWidth: "500px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <b>Upload Photos and Videos</b>
                </div>
                <p>
                  Your uploads will be distributed for free under the VideoBzzz
              license. <br /> Learn more about the{" "}
                  <a href="/license">Term of use policy</a> <br /> To increase the chance
              of beign featured, please ensure that your submissions meet our
              guidelines
              <br /> We'll review your submission. If it gets selected, you'll
              recieve an eamil notification, and your content will be featured
              in our search. <br /> You can always check your video status and
              video approvals in your admin <a href="/user/my-videos">Here</a>
                </p>
                <p></p>
                <p></p>
                <p></p>
              </div>

            </div>
            <DropzoneComponent handleDrop={handleDrop} />
          </div>
          // <DragAndDrop handleDrop={handleDrop}>
          //   <div
          //     style={{
          //       height: 200,
          //       width: 450,
          //       border: "dashed grey 2px",
          //       borderRadius: "15px",
          //     }}
          //   >
          //     {files.length == 0 && (
          //       <div className={classes.Instructions}>
          //         <span>
          //           <Button>Browse</Button> or Drag & Drop
          //         </span>
          //       </div>
          //     )}
          //     {files.map((file) => (
          //       <div key={file.name}>{file.name}</div>
          //     ))}
          //   </div>
          //   {files.length > 0 && <Button onClick={handleSubmit}>Upload</Button>}
          // </DragAndDrop>

        )
      }
    </div > : <div></div>
  );
}
