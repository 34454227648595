import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';

import classes from './Toolbar.module.css'
import Logo from 'components/UI/Logo'
import NavItems from './NavItems'

const Toolbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className={classes.Toolbar} expand="md">
      <NavLink
        className="navbar-brand"
        exact
        to='/'

      >
        <Logo />
      </NavLink>
      <NavbarToggler onClick={toggle}><i className="fa fa-list"></i></NavbarToggler>
      <Collapse isOpen={isOpen} navbar>
        <NavItems />
      </Collapse>
    </Navbar>
  )
}

export default Toolbar
