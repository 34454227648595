import './app.css';
import * as React from 'react';
import $ from 'jquery';
import styled from "styled-components";
import httpService from "../../../services/httpService";
import { css } from "emotion";
import AppContext from "../../../contexts/AppContext";

const field1 = css`
display: contents;
align-items: center;
justify-content: center !important;
padding: 10px;
`;
const Button = styled.button`
   background-color: #ff9d00;
    color: white;
    font-size: 26px;
    padding: 1px 0px;
    border-radius: 20px;
    font-size: 40px;
    width: 80%;
    margin: 0px 0px;
    cursor: pointer;
    border-style: hidden;
`;
export default class Greate extends React.Component {
  static contextType=AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
      isChecked1: true
    };
    this.sayHello = this.sayHello.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnChange1 = this.handleOnChange1.bind(this);
    console.log("Created");
  }

  componentDidMount() {
    console.log("Ready to publish");
    $("#timelineBadge8").css({ "--customback": "#27da11" })
    $("#finished").text("GREAT").css({ "left": "43px", "top": "7px" });
    $("#timelineBadge1").css({ "--customback": "#27da11" })
    this.props.updatedfunction();
  }

  handleOnChange() {
    this.setState({ isChecked: !this.state.isChecked })
  }
  handleOnChange1() {
    this.setState({ isChecked1: !this.state.isChecked1 })
  }
  componentDidUpdate() {
    $("#timelineBadge1").css({ "--customback": "#27da11" })
    if (this.state.isChecked == false && this.state.isChecked1 == false) {
      $(".gernlo").css({ "background-color": "#ced4da" })
    } else if (this.state.isChecked == true && this.state.isChecked1 == true) {
      $(".gernlo").css({ "background-color": "#f89400 " })
      // $("#greateas").hide();
    }
  }
  sayHello() {
    let lang_type = 0;
    const lang_ctx = this.context.metaPickerState.Language;
    if(lang_ctx.language_text){
      lang_type = 1;
    }else if(lang_ctx.language_subtitle){
      lang_type = 2;
    }else if(lang_ctx.language_overlanguage){
      lang_type = 3;
    }

    httpService
      .post(`gallery/publish/`, {
        publishables: this.context.metaPickerState.selectedList,
        category: this.context.metaPickerState.Category.checkedOptionValue,
        subCategory: this.context.metaPickerState.Category.checkedItems,
        keywords: this.context.metaPickerState.Keywords.keywords,
        method: this.context.metaPickerState.CM.checkedOptionValue,
        license: this.context.metaPickerState.CM.descrition,
        language: this.context.metaPickerState.Language.checkedOptionValue1,
        languageType: lang_type,
        environment: this.context.metaPickerState.Environment.checkedOptionValue,
        value: this.context.metaPickerState.Symbols.checkedOptionValue=="no_symbols"?"NCS No Currency Symbols ":this.context.metaPickerState.Symbols.checkedOptionValue1,
      })
      .then((res) => {
        this.context.setMetaPickerState({});
        window.location.href = "/user/my-videos";
        console.log("done");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    console.log("Rendering");
    return (
      <div className={field1} id="greateas">
        <div className="contain">
          <div className="container-fluid">
            <div className="filedviodegreate">
              <div className="headerspell1">
                <span>You finished categories your videos!</span>
              </div>
              <div className="rready">
                <span className="readyspan">Ready to publish?</span>
              </div>
              <div className="buttonfield">
                <div className="buttonfield1"><Button onClick={this.sayHello}>
                  PUBLISH
                </Button></div>
                <div >
                  <div className="checkboxform">
                    <div className="topping">
                      <span className="termsspan">Terms and conditions</span> <input
                        type="checkbox"
                        id="topping"
                        name="topping"
                        value="Paneer"
                        checked={this.state.isChecked}
                        onChange={this.handleOnChange}
                      />

                    </div>
                    <div className="topping1">
                      <span className="privacyspan">Privacy Policy </span><input
                        type="checkbox"
                        id="topping"
                        name="topping"
                        value="Paneer"
                        checked={this.state.isChecked1}
                        onChange={this.handleOnChange1}
                      />

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div >
    )
  }
}